import React, { useState } from 'react';
import { useLocation } from '../../../context/LocationContext';
import useFetch from '../../../services/useFetch'
import Radio from '@mui/material/Radio';
import CircularProgress from '@mui/material/CircularProgress';
import PostData from '../../../services/PostData';
import SnackbarEx from '../../Helpers/SnackbarEx';

export default function SelectLocations({ close }) {
    const { selectedLocation: appLoc } = useLocation();
    const { data: qbClasses, error, load } = useFetch("api/quickbooks-locations", false)
    const [selectedClass, setSelectedClass] = useState(null)
    const [openTestSnack, setOpenTestSnack] = useState({
        color: "",
        message: "",
        open: false
    })

    const handleRadioChange = ({ target }) => {
        setSelectedClass(target.value);
    }
    const handleSubmit = async () => {
        const { success, error } = await PostData({ "classInfo": selectedClass }, "api/add-qbo-class")
        if (success) {
            close(true)
        } else {
            setOpenTestSnack({ color: "error", message: "An unexpected error occurred.", open: true })
        }
    }

    return (
        <div>
            <div style={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1, padding: '10px 0 0 0' }}>
                <div className='row' style={{ width: '100%', left: "3%", position: "relative", top: "5px" }}>
                    <div className='col-9'>
                        <h4 style={{ marginBottom: "3px" }}>Select QBO class for {appLoc}: </h4>
                    </div>
                </div>
                <hr style={{ marginBottom: "5px" }} />
            </div>
            <div style={{ overflowY: 'auto', flexGrow: 1, padding: '8px 20px 0 20px', marginLeft: "1.5%", height: '23.5vh' }}>
                {
                    load ? <div style={{ position: 'relative', left: '45%' }}> <CircularProgress /> </div>
                        :
                        qbClasses?.Department?.map(qbClassNm => (
                            <div className='row' style={{ padding: '0 30px 5px 30px', alignItems: 'center' }}>
                                <div className='col-1'>
                                    <Radio size='small'
                                        value={qbClassNm.Id}
                                        checked={selectedClass === qbClassNm.Id}
                                        onChange={handleRadioChange} />
                                </div>
                                <div className='col-11'>
                                    <label>{qbClassNm.Name}</label>
                                </div>
                            </div>
                        ))
                }
            </div>
            <div style={{ position: 'sticky', bottom: 0, backgroundColor: 'white', padding: '10px 0', borderTop: '1px solid #e0e0e0' }}>
                <div className='row' style={{ justifyContent: 'center' }}>
                    <button className='submit' type='submit' style={{ width: "80%" }} onClick={handleSubmit}>
                        Submit
                    </button>
                </div>
            </div>
            {
                openTestSnack.open && <SnackbarEx color={openTestSnack.color} message={openTestSnack.message} open={openTestSnack.open}
                    close={() => setOpenTestSnack({
                        color: "",
                        message: "",
                        open: false
                    })} />
            }
        </div >
    );
};
