import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import PostData from '../../../services/PostData';

export default function ChangePassword() {
    const [load, setLoad] = useState(false)
    const [openSnackbar, setOpenSnackbar] = useState(false)

    const [newVals, setNewVals] = useState({
        oldPassword: "",
        newPassword: ""
    })

    const handleChange = ({ target }) => {

        const value = target.value
        const fldName = target.name

        setNewVals({ ...newVals, [fldName]: value })
    }

    const changePass = async () => {
        setLoad(true)
        const { success, error } = await PostData(newVals, "api/change-password", false, null)

        if (success) {
            setNewVals({
                oldPassword: "",
                newPassword: ""
            })
            setLoad(false)
            setOpenSnackbar(true)
        }
        else (
            console.log("Failure")
        )
    }

    if (load) {
        return (<div className="container" style={{ left: '15%' }}><CircularProgress size={30} /></div>)
    }
    return (
        <div >
            <div className='row' style={{ padding: "5px 0 15px 15px" }}>
                <div className='col-12' style={{ padding: '0' }}>
                    <p style={{ fontSize: '20px', height: '30px', paddingLeft: '5px', margin: '0' }}>Change Password: </p>
                    <hr style={{ borderWidth: '0.5px', margin: '0', width: '315px' }} />
                </div>

            </div >

            <div className='row' style={{ height: 'min-content', paddingBottom: '15px', marginLeft: '5px' }}>
                <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                    {/* <AccountCircle fontSize="medium" sx={{ marginRight: "20px" }} /> */}
                    <TextField size="small" label="Current Password"
                        name='oldPassword' value={newVals.oldPassword} onChange={handleChange}
                        variant='standard' sx={{ width: "275px", }} />
                </div>
            </div>

            <div className='row' style={{ height: "min-content", marginLeft: '5px' }}>
                <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                    {/* <AccountCircle fontSize="medium" sx={{ marginRight: "20px" }} /> */}
                    <TextField size="small" label="New Password"
                        name='newPassword' value={newVals.newPassword} onChange={handleChange}
                        variant='standard' sx={{ width: "275px" }} />
                </div>

            </div>
            <div className='row' >
                <button className='submit' type='submit' onClick={changePass}
                    style={{ width: '305px', marginTop: '37%', marginLeft: '15px', height: '35px' }}>
                    Submit
                </button>
            </div>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={() => setOpenSnackbar(false)}>
                <Alert
                    onClose={() => setOpenSnackbar(false)}
                    severity="success"
                // variant="filled"
                >
                    Password Changed Successfully!
                </Alert>
            </Snackbar>
        </div >
        // </div>
    );
};
