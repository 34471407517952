import React, { useContext } from 'react';
import { authContext } from '../context/AuthContext';
import { Outlet } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import AccountSidebar from '../components/Account/AccountSidebar';


export default function Account({ home }) {
    const { userRole } = useContext(authContext)
    const navigate = useNavigate()

    const handleClose = () => {
        userRole !== "AnalyticsOnly" ? navigate("/analytics") : navigate("/looker-analytics")
        home(true)
    }

    return (
        <>

            <div className='acct-content' style={{ height: '100vh', overflowY: 'hidden' }}>
                <div className='row' style={{ justifyContent: 'flex-end', marginRight: "10%", position: "relative", width: "30%", left: "2%", }}>
                    <AccountSidebar back={handleClose} />
                </div>
                <div className='row' id='act-cntnt'>
                    <Outlet />
                </div>
            </div>


            {/* // TransitionComponent={Transition} */}

        </>
    );
};
