import { useState, useEffect } from "react"
import axios from "axios"

axios.defaults.withCredentials = true
const baseUrl = process.env.REACT_APP_NETLIFY_API_URL || process.env.REACT_APP_API_URL //ENV VARIABLE WITH THE DEFAULT URL 
const tkn = sessionStorage.getItem("urtkn")


export default function FetchWithParams(url, params, refresh) {
    const tkn = sessionStorage.getItem("urtkn")
    const location = localStorage.getItem('usrlctn')

    const [data, setData] = useState(null)

    const [error, setError] = useState(null)
    const [load, setLoad] = useState(true)

    useEffect(() => {

        async function getData() {

            setLoad(true)
            try {
                const paramsWithLocation = ({ ...params, location: location })
                const response = await axios.get(`${baseUrl}/${url}`, {
                    headers: { Authorization: `Bearer ${tkn}` },
                    params: paramsWithLocation
                })
                if (response.status === 200) {

                    setData(response.data)
                } else { throw response }

            } catch (err) {
                setError(err)
            } finally {
                setLoad(false)
            }
        }
        getData()
    }, [url, refresh])
    return { data, error, load }
}