import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';
import PostData from '../../../services/PostData';
import SnackbarEx from '../../Helpers/SnackbarEx';
import { UoMMenuOptions } from '../../Helpers/UoMMenuOptions';
import HoverOverTootlTip from '../../Helpers/HoverOverToolTip';

export default function UploadedNewIngredient({ close, ingredients }) {
    const [ingredientValue, setIngredientValue] = useState([])
    const [fndItemUoM, setFoundItemUoM] = useState({});

    const [openTestSnack, setOpenTestSnack] = useState({
        color: "",
        message: "",
        open: false
    })
    const [load, setLoad] = useState(true)

    const recipeUoM = ["oz", "floz", "lbs", "tsp", "tbsp", "mL", "grams", "cup", "units", "none"]
    const inventoryLocation = [
        { "display": "Front of House", "value": "FrontOfHouse" },
        { "display": "Back of House", "value": "BackOfHouse" }
    ]
    useEffect(() => {

        if (ingredients['newIngs'] && ingredients['newIngs'].length > 0) {
            setLoad(false)
            const configureStateVal = ingredients['newIngs'].map(ingred => ({
                ingredientName: ingred.itemName,
                ingredientId: ingred.itemId,
                vendorIngredientID: ingred.vendorIngredientID ?? null,
                storeName: null,
                ingredientCategory: null,
                parThresholdUoM: null,
                parThreshold: null,
                recipeUoM: null,
                parUoM: null,
                inventoryLocation: null,
                itemSize: ingred.itemSize,
            }))
            setIngredientValue(prevVals => [...prevVals, ...configureStateVal]);
        } else {
            console.log("No ingredients found")
        }
    }, [])
    const menuCategories = [
        "Coffee",
        "Dairy",
        "Disposables",
        "Dry Goods",
        "Tea",
        "Meat",
        "Produce",
        "Bread",
        "Chemicals/Cleaning",
        "Retail",
        "Other"]

    const handleValueChange = (val, index, objKey) => {

        const newIngredient = [...ingredientValue]

        if (objKey === "ingredientName") {
            newIngredient[index][objKey] = val.ingredient
            setIngredientValue(...ingredientValue, newIngredient)
        } else if (objKey === "parThreshold") {
            const inputValue = val.target.value
            newIngredient[index] = { ...newIngredient[index], [objKey]: Number(inputValue) };
            setIngredientValue(newIngredient)
        } else if (objKey === "recipeUoM") {
            const inputValue = val.target.value
            findItemUoM(inputValue, index)
            newIngredient[index] = { ...newIngredient[index], [objKey]: inputValue };
            setIngredientValue(newIngredient)
        } else if (objKey === "parThresholdUoM") {
            let inputValue = val.target.value

            if (inputValue.charAt(inputValue.length - 1) === "s" && inputValue.charAt(inputValue.length > 0)) {
                const packageStr = inputValue.slice(0, -1)
                newIngredient[index] = { ...newIngredient[index], [objKey]: packageStr };
                setIngredientValue(newIngredient)
            }
            else {
                newIngredient[index] = { ...newIngredient[index], [objKey]: inputValue };
                setIngredientValue(newIngredient)
            }
        }
        else {
            const inputValue = val.target.value
            newIngredient[index] = { ...newIngredient[index], [objKey]: inputValue };
            setIngredientValue(newIngredient)
        };
    }

    const findItemUoM = (key, rowIndex) => {
        const obj = UoMMenuOptions(key)
        if (obj) {
            const val = obj[key];
            setFoundItemUoM(prevUoMs => ({
                ...prevUoMs,
                [rowIndex]: val
            }));
        } else {
            setFoundItemUoM(prevUoMs => ({
                ...prevUoMs,
                [rowIndex]: [] // or some default/fallback value
            }));
        }
    }

    const validateForm = () => {
        const allFilled = ingredientValue.every(value => value.ingredientCategory
            && value.parThresholdUoM && value.parThreshold);
        return allFilled;
    };

    const handleSubmit = async () => {
        setLoad(true)
        const valid = validateForm()
        if (valid) {
            const { success, error } = await PostData({ "ingValue": ingredientValue, "invoiceNum": ingredients.invoiceNum }, 'api/update-par-details', false)
            if (success) {
                close("parDetails", "NEWING")
                setLoad(false)
            }
        } else {
            setLoad(false)
            setOpenTestSnack({ color: "error", message: "Please fill out all information", open: true })
        }

    }

    if (load) {
        return (<div className="container" style={{ top: "5px", left: "45%" }}><CircularProgress /></div>)
    }

    return (
        <div className='container-fluid d-flex flex-column' style={{ height: '60vh' }}>
            <div className='row' style={{ justifyContent: "center" }}>
                <div className='col-12' style={{ paddingTop: '15px', paddingLeft: '35px' }}>
                    <h4 style={{ marginBottom: "0" }}>New Ingredient Detected: </h4>
                </div>
                {/* <div className='col-3' style={{ position: 'relative', left: "70px", paddingTop: '15px' }}>
                    <button type="button" className='blank-bttn'>
                        <CloseIcon onClick={() => close('exitIcon')} />
                    </button>
                </div> */}

            </div>
            <div className='row'>
                <p style={{ paddingLeft: "35px", margin: '0' }}>Additional information needed for ingredients.</p>
            </div>
            <hr style={{ marginBottom: "25px" }} />

            <div className='row' style={{ marginBottom: "25px" }}>
                {ingredients['newIngs'].map((ing, index) => (
                    <div className='row' style={{ marginBottom: "15px" }}>
                        <div className='col-6' style={{ position: 'relative', left: '3.5%', marginBottom: "12px", width: "450px" }}>
                            <TextField
                                disabled
                                // helperText="Ingredient"
                                value={ing.itemName ? ing.itemName : ""}
                                variant="standard"
                                onChange={(val) => handleValueChange(val, index, "ingredientName")}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            :
                                        </InputAdornment>
                                    ),
                                }}
                                sx={{
                                    width: "100%",
                                    "& .MuiInputBase-input.Mui-disabled": {
                                        WebkitTextFillColor: "#000000",
                                        textOverflow: 'ellipsis'
                                    },
                                    "& .MuiFormHelperText-root.Mui-disabled": {
                                        WebkitTextFillColor: "#000000"
                                    }
                                }} />
                        </div>

                        <div className='row' style={{ marginLeft: "2px", marginBottom: '10px' }}>
                            <div className='col-5' >
                                <HoverOverTootlTip message={"Shorthand name of raw item."} placement={"bottom-start"}>
                                    <TextField
                                        label="Store Item Name"
                                        value={ingredientValue[index].storeName ? ingredientValue[index].storeName : ""}
                                        // value={"test"}
                                        size='small'
                                        sx={{ minWidth: "415px", '.MuiInputLabel-root, .MuiInput-root': { fontSize: "14.5px" } }}
                                        onChange={(val) => handleValueChange(val, index, "storeName")}>
                                    </TextField>
                                </HoverOverTootlTip>
                            </div>
                        </div>

                        <div className='row' style={{ marginLeft: "2px", marginBottom: '10px' }}>

                            <div className='col-5' style={{ maxWidth: '215px' }}>
                                <HoverOverTootlTip message={"Min. amount needed in inventory."} placement={"bottom-start"}>
                                    <TextField
                                        type='number'
                                        label="PAR Threshold"
                                        value={ingredientValue[index].parThreshold ? ingredientValue[index].parThreshold : ""}
                                        // value={"test"}
                                        size='small'
                                        sx={{ minWidth: "200px", '.MuiInputLabel-root, .MuiInput-root': { fontSize: "14.5px" } }}
                                        onChange={(val) => handleValueChange(val, index, "parThreshold")}>
                                    </TextField>
                                </HoverOverTootlTip>
                            </div>

                            <div className='col-5' >
                                <HoverOverTootlTip message={"Bag, cup, container, carton ..."} placement={"bottom-start"}>
                                    <TextField
                                        label="Raw item Packaging"
                                        value={ingredientValue[index].parThresholdUoM ? ingredientValue[index].parThresholdUoM : ""}
                                        // value={"test"}
                                        size='small'
                                        sx={{ minWidth: "200px", '.MuiInputLabel-root, .MuiInput-root': { fontSize: "14.5px" } }}
                                        onChange={(val) => handleValueChange(val, index, "parThresholdUoM")}>
                                    </TextField>
                                </HoverOverTootlTip>
                            </div>

                        </div>

                        <div className='row' style={{ marginLeft: "2px" }}>
                            <div className='col-5' style={{ maxWidth: '215px' }}>
                                <HoverOverTootlTip message={"UoM for ingredient used in item sold to customer"} placement={"top"}>
                                    <TextField
                                        label="Recipe UoM"
                                        value={ingredientValue[index].recipeUoM ? ingredientValue[index].recipeUoM : ""}
                                        // value={"test"}
                                        select
                                        size='small'
                                        placeholder='grams, units, oz, ml...'

                                        onChange={(val) => handleValueChange(val, index, "recipeUoM")}
                                        sx={{ minWidth: "200px", '.MuiInputLabel-root, .MuiInput-root': { fontSize: "14.5px" } }}>
                                        {recipeUoM?.map((option, ind) =>
                                            <MenuItem key={ind} value={option}>
                                                {option}
                                            </MenuItem>
                                        )}
                                    </TextField>
                                </HoverOverTootlTip>
                            </div>
                            <div className='col-5'>
                                <HoverOverTootlTip message={"UoM for item as purchased from vendor"} placement={"top"}>
                                    <TextField
                                        size='small'
                                        label="ItemUoM"
                                        value={ingredientValue[index].parUoM ? ingredientValue[index].parUoM : ""}
                                        // value={"test"}
                                        select
                                        placeholder='grams, units, oz, ml...'
                                        onChange={(val) => handleValueChange(val, index, "parUoM")}
                                        sx={{ minWidth: "200px", '.MuiInputLabel-root, .MuiInput-root': { fontSize: "14.5px" } }} >
                                        {fndItemUoM[index]?.map((option, ind) => (

                                            <MenuItem key={ind} value={option}>
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </HoverOverTootlTip>
                            </div>

                        </div>
                        <div className='row' style={{ marginLeft: "2px", marginTop: '10px' }}>
                            <div className='col-5' style={{ paddingRight: '0', maxWidth: '215px' }}>
                                <HoverOverTootlTip message={"Unit size of purchased item"} placement={"bottom-start"}>
                                    <TextField
                                        type='number'
                                        label="ItemSize"
                                        value={ingredientValue[index].itemSize ? ingredientValue[index].itemSize : ""}
                                        size='small'
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">{ingredientValue[index].parUoM ? ingredientValue[index].parUoM : ""}</InputAdornment>,
                                        }}
                                        sx={{ minWidth: "200px", '.MuiInputLabel-root, .MuiInput-root': { fontSize: "14.5px" } }}
                                        onChange={(val) => handleValueChange(val, index, "itemSize")}>
                                    </TextField>
                                </HoverOverTootlTip>
                            </div>
                            <div className='col-5' style={{ paddingRight: '0' }}>
                                <TextField
                                    select
                                    label="Ingredient Category"
                                    value={ingredientValue[index].ingredientCategory ? ingredientValue[index].ingredientCategory : ""}
                                    // value={"test"}
                                    size='small'

                                    sx={{ width: "200px", '.MuiInputLabel-root, .MuiInput-root': { fontSize: "14.5px" } }}
                                    onChange={(val) => handleValueChange(val, index, "ingredientCategory")}>
                                    {menuCategories.map((option, ind) =>
                                        <MenuItem key={ind} value={option}>
                                            {option}
                                        </MenuItem>
                                    )}
                                </TextField>
                            </div>
                        </div>
                        <div className='row' style={{ marginLeft: "2px", marginTop: '10px' }}>
                            <div className='col-5' style={{ marginRight: "10px" }}>
                                <HoverOverTootlTip message={"Front of House or Back of House"} placement={"top"}>
                                    <TextField
                                        select
                                        label={"Inventory Location"}
                                        size="small"
                                        variant="outlined"
                                        sx={{ minWidth: "415px", '.MuiInputLabel-root, .MuiInput-root': { fontSize: "14.5px" } }}
                                        name='inventoryLocation'
                                        value={ingredientValue[index]["inventoryLocation"] || ""}
                                        onChange={(val) => handleValueChange(val, index, "inventoryLocation")} >
                                        {inventoryLocation.map((option, ind) => (
                                            <MenuItem key={ind} value={option.value}>
                                                {option.display}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </HoverOverTootlTip>
                            </div>
                        </div>
                    </div>
                ))
                }


            </div >
            <div className='row' style={{ paddingBottom: "20px", justifyContent: 'flex-start', position: "relative", left: "3%", marginTop: "auto" }}>
                <button className='submit' type='submit' style={{ width: "90%" }} onClick={handleSubmit}>
                    Submit
                </button>
            </div>
            {
                openTestSnack.open && <SnackbarEx color={openTestSnack.color} message={openTestSnack.message} open={openTestSnack.open}
                    close={() => setOpenTestSnack({
                        color: "",
                        message: "",
                        open: false
                    })} />
            }
        </div >
    );
};
