import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { CircularProgress } from '@mui/material';
import SnackbarEx from '../Helpers/SnackbarEx';

export default function UploadOrderDetails({ close, items, totalCost }) {

    const [stateVar, setStateVar] = useState({
        "loadFile": false,
        "file": null
    })

    const [openTestSnack, setOpenTestSnack] = useState({
        color: "",
        message: "",
        open: false
    })

    const closePop = () => {
        close('exit')
    }

    const handleFile = ({ target }) => {
        setStateVar(curVals => ({ ...curVals, ["loadFile"]: true }))
        const f = target.files[0]
        const reader = new FileReader();

        reader.onload = (e) => {

            // Get the content of the CSV file
            const csvContent = e.target.result;

            // Split the content into rows
            const rows = csvContent.split('\n');

            // Extract the header row
            const headerRow = rows[0].split(',');

            const allowedHeaders = ["itemName", "productId", "UnitType", "Quantity", "caseUnits", "unitPrice"]
            const trimmedHeaderRow = headerRow.map(header => header.trim().replace(/\r$/, ''));

            const headerMatch = allowedHeaders.every(allowedHeader =>

                trimmedHeaderRow.includes(allowedHeader)
            )

            if (headerMatch) {
                setStateVar(curVals => ({ ...curVals, ["loadFile"]: false, ["file"]: f }))

                const parseCSVRow = (row) => {
                    const parsedRow = [];
                    let insideQuotes = false;
                    let cellValue = '';

                    for (let i = 0; i < row.length; i++) {
                        const char = row[i];

                        if (char === '"' && row[i - 1] !== '\\') {
                            insideQuotes = !insideQuotes;
                        } else if (char === ',' && !insideQuotes) {
                            parsedRow.push(cellValue.trim().replace(/^"|"$/g, '').replace(/\r$/, ''));
                            cellValue = '';
                        } else {
                            cellValue += char;
                        }
                    }

                    parsedRow.push(cellValue.trim().replace(/^"|"$/g, '').replace(/\r$/, ''));
                    return parsedRow;
                };
                const orderDetails = []

                for (const i of rows.slice(1)) {
                    const rowData = parseCSVRow(i)

                    orderDetails.push(
                        rowData[2] === "Case" ?
                            {
                                ingredient: rowData[0],
                                ingredientId: null,
                                vendorIngredientId: rowData[1],
                                quantityOrder: Number(rowData[3]),
                                unitType: rowData[2],
                                caseUnits: Number(rowData[4]),
                                price: Number(rowData[5])
                            }
                            :
                            {
                                ingredient: rowData[0],
                                ingredientId: null,
                                vendorIngredientId: rowData[1],
                                quantityOrder: Number(rowData[3]),
                                unitType: rowData[2],
                                price: Number(rowData[5])
                            }
                    );

                }

                const tot = orderDetails.reduce((total, row) => parseFloat(total) + (parseFloat(row.price) || parseFloat(0)), 0);
                const newSumTotal = Number(tot)
                close('exit')
                totalCost(Number(newSumTotal))
                items(orderDetails)

            } else {
                setStateVar(curVals => ({ ...curVals, ["loadFile"]: false }))
                setOpenTestSnack({ color: "error", message: "Column headers mismatch... please edit headers and try again.", open: true })
            }
        }
        reader.readAsText(f)
    }

    if (stateVar["loadFile"]) {
        return (
            <div style={{ height: '30vh' }}>
                <div className='row' style={{ position: 'relative', left: '5%', padding: '10px 0', alignItems: 'center' }}>
                    <div className='col-10'>
                        <p style={{ fontSize: "20px", margin: '0' }}>Select File to Upload:</p>
                    </div>
                    <div className='col-2'>
                        <CloseIcon fontSize='medium' onClick={closePop} sx={{ cursor: 'pointer' }} />
                    </div>

                </div>
                <hr style={{ marginTop: "0" }} />
                <div className="container" style={{ left: '40%' }}><CircularProgress sx={{ marginLeft: '20px' }} />
                    <p style={{ fontSize: '12px' }}>Checking data...</p>
                </div>
            </div>
        )
    }
    return (
        <div style={{ height: '30vh' }}>
            <div className='row' style={{ position: 'relative', left: '5%', padding: '10px 0', alignItems: 'center' }}>
                <div className='col-10'>
                    <p style={{ fontSize: "20px", margin: '0' }}>Select File to Upload:</p>
                </div>
                <div className='col-2'>
                    <CloseIcon fontSize='medium' onClick={closePop} sx={{ cursor: 'pointer' }} />
                </div>

            </div>
            <hr style={{ marginTop: "0" }} />

            <div className='row' style={{ padding: '0 20px' }}>
                <p style={{ fontSize: 'small', margin: '0' }}>Fields required:</p>
                <p style={{ fontSize: 'small' }}> <i>[itemName, productId, UnitType (Case / Individual), Quantity, caseUnits, unitPrice] </i></p>
            </div>

            <div className='row'>
                <button style={{
                    width: '125px', position: 'relative', left: '40%',
                    marginTop: '25px', border: "1px solid #1fb6ff", background: "white", borderRadius: "5px", color: "#1fb6ff", padding: "5px 10px"
                }}>
                    <input type="file" name="file"
                        onChange={handleFile} accept={'.csv, .xlsx'}
                        style={{ opacity: '0', right: '.2%', position: 'absolute', width: '150px' }} />
                    Select file
                </button>


            </div>
            {
                openTestSnack.open && <SnackbarEx color={openTestSnack.color} message={openTestSnack.message} open={openTestSnack.open}
                    close={() => setOpenTestSnack({
                        color: "",
                        message: "",
                        open: false
                    })} />
            }
        </div>
    );
};
