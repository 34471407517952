import React, { useState, useContext, useEffect } from 'react';
import { LocationContext, useLocation } from '../../../context/LocationContext';
import CloseIcon from '@mui/icons-material/Close';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import PostData from "../../../services/PostData.js"
import SnackbarEx from '../../Helpers/SnackbarEx';
import CircularProgress from '@mui/material/CircularProgress';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';


export default function NewLocationConfirmation({ close, selectedLocation: newLoc, selectedUser }) {

    const { selectedLocation: appLoc } = useLocation();
    const { locationAccess } = useContext(LocationContext)
    const [submitLoad, setSubmitLoad] = useState(false)
    const [openTestSnack, setOpenTestSnack] = useState({
        color: "",
        message: "",
        open: false
    })
    const [checked, setCheck] = useState({
        "vendorCheck": false,
        "ingredientCheck": false,
        "customMenu": false,
        "recipeCheck": false,
        "allNull": true
    })

    const [copyLoc, setCopyLoc] = useState({
        "copyVendor": null,
        "copyIngs": null,
        "copyCustomMenu": null,
        "copyRecipes": null,

    })

    useEffect(() => {
        if (!checked["vendorCheck"] && !checked["ingredientCheck"] && !checked["customMenu"] && !checked["recipeCheck"]) {
            setCheck(cur => ({ ...cur, ["allNull"]: true }))
        } else {
            setCheck(cur => ({ ...cur, ["allNull"]: false }))
        }
    }, [checked["vendorCheck"], checked["ingredientCheck"], checked["customMenu"], checked["recipeCheck"]])

    const handleCheck = (key, val) => {
        setCheck(cur => ({ ...cur, [key]: !val }))
    }

    const handleCopyLoc = (e, val, reason, key) => {
        if (reason !== "clear") {
            setCopyLoc(cur => ({ ...cur, [key]: val }))
        } else {
            setCopyLoc(cur => ({ ...cur, [key]: null }))
        }

    }

    const handleSubmit = async () => {
        setSubmitLoad(true)
        const { success, error } = await PostData({ "newLocation": newLoc, "addUsers": selectedUser, "copyParams": copyLoc }, "api/create-new-location", false)
        // const success = false
        if (success) {
            setSubmitLoad(false)
            close('success')

        } else {
            setSubmitLoad(false)
            setOpenTestSnack({ color: "error", message: error.message, open: true })
        }
    }

    if (submitLoad) {
        return (
            <div style={{ height: '63vh' }}>
                <div className='row' style={{ padding: '15px 0 0 25px' }}>
                    <div className='col-10'>
                        <p style={{ fontSize: "20px" }}>Copy-to Confirmations:</p>
                    </div>
                    <div className='col-2' style={{ paddingLeft: '8%' }}>
                        <CloseIcon fontSize='medium' onClick={() => close("close")} sx={{ cursor: 'pointer' }} />
                    </div>

                </div>
                <hr style={{ marginTop: "0" }} />
                <div style={{ position: 'relative', top: '30%', left: '40%' }}>
                    <CircularProgress size={30} sx={{ marginLeft: "25px" }} />
                    <p style={{ color: '#1976d2' }}>Creating ...</p>
                </div>
            </div>)
    }
    return (
        <div style={{ height: '63vh' }}>
            <div className='row' style={{ padding: '15px 0 0 25px', height: '55px' }}>
                <div className='col-10'>
                    <p style={{ fontSize: "20px" }}>Copy-to Confirmations:</p>

                </div>
                <div className='col-2' style={{ paddingLeft: '8%' }}>
                    <CloseIcon fontSize='medium' onClick={() => close("close")} sx={{ cursor: 'pointer' }} />
                </div>

            </div>
            <div className='row' style={{ paddingLeft: '5.5%', alignItems: 'baseline' }}>
                {/* <div className='col-1' style={{ padding: '0', width: 'fit-content' }}>
                    <InfoOutlinedIcon fontSize='8px' sx={{ padding: '0', width: 'fit-content' }} />
                </div> */}
                <div className='col-10' style={{ paddingLeft: '5px' }}>
                    <p style={{ fontSize: '12px' }}>Select to copy data from {appLoc}. <i>If no copying,</i> leave options blank and submit.</p>
                </div>

            </div>
            <hr style={{ marginTop: "0" }} />


            <div className='row' style={{
                height: '70%'
            }}>
                <div className='row' style={{
                    overflowY: 'scroll', height: '70%', borderRight: checked["allNull"] ? 'solid 1px' : 'none',
                    maxWidth: checked["allNull"] ? '350px' : '100%',
                    paddingLeft: checked["allNull"] ? '5%' : '',
                    height: '92%'
                }}>
                    <div className='row' style={{ flexDirection: "column", paddingLeft: checked["allNull"] ? '10%' : '' }}>
                        <div className='row' style={{ paddingLeft: '5%' }}>
                            <div className='row'>
                                <div className='col-1' style={{ width: 'max-content', padding: '0 0 0 5%' }}>
                                    <Checkbox sx={{ padding: "0" }} checked={checked["vendorCheck"]} onChange={() => handleCheck("vendorCheck", checked["vendorCheck"])} />
                                </div>
                                <div className='col-10'>
                                    <p style={{ margin: '0' }}>Copy vendors</p>
                                </div>
                            </div>
                            {checked["vendorCheck"] && <div className='row' style={{ padding: '15px 0 0 9%', alignItems: 'end' }}>
                                {/* <label style={{}}>Location to copy from:</label> */}
                                <Autocomplete
                                    size="small"
                                    options={locationAccess ?? []}
                                    name='locationNames'
                                    value={copyLoc["copyVendor"]}
                                    onChange={(e, val, reason) => handleCopyLoc(e, val, reason, "copyVendor")}
                                    sx={{ width: "325px" }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={"Copy from Location"}
                                        />
                                    )}>
                                </Autocomplete>
                            </div>}
                        </div>

                        <div className='row' style={{ marginTop: '5%', paddingLeft: '5%' }}>
                            <div className='row'>
                                <div className='col-1' style={{ width: 'max-content', padding: '0 0 0 5%' }}>
                                    <Checkbox sx={{ padding: "0" }} checked={checked["ingredientCheck"]} onChange={() => handleCheck("ingredientCheck", checked["ingredientCheck"])} />
                                </div>
                                <div className='col-10'>
                                    <p style={{ margin: '0' }}>Copy ingredients</p>
                                </div>
                            </div>
                            {checked["ingredientCheck"] && <div className='row' style={{ padding: '15px 0 0 9%', alignItems: 'end' }}>
                                {/* <label style={{}}>Location to copy from:</label> */}
                                <Autocomplete
                                    size="small"
                                    options={locationAccess ?? []}
                                    name='locationNames'
                                    value={copyLoc["copyIngs"] || ""}
                                    onChange={(e, val, reason) => handleCopyLoc(e, val, reason, "copyIngs")}
                                    sx={{ width: "325px" }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={"Copy from Location"}
                                        />
                                    )}>
                                </Autocomplete>
                            </div>}
                        </div>

                        <div className='row' style={{ marginTop: '5%', paddingLeft: '5%' }}>
                            <div className='row'>
                                <div className='col-1' style={{ width: 'max-content', padding: '0 0 0 5%' }}>
                                    <Checkbox sx={{ padding: "0" }} checked={checked["recipeCheck"]} onChange={() => handleCheck("recipeCheck", checked["recipeCheck"])} />
                                </div>
                                <div className='col-10'>
                                    <p style={{ margin: '0' }}>Copy recipes</p>
                                </div>
                            </div>
                            {checked["recipeCheck"] && <div className='row' style={{ padding: '15px 0 0 9%', alignItems: 'end' }}>
                                {/* <label style={{}}>Location to copy from:</label> */}
                                <Autocomplete
                                    size="small"
                                    options={locationAccess ?? []}
                                    name='locationNames'
                                    value={copyLoc["copyRecipes"] || ""}
                                    onChange={(e, val, reason) => handleCopyLoc(e, val, reason, "copyRecipes")}
                                    sx={{ width: "325px" }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={"Copy from Location"}
                                        />
                                    )}>
                                </Autocomplete>
                            </div>}
                        </div>

                        <div className='row' style={{ marginTop: '5%', paddingLeft: '5%', marginBottom: "10px" }}>
                            <div className='row'>
                                <div className='col-1' style={{ width: 'max-content', padding: '0 0 0 5%' }}>
                                    <Checkbox sx={{ padding: "0" }} checked={checked["customMenu"]} onChange={() => handleCheck("customMenu", checked["customMenu"])} />
                                </div>
                                <div className='col-10'>
                                    <p style={{ margin: '0' }}>Copy custom menu</p>
                                </div>
                            </div>
                            {checked["customMenu"] && <div className='row' style={{ padding: '15px 0 0 9%', alignItems: 'end' }}>
                                <Autocomplete
                                    size="small"
                                    options={locationAccess ?? []}
                                    name='locationNames'
                                    value={copyLoc["copyCustomMenu"] || ""}
                                    onChange={(e, val, reason) => handleCopyLoc(e, val, reason, "copyCustomMenu")}
                                    sx={{ width: "325px" }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={"Copy from Location"}
                                        />
                                    )}>
                                </Autocomplete>
                            </div>}
                        </div>
                    </div>
                </div>


                {checked['allNull'] && <div className='row' style={{ width: '45%', paddingLeft: '35px' }}>
                    <div className='col-12'>
                        <p>Not copying any current items from existing locations to {newLoc[0].locationName}?</p>
                    </div>
                </div>}
            </div>



            <div className='row' style={{ background: "white", width: '100%', position: 'fixed', top: "75.5%", }}>
                <div className='col-12'>
                    {!checked["allNull"] ? <button className='submit' type='submit' onClick={handleSubmit}
                        style={{ width: '32.5%', maxWidth: '235px', position: 'fixed', top: "74.5%", marginLeft: '2.5%', height: '35px' }}>
                        Create location
                    </button> :
                        <button className='submit' type='submit' onClick={handleSubmit}
                            style={{ width: '32.5%', maxWidth: '235px', position: 'fixed', top: "67.5%", left: '52%', height: '35px' }}>
                            Create location
                        </button>}
                </div>
            </div >

            {
                openTestSnack.open && <SnackbarEx color={openTestSnack.color} message={openTestSnack.message} open={openTestSnack.open}
                    close={() => setOpenTestSnack({
                        color: "",
                        message: "",
                        open: false
                    })} />
            }
        </div >

    );
};
