import React, { useState, useEffect } from 'react';
import useFetch from "../../../../services/useFetch.js"
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import MenuItem from '@mui/material/MenuItem';
import { recipeMeasurementConversions } from '../../../Inventory/UoMConversion.js';
import { UoMMenuOptions } from '../../../Helpers/UoMMenuOptions.js';

export default function AddMenuItems({ updateMenuItems, itemType, customSize }) {

    const { data: getMenuItems, error, load } = useFetch('api/menuItemsRecipes', false)

    const [menuItemList, setMenuItemList] = useState([])

    // const UoMs = ["oz", "grams", "gallons", "quarts", "lbs", "units"]
    const UoMsOptions = UoMMenuOptions(customSize)
    const UoMs = Object.values(UoMsOptions)

    const [addMenuItem, setAddMenuItem] = useState([
        // {
        //     name: "",
        //     menuId: null,
        //     variant: null,
        //     menuUoM: null,
        //     menuAmount: null,
        //     units: [{ amount: null, measurement: customSize }]
        // }
    ])

    useEffect(() => {
        if (getMenuItems) {
            getMenuItems.map(ingr => {
                setMenuItemList(prevIng => [...prevIng, ingr.posMenuItemVariant ? ingr.posMenuItem + ' _ ' + ingr.posMenuItemVariant : ingr.posMenuItem + ' _ Modifier'])
            })
        }
    }, [getMenuItems])

    const handleNameChange = (index, val, reason) => {

        if (reason !== 'clear') {
            // let namePart = val.match(/^[^-]+/)[0]
            const namePart = val.split(" _ ")[0]
            const varPart = val.split(" _ ")[1]

            const nmIndx = getMenuItems.findIndex(item => item.posMenuItem + ' _ ' + item.posMenuItemVariant === val)

            const newId = [...addMenuItem]
            const newIngredient = [...addMenuItem]
            newIngredient[index]['name'] = namePart
            newIngredient[index]['variant'] = varPart
            newId[index]['menuId'] = getMenuItems[nmIndx].posMenuItemId

            updateMenuItems(newIngredient)

            // handleMeasureChange(val, index)
            setAddMenuItem(newIngredient)
        }

    }

    const handleAmountChange = (index, { target }) => {
        const newIngredient = [...addMenuItem]
        // newIngredient[index]['units'][0].amount = parseFloat(target.value)
        newIngredient[index].menuAmount = parseFloat(target.value)

        updateMenuItems(newIngredient)
    }
    /* ---------------------------------------------------------------------------------------------------------------------
    Will grab the measurement of the ingredient based on the ingredient chosen by the user 
    ---------------------------------------------------------------------------------------------------------------------*/
    const handleMeasureChange = async (index, { target }) => {
        // value = item (Lid) -> we want to find the index of the Lid and pull the measurement from that index        
        const newIngredient = [...addMenuItem]

        const recipeUnits = await recipeMeasurementConversions(customSize, target.value, addMenuItem[index].menuAmount)
        newIngredient[index].menuUoM = target.value
        newIngredient[index].units[0].amount = addMenuItem[index].menuAmount

        newIngredient[index]['units'][0].measurement = target.value
        updateMenuItems(newIngredient)
    }

    function onAdd() {
        setAddMenuItem([...addMenuItem, { name: "", variant: null, menuUoM: null, menuAmount: null, units: [{ amount: null, measurement: customSize }] }])
    }

    /* ---------------------------------------------------------------------------------------------------------------------
        This will delete the most recently added item to the Menu Item
    ---------------------------------------------------------------------------------------------------------------------*/
    const deleteRow = (index) => {

        const ingredientList = [...addMenuItem]
        const last = addMenuItem.length - 1

        if (index !== 0) {
            const indFrom = ingredientList.splice(0, index)
            const indTo = ingredientList.splice(index, last)
            const newList = indFrom.concat(indTo)

            setAddMenuItem(newList)
            updateMenuItems(newList)
        } else {
            setAddMenuItem(ingredientList.splice(1, last))
            updateMenuItems(ingredientList.splice(1, last))
        }
    }
    return (
        <div>
            {addMenuItem.map((elem, ind) =>

                <div className='row' style={{ marginBottom: "10px" }} key={ind}>

                    {/* 
                This is the actual ingredient name
                 */}
                    <div className='col-3'>
                        <Autocomplete
                            /*options={getMenuItems}*/
                            options={menuItemList}
                            key={ind}
                            size='small'
                            onChange={(e, val, reason) => handleNameChange(ind, val, reason)}
                            // getOptionLabel={(option) => (option.itemName + ' - ' + option.variant)}
                            value={addMenuItem[ind].name ? addMenuItem[ind].name + ' - ' + addMenuItem[ind].variant : ""}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: 'black', // Change outline color
                                    },

                                    '&:hover fieldset': {
                                        borderColor: '#1fb6ff', // Change outline color on hover
                                    },
                                },
                            }}
                            renderInput={(params, ind) => <TextField {...params} placeholder='Select a menu item'
                                name='Ingredient' value={elem} key={ind}
                            />}
                        />
                    </div>

                    {/* 
                This is the Units for that specific ingredient in the menu item 
                */}

                    <div className='col-2'>
                        <TextField
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: 'black', // Change outline color
                                    },

                                    '&:hover fieldset': {
                                        borderColor: '#1fb6ff', // Change outline color on hover
                                    },
                                },
                            }}
                            placeholder={itemType === "customIngredient" ? "Amount" : "Item Size"}
                            style={{ width: "100%" }}
                            type="number"
                            onChange={(e) => handleAmountChange(ind, e)}
                            value={elem.units[0].units} size='small'
                        />
                    </div>



                    {/* 
                This is the meaurement for that specific ingredient in the menu item -- this is auto added based on the ingredient 
                */}

                    <div className='col-2'>
                        <TextField
                            select
                            disabled={!elem.menuAmount}
                            style={{ width: "100%" }}
                            onChange={(e) => handleMeasureChange(ind, e)}
                            type="text"
                            // value={elem.units[0].measurement}
                            value={elem.menuUoM}

                            size='small'
                            helperText={"Unit of Measure"}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: 'black', // Change outline color
                                    },

                                    '&:hover fieldset': {
                                        borderColor: '#1fb6ff', // Change outline color on hover
                                    },
                                },
                            }}
                        >
                            {UoMs[0]?.map((option, ind) =>
                                <MenuItem key={ind} value={option}>
                                    {option}
                                </MenuItem>
                            )}
                        </TextField>
                    </div>

                    <div className='col-2' style={{ alignSelf: 'center' }}>
                        <AddCircleOutlineIcon onClick={onAdd} fontSize='medium' sx={{ marginRight: '10px', cursor: 'pointer' }} />
                        <DeleteIcon onClick={() => deleteRow(ind)} sx={{ cursor: 'pointer' }} fontSize='medium' />
                    </div>

                </div>
            )}
            {addMenuItem.length === 0 &&
                <div className='col-3' style={{ alignSelf: 'center', marginBottom: '30px' }}>
                    <button className='recipe-bttn' onClick={onAdd} style={{ display: 'flex', justifyContent: 'center', fontSize: '16px' }}>
                        <AddCircleOutlineIcon onClick={onAdd} fontSize='medium' sx={{ marginRight: '10px', cursor: 'pointer' }} />
                        Add Menu Items
                    </button>

                    {/* <DeleteIcon onClick={() => deleteRow(null)} sx={{ cursor: 'pointer' }} fontSize='medium' /> */}
                </div>
            }

        </div>
    )
}
