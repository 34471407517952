import React, { useEffect, useState } from 'react';
import useFetch from '../../../services/useFetch.js'
import FilterListIcon from '@mui/icons-material/FilterList';
import CircularProgress from '@mui/material/CircularProgress';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';


export default function VendorFilter({ vendor }) {

    const { data, error } = useFetch("api/vendors", false)
    const [vendorOpts, setVendorOpts] = useState([])

    const [load, setLoad] = useState(true)

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    useEffect(() => {
        setLoad(false)
        if (data === null) {
            return
        }
        else {
            setVendorOpts(data)
        }
    }, [data])

    const handleDropdown = ({ target }) => {
        setAnchorEl(target)
    }

    const handleSelect = (vendorName) => {
        setAnchorEl(false)
        vendor(vendorName)
    }

    if (load) return (<div className="container" style={{ left: '0', top: '0' }}><CircularProgress size={20} /></div>)
    return (
        <div>

            {vendorOpts.length > 0 && <FilterListIcon sx={{ marginBottom: '10px', color: 'gray' }} onClick={handleDropdown} />}
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={() => { setAnchorEl(false) }}
                sx={{ '.MuiPaper-root': { width: "fit-content", borderRadius: "10px" } }}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {vendorOpts?.map(vend => (
                    < MenuItem key={vend.vendorId} onClick={() => handleSelect(vend.vendor)}>{vend.vendor}</MenuItem>
                ))}

            </Menu>

        </div >
    );
};
