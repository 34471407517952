import React, { useContext } from 'react';
import { authContext } from '../../../context/AuthContext';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import MenuItem from '@mui/material/MenuItem';

export default function ImportCSVFile({ vendorSel }) {
    const { userRole } = useContext(authContext)

    let vendorCsv
    userRole === "AppAdmin" ?
        vendorCsv = ["Gordon Food Services.", "OdekoAuto"] :
        vendorCsv = ["Gordon Food Services.", "Odeko"]
    return (

        <div className='row' style={{ marginTop: '5px', marginBottom: '15px' }}>
            <div className='col-6'>
                <Autocomplete
                    size="small"
                    options={vendorCsv}
                    onChange={(e, val) => vendorSel(val, "VendorName")}
                    sx={{ width: "200px", '.MuiInputBase-root': { height: '38px' } }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Select Vendor"
                        />
                    )}>
                    {vendorCsv.map((val, ind) =>
                        <MenuItem key={ind} value={val}>{val}</MenuItem>
                    )}
                </Autocomplete>
            </div>
            <div className='col-6'>
                <TextField
                    size='small'
                    sx={{ '.MuiInputBase-root': { height: '38px' } }}
                    label="Invoice Number"
                    onChange={(e) => vendorSel(e.target.value, "InvoiceNum")}
                />
            </div>
        </div>


    );
};
