import React, { useEffect } from 'react';
import useFetch from "../services/useFetch.js"
import CircularProgress from '@mui/material/CircularProgress';

export default function LookerAnalytics() {
    const { data: srcLink, error, load } = useFetch("api/getLookerSrc", false)
    if (load) {
        return (<div className="pg-spin"><CircularProgress /></div>)
    }
    return (
        <div style={{ height: '100%' }}>
            <iframe
                width="98%"
                height="91%"
                // src="https://lookerstudio.google.com/embed/reporting/2971c8f2-2a33-4438-ba3d-f4c8c66be997/page/p_5tw6fy6wjd"
                // src="https://lookerstudio.google.com/embed/reporting/dedffa15-b901-4f0c-a3ca-2db5d00a594a/page/CNXFE"
                src={srcLink}
                frameborder="0"
                style={{ border: "0", position: 'relative', left: '1%', overflowY: 'scroll' }}
                allowfullscreen
                sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox">
            </iframe>
        </div >
    );
};