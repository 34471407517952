import React, { useState, useEffect } from 'react';
import useFetch from "../../../../services/useFetch.js"

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';

export default function EditCustomIngredients({ ingredients, updateIngredients }) {

    const { data: getIngredients, error, load } = useFetch('api/ingredients', false)
    const [ingredientItemList, setIngredientItemList] = useState([])
    const [addIngredients, setAddingredients] = useState(ingredients)


    useEffect(() => {
        if (getIngredients) {
            getIngredients.map(ingr => {
                setIngredientItemList(prevIng => [...prevIng, ingr.ingredient])
            })
        }
    }, [getIngredients])

    const handleNameChange = (index, val, reason) => {

        if (reason !== 'clear') {
            const nmIndx = getIngredients.findIndex(item => item.ingredient === val)

            const newId = [...addIngredients]
            const newIngredient = [...addIngredients]
            newIngredient[index]['name'] = val
            newId[index]['id'] = getIngredients[nmIndx].ingredientId

            updateIngredients(newIngredient, index)

            handleMeasureChange(newId, index)
            setAddingredients(newIngredient)
        }

    }

    const handleAmountChange = (index, { target }) => {
        const newIngredient = [...addIngredients]
        newIngredient[index]['units'][0].amount = parseFloat(target.value)

        updateIngredients(newIngredient, index)
    }
    /* ---------------------------------------------------------------------------------------------------------------------
    Will grab the measurement of the ingredient based on the ingredient chosen by the user 
    ---------------------------------------------------------------------------------------------------------------------*/
    const handleMeasureChange = (value, index) => {
        // value = item (Lid) -> we want to find the index of the Lid and pull the measurement from that index

        // const measure = value.recipeUoM
        const newIngredient = [...addIngredients]

        // newIngredient[index]['units'][0].measurement = measure
        let fin = getIngredients.find(ele => ele.ingredientId === value[index].id)

        newIngredient[index]['units'][0].measurement = fin.recipeUoM

        // setSelectedData(true)
        updateIngredients(newIngredient, index)
    }

    function onAdd() {
        setAddingredients([...addIngredients, { name: "", type: "custom", units: [{ amount: "", measurement: "" }] }])
    }

    /* ---------------------------------------------------------------------------------------------------------------------
        This will delete the most recently added item to the Menu Item
    ---------------------------------------------------------------------------------------------------------------------*/
    const deleteRow = (index) => {

        const ingredientList = [...addIngredients]
        const last = addIngredients.length - 1

        if (index !== 0) {
            const indFrom = ingredientList.splice(0, index)
            const indTo = ingredientList.splice(index, last)
            const newList = indFrom.concat(indTo)

            setAddingredients(newList)
            updateIngredients(newList)
        } else {
            setAddingredients(ingredientList.splice(1, last))
            updateIngredients(ingredientList.splice(1, last))
        }
        // const last = addIngredients.length - 1
        // const ingredientList = [...addIngredients]

        // ingredientList.splice(last, 1)
        // setAddingredients(ingredientList)
    }

    return (
        <div>
            {addIngredients.map((elem, ind) =>

                <div className='row' style={{ marginBottom: "10px" }} key={ind}>

                    {/* 
                This is the actual ingredient name
                 */}
                    <div className='col-3'>
                        <Autocomplete options={ingredientItemList} size='small'
                            onChange={(e, val, reason) => handleNameChange(ind, val, reason)}
                            // getOptionLabel={(option) => option.ingredient}
                            value={addIngredients[ind].name || ""}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: 'black', // Change outline color
                                    },

                                    '&:hover fieldset': {
                                        borderColor: '#1fb6ff', // Change outline color on hover
                                    },
                                },
                            }}
                            renderInput={(params) => <TextField {...params}
                                placeholder={addIngredients[ind].name ? `${addIngredients[ind].name} - ${addIngredients[ind].variant}` : "Select an ingredient..."}
                                name='Ingredient' value={elem}
                            />}
                        />
                    </div>

                    {/* 
                This is the Units for that specific ingredient in the menu item 
                */}

                    <div className='col-2'>
                        <TextField
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: 'black', // Change outline color
                                    },

                                    '&:hover fieldset': {
                                        borderColor: '#1fb6ff', // Change outline color on hover
                                    },
                                },
                            }}
                            placeholder='Add Ingredient Units...'
                            style={{ width: "100%" }}
                            type="number" onChange={(e) => handleAmountChange(ind, e)}
                            value={elem.units[0].amount} size='small'
                        />
                    </div>



                    {/* 
                This is the meaurement for that specific ingredient in the menu item -- this is auto added based on the ingredient 
                */}

                    <div className='col-2'>
                        <TextField style={{ width: "100%" }}

                            type="text"
                            value={elem.units[0].measurement || "Awaiting ingredient"}
                            disabled
                            size='small'
                        />
                    </div>

                    <div className='col-2' style={{ alignSelf: 'center' }}>
                        <AddCircleOutlineIcon onClick={onAdd} fontSize='medium' sx={{ marginRight: '10px', cursor: 'pointer' }} />
                        <DeleteIcon onClick={() => deleteRow(ind)} sx={{ cursor: 'pointer' }} fontSize='medium' />
                    </div>

                </div>
            )}
            {addIngredients.length === 0 &&
                <div className='col-2' style={{ alignSelf: 'center', marginBottom: '20px' }}>
                    <button className='recipe-bttn' onClick={onAdd} style={{ display: 'flex', justifyContent: 'center', fontSize: '16px' }}>
                        <AddCircleOutlineIcon onClick={onAdd} fontSize='medium' sx={{ marginRight: '10px', cursor: 'pointer' }} />
                        Add Ingredients
                    </button>

                    {/* <DeleteIcon onClick={() => deleteRow(null)} sx={{ cursor: 'pointer' }} fontSize='medium' /> */}
                </div>
            }

        </div>
    );
};
