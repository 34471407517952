import React, { useState, useEffect, useContext } from 'react';
import { LocationContext } from '../../../context/LocationContext.js';
import PostData from '../../../services/PostData.js';
import useFetchWithParams from "../../../services/useFetchWithParams.js"
import CloseIcon from '@mui/icons-material/Close';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import DoubleArrowRoundedIcon from '@mui/icons-material/DoubleArrowRounded';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import CircularProgress from '@mui/material/CircularProgress';
import Checkbox from '@mui/material/Checkbox';


/* --------------------------------------------------------------------IMPORTS END ---------------------------------------------------------------- */
export default function CopyRecipes({ close, selIngredients, copyType, bbLoc, copCust }) {

    const { locationAccess } = useContext(LocationContext)

    const { data: getItems, error } = useFetchWithParams(`api/copy-${copyType}`, { "pullFromLoc": bbLoc, "arrayItem": selIngredients, "type": copyType })
    const [load, setLoad] = useState(true)
    const [postLoad, setPostLoad] = useState(false)
    const [copyLocAcc, setCopyLocAcc] = useState([])

    const [recipes, setRecipes] = useState([])
    useEffect(() => {
        if (getItems && copyType) {
            let nameArr;
            let locArr;
            switch (copyType) {
                case "recipe":
                    nameArr = getItems?.filter(item => selIngredients?.includes(item.itemId));
                    getItems?.map(ing => setRecipes(cur => [...cur, {
                        copyFromName: ing.itemName + ' -- ' + ing.variant,
                        copyFromId: ing.itemId,
                        copyFromPOSId: ing.posId,
                        copyToName: [],
                        toLocChip: []
                    }]))
                    locArr = locationAccess?.filter(loc => !loc.includes(bbLoc));
                    setLoad(false)
                    break;
                case "ingredient":
                    nameArr = getItems?.filter(item => selIngredients?.includes(item.itemId));

                    getItems?.map(ing => setRecipes(cur => [...cur, {
                        copyFromName: ing.itemName,
                        copyFromId: ing.itemId,
                        copyFromPOSId: null,
                        copyToName: [],
                        toLocChip: []
                    }]))
                    locArr = locationAccess?.filter(loc => !loc.includes(bbLoc));
                    setLoad(false)
                    break;
                case "vendor":
                    nameArr = getItems?.filter(item => selIngredients?.includes(item.itemId));

                    getItems?.map(ing => setRecipes(cur => [...cur, {
                        copyFromName: ing.vendorName,
                        copyFromId: ing.itemId,
                        copyFromPOSId: null,
                        copyToName: [],
                        toLocChip: []
                    }]))
                    locArr = locationAccess?.filter(loc => !loc.includes(bbLoc));
                    setLoad(false)
                    break;
                case "custom":
                    nameArr = getItems?.filter(item => selIngredients?.includes(item.itemId));

                    getItems?.map(ing => setRecipes(cur => [...cur, {
                        copyFromName: ing.itemName,
                        copyFromId: ing.itemId,
                        copyFromPOSId: null,
                        copyToName: [],
                        toLocChip: []
                    }]))
                    locArr = locationAccess?.filter(loc => !loc.includes(bbLoc));
                    setLoad(false)
                    break;
            }
            setCopyLocAcc(locArr)
        }
    }, [getItems])

    const handleToChange = (index, val, reason) => {

        if (reason !== 'clear') {
            const newRecipe = [...recipes]
            if (val == "All locations") {
                newRecipe[index]["copyToName"] = copyLocAcc
                newRecipe[index]["toLocChip"] = val
            } else {
                newRecipe[index]["copyToName"] = val
                newRecipe[index]["toLocChip"] = val
            }

            setRecipes(newRecipe)
        } else if (reason === "removeOption") {
            // removing a value, so find it and remove from array
            const matchingObjects = recipes[index]["copyToName"].filter(obj => val.includes(obj[val]));
            setRecipes(matchingObjects)
        }
    }

    function onAdd() {
        setRecipes([...recipes, { copyFromName: null, copyFromId: null, copyToName: [], toLocChip: [] }])
    }

    /* ---------------------------------------------------------------------------------------------------------------------
        This will delete the most recently added item to the Menu Item
    ---------------------------------------------------------------------------------------------------------------------*/
    const deleteRow = () => {
        const last = recipes.length - 1
        const recipeList = [...recipes]

        recipeList.splice(last, 1)
        setRecipes(recipeList)
    }

    const handleAllLocsCheck = () => {
        const locObj = recipes[0]["copyToName"]
        const locChip = recipes[0]["toLocChip"]
        const updatedRecipes = recipes.map(rec => ({
            ...rec, // Copy the existing fields
            copyToName: locObj, // Update the copyToName field
            toLocChip: locChip
        }));

        // Set the new state
        setRecipes(updatedRecipes);
    }

    const handleSubmit = async () => {

        setRecipes(cur => [...cur, { ["fromLoc"]: bbLoc }])
        setPostLoad(true)
        const { success, error } = await PostData({ "recipes": recipes, "fromLoc": bbLoc, "copyCustom": copCust }, `api/copy-items-location/${copyType}`, false)

        if (success) {

            setRecipes([{ copyFromName: null, copyFromId: null, copyToName: [], toLocChip: [] }])
            close(false, "copied")
            setPostLoad(false)

        }
    }

    if (load || postLoad) {
        return (
            <>
                <div className='row inv-hdr' style={{ alignItems: 'baseline', marginBottom: '35px' }}>
                    <div className='col-10'>
                        <h4 style={{ margin: "20px 0 20px 4%" }}>Copy {bbLoc} menu item recipes: </h4>
                    </div>
                    <div className='col-2' style={{ position: 'relative', left: '8%' }}>
                        <button type='button' className='blank-bttn' onClick={() => close(false)}>
                            <CloseIcon sx={{ cursor: 'pointer' }} />
                        </button>
                    </div>

                </div>
                <div style={{ position: 'relative', top: '30%', left: '47%' }}>
                    <CircularProgress size={30} sx={{ marginLeft: "20px" }} />
                    <p style={{ color: '#1976d2' }}>{load ? "Loading ..." : "Copying ..."}</p>
                </div>
            </>
        )
    }
    return (
        <div /*className='container-fluid d-flex flex-column'*/ style={{ height: '100%' }}>
            <div className='row inv-hdr' style={{ alignItems: 'baseline', marginBottom: '35px' }}>
                <div className='col-10'>
                    <h5 style={{ margin: "20px 0 20px 4%" }}>Copy {bbLoc} menu item recipes: </h5>
                </div>
                <div className='col-2' style={{ position: 'relative', left: '8%' }}>
                    <button type='button' className='blank-bttn' onClick={() => close(false)}>
                        <CloseIcon sx={{ cursor: 'pointer' }} />
                    </button>
                </div>

            </div>
            <div className='row' style={{ marginRight: '14px', alignItems: "center", justifyContent: "flex-end" }}>
                {recipes && recipes[0]["copyToName"] && recipes[0]["copyToName"]?.length > 0 &&
                    <>
                        <div className='col-1' style={{ width: 'max-content', padding: '0 0 0 5%' }}>
                            <Checkbox sx={{ padding: "0" }} onChange={handleAllLocsCheck} />
                        </div>
                        <div className='col-2' style={{ maxWidth: '200px' }}>
                            <p style={{ margin: '0' }}>Set all locations</p>
                        </div>
                    </>
                }
            </div>

            <div className='row' style={{ height: '77%', overflowY: 'scroll', overflowX: 'hidden' }}>
                <div className='row' style={{ flexDirection: "column", paddingTop: '5px' }}>
                    <div className='col-12'>
                        {recipes?.map((elem, ind) =>
                            <div className='row' style={{ marginBottom: "20px", alignItems: 'center', justifyContent: "center", height: "max-content" }}>
                                <div className='col-5'>
                                    <TextField
                                        disabled
                                        size='small'
                                        value={recipes[ind]["copyFromName"] || ""}
                                        sx={{
                                            width: "100%",
                                            "& .MuiInputBase-input.Mui-disabled": {
                                                WebkitTextFillColor: "#000000"
                                            },
                                            "& .MuiFormHelperText-root.Mui-disabled": {
                                                WebkitTextFillColor: "#000000"
                                            }
                                        }} />
                                </div>
                                <div className='col-1' style={{ paddingLeft: '50px' }}>
                                    <DoubleArrowRoundedIcon />
                                </div>
                                <div className='col-5'>
                                    <Autocomplete
                                        multiple
                                        options={["All locations", ...copyLocAcc]} size='small'
                                        onChange={(e, val, reason) => handleToChange(ind, val, reason)}
                                        value={recipes[ind]["toLocChip"] || ""}
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    borderColor: 'black', // Change outline color
                                                },

                                                '&:hover fieldset': {
                                                    borderColor: '#1fb6ff', // Change outline color on hover
                                                },
                                            },
                                        }}
                                        renderInput={(params) => <TextField {...params}
                                            name='copyFrom' label="Copy to Location(s)"
                                        />}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    <div className='col-12' style={{ marginBottom: '30px' }}>
                        {recipes.length === 0 ?
                            <div className='row' style={{ position: 'relative', left: "5%" }}>
                                <div className='col-12'>
                                    <button className='recipe-bttn' onClick={onAdd} style={{ display: 'flex', justifyContent: 'center', fontSize: '16px' }}>
                                        <AddCircleOutlineIcon onClick={onAdd} fontSize='medium' sx={{ marginRight: '10px', cursor: 'pointer' }} />
                                        Add Items
                                    </button>
                                </div>
                            </div>
                            :
                            <div className='row' style={{ position: 'relative', left: "84%", height: 'max-content' }}>

                                <div className='col-12' style={{ alignSelf: 'end', paddingLeft: '10.5%' }}>
                                    {/* <AddCircleOutlineIcon onClick={onAdd} fontSize='medium' sx={{ marginRight: '10px', cursor: 'pointer' }} /> */}
                                    <DeleteIcon onClick={deleteRow} sx={{ cursor: 'pointer' }} fontSize='medium' />
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>

            <div className='row' style={{ height: "75px", justifyContent: 'end', position: "fixed", background: 'white', bottom: '0', width: '100%' }}>
                <div className='col-12'>
                    <button className='submit' type='submit' style={{ width: "15%", marginLeft: '5%' }} onClick={handleSubmit}  >
                        Submit
                    </button>
                </div>

            </div>

        </div>
    );
};
