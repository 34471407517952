import React, { useEffect, useState } from 'react';
import GetData from '../../services/GetData';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import { DataGrid, GridToolbarContainer } from "@mui/x-data-grid"
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { currencyFormatter } from '../Helpers/CurrencyFormat';
import EditIcon from '@mui/icons-material/Edit';
import PostFulfilledInvoice from '../../services/PostFulfilledInvoice';


const apiUrl = "api/invoicedetail"

export default function InvoiceDetails({ invnum, invVendor, close, vendorStock, pdfUpload }) {
    // const pdfUpload = true
    const [load, setLoad] = useState(true)
    const [row, setRow] = useState([])
    const [col, setCol] = useState([])
    const [confirmingUpload, setConfirmingUpload] = useState(false)

    useEffect(() => {
        if (invnum && invVendor) {
            fetchInvoice()
        }
    }, [invnum, invVendor])

    const fetchInvoice = async () => {
        const { getData, error } = await GetData(apiUrl,
            { "invnum": invnum, "vendorId": invVendor }, null, null, null)
        if (getData) {
            setData(getData.ingredients)
            setLoad(false)
        }
        if (error) { console.log(error) }
    }

    const closePop = () => {
        close(false, null)
    }

    const reInvoice = (val) => {
        if (val !== "edit") {
            close("openInvoice", invnum, invVendor, false)
            // close("openInvoice", { "invNum": invnum, "invVend": invVendor })
        } else {
            close("openInvoice", { "invNum": invnum, "invVend": invVendor })
        }
    }

    const setData = (val) => {
        setRow(val.map((e, ind) =>
        ({
            id: ind, Ingredient: e.ingredient,
            // RecipeUoM: e.measurement ? e.measurement : e.recipeUoM,
            // ItemUoM: e.itemUoM,
            ParQuantity: e.packQuantity > 1 ? e.packQuantity + " " + 'units' : e.packQuantity + " " + 'unit',
            Quantity: (e.recipeUoM || e.measurement) ? `${e.quantity.toFixed(0)} ${e.recipeUoM || e.measurement}` : `${e.quantity.toFixed(0)}`,
            UnitCost: (e.total / e.quantity),
            Fees: e.fee ? e.fee : 0.0,
            Discount: e.item_discount ?? 0.0,
            ItemCost: e.total,
        })
        ))

        setCol([
            { field: 'Ingredient', headerName: 'Ingredient', flex: 2 },
            // { field: 'RecipeUoM', headerName: 'Recipe UoM', flex: 1 },
            // { field: 'ItemUoM', headerName: 'Item UoM', flex: 1 },
            { field: 'ParQuantity', headerName: 'Quantity', flex: 1 },
            {
                field: 'Quantity', headerName: 'Recipe Quantity', flex: 1
            },
            {
                field: 'UnitCost', headerName: 'Recipe Unit Cost', flex: 1, valueFormatter: ({ value }) => {
                    return value ? currencyFormatter(value, 2) : currencyFormatter(0.0, 2)
                }
            },
            { field: 'Fees', headerName: 'Fees', flex: 1, valueFormatter: ({ value }) => currencyFormatter(value, 2) },
            { field: 'Discount', headerName: 'Discount', flex: 1, valueFormatter: ({ value }) => currencyFormatter(value, 2) },
            { field: 'ItemCost', headerName: 'Item Cost', flex: 1, valueFormatter: ({ value }) => currencyFormatter(value, 2) },
        ])
    }

    const pdfUploadConfirm = async () => {
        setConfirmingUpload(true)
        const vendorInfo = {
            "vendorNm": null,
            "vendorId": invVendor,
            "orderNumber": invnum,
            "invoiceNum": null
        }
        let { success: invFulfilled, error, retIngredients } = await PostFulfilledInvoice(vendorInfo, "api/fulfill-invoice/upload")
        if (invFulfilled && retIngredients.length > 0) {
            setConfirmingUpload(false)
            // newIngredients(retIngredients)
            close("uploadNewIngs", retIngredients)
        }
        else {
            setConfirmingUpload(false)
            close("uploadNewIngs", false)
        }
    }

    function CustomToolbar() {
        if (pdfUpload) {
            return (
                <GridToolbarContainer>
                    <div className="col-xl-3 col-lg-3 me-auto d-flex align-items-center">
                        {/* Will open the manual entry page for the user to edit some of the data */}
                        <button
                            onClick={() => reInvoice("edit")}
                            className={'col-xl-3 col-lg-3 d-flex align-items-center recipe-bttn'}
                            style={{ width: '90px', marginRight: '5px', display: "flex", alignItems: "center", height: '32px', marginTop: "3px" }}
                        >
                            <EditIcon fontSize='medium' sx={{ display: "inherit", marginRight: "5px", marginLeft: "-2px", "&.MuiSvgIcon-root": { marginRight: '7px' } }} />
                            EDIT</button>
                    </div>
                    <div>
                        {/* Confirm will call the fulfill invoice function */}
                        <button
                            onClick={pdfUploadConfirm}
                            className="recipe-bttn"
                            style={{ marginTop: "3px", height: '32px' }}>
                            CONFIRM
                        </button>
                    </div>
                </GridToolbarContainer>
            );
        } else {
            return (
                !vendorStock && (
                    <GridToolbarContainer>
                        <button className="recipe-bttn" style={{ marginTop: "3px", height: '32px', paddingTop: '3px' }} onClick={() => reInvoice("re-inv")}>
                            <div className='row' style={{ display: 'flex', alignItems: 'center' }}>
                                <div className='col-2' style={{ paddingBottom: '2px' }}>
                                    <AddOutlinedIcon
                                        fontSize='medium'
                                        sx={{ color: "#1fb6ff", marginTop: '0', marginBottom: '0', marginRight: '5px', '&:hover': { color: "#14a9f3" } }}
                                    />
                                </div>
                                <div className='col-9' style={{ paddingTop: '0', paddingRight: '0' }}>RE-INVOICE</div>
                            </div>
                        </button>
                    </GridToolbarContainer >
                )
            );
        }
    }

    if (load) { return <CircularProgress sx={{ '&.MuiCircularProgress-root': { position: 'relative', left: '50%' } }} /> }
    return (
        <>
            <div id='add-item-pop' style={{ width: "100%" }}>
                <div className='row'>
                    <div className='col-10' style={{ position: 'relative', left: "10px" }}>
                        {!vendorStock ?
                            <p style={{ fontSize: "large" }}>Invoice Details:</p>
                            :
                            <p style={{ fontSize: "large" }}>Email Order Details:</p>
                        }
                    </div>
                    <div className='col-2' style={{ position: 'relative', left: "75px" }}>
                        <button type="button" className='blank-bttn'>
                            <CloseIcon onClick={closePop} sx={{ cursor: 'pointer' }} />
                        </button>
                    </div>
                </div>
                <hr style={{ margin: "0" }} />
                {confirmingUpload ?
                    <div>
                        <div className="container" style={{ left: '43%', top: '100%' }}>
                            <CircularProgress sx={{ position: 'relative', left: '5%', marginTop: '15%' }} />
                            <p style={{ fontSize: '14px' }}>Confirming invoice...</p>
                        </div>
                    </div>
                    :
                    <div className='row'  >
                        <div className='col-12'>
                            <DataGrid
                                rows={row}
                                columns={col}
                                rowHeight={40}
                                slots={{
                                    toolbar: CustomToolbar
                                }}
                                sx={{
                                    padding: "5px 0 0 10px", border: "none", marginLeft: "10px", overflowY: "scroll",
                                    height: "45vh",
                                    '.MuiToolbar-root': { alignItems: "baseline" },
                                    '.MuiDataGrid-main': { fontSize: 'small' },
                                    '.MuiSvgIcon-root': { margin: 0 },
                                    '.MuiDataGrid-main': { margin: "0 20px 0 0" },
                                    '.MuiDataGrid-toolbarContainer': { justifyContent: 'flex-end', marginRight: "10px", paddingLeft: '0' },
                                    '.MuiDataGrid-columnHeaderTitle': {
                                        whiteSpace: "break-spaces",
                                        lineHeight: "normal",
                                        fontWeight: '600',
                                        '@media (max-width:1080px)': { fontSize: '11px' }
                                        // webkitTextStroke: '1px transparent'
                                    }
                                }}
                            />
                            {/* </div> */}

                        </div>

                    </div>
                }
            </div >
        </>
    );
};
