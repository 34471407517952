import axios from "axios"

axios.defaults.withCredentials = true
const baseUrl = process.env.REACT_APP_NETLIFY_API_URL || process.env.REACT_APP_API_URL


export default async function PostInvoice(data, url,) {
    // const tkn = localStorage.getItem("urtkn")
    const tkn = sessionStorage.getItem("urtkn")
    const location = localStorage.getItem('usrlctn')

    try {

        const response = await axios.post(`${baseUrl}/${url}`, { data, location: location },
            {
                headers: { Authorization: `Bearer ${tkn}` }
            })

        if (response.status === 200) {
            const respData = response.data
            return {
                success: true, error: false, retIngredients: respData
            }
        } else { return { success: false, error: true, load: false } }

    } catch (err) {
        return { success: false, error: err, load: false }
    }
}