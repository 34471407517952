import React, { useState, useEffect } from 'react';
import { useParams, useSearchParams, Link } from 'react-router-dom';
import Bread_Bskt from "../images/Bread_Bskt.png"
import Snackbar from "@mui/material/Snackbar"
import Alert from '@mui/material/Alert'
import useFetchWithParams from "../services/useFetchWithParams.js"
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import PostData from '../services/PostData.js';

export default function ResetPasswordAccount() {

    const [load, setLoad] = useState(false)
    const { tokenKey } = useParams()
    const [searchParams] = useSearchParams();
    const email = searchParams.get('email')

    const [passwordInput, setPasswordInput] = useState("")
    const [passReset, setPassReset] = useState(false)
    const [openSuccess, setOpenSuccess] = useState(false)
    const [openFail, setOpenFail] = useState(false)

    const { data, error } = useFetchWithParams("api/reset-password-authorized", { email: email, tokenKey: tokenKey })

    useEffect(() => {
        if (data && data.message === 'Authorized') setLoad(false)
    }, [data])

    const submitPassword = async () => {
        setLoad(true)
        const { success } = await PostData({ email: email, password: passwordInput }, "api/update-password", null, null)

        if (success) {
            setLoad(false)
            setOpenSuccess(true)
            setPassReset(true)
        } else {
            setLoad(false)
            setOpenFail(true)
        }
    }


    // Will need a useFetch with the email and tokenKey
    // on successful return it will render the return and let the user reset their password
    if (load) {
        return (
            <div className='row align-content-center' style={{ height: '100vh' }}>
                <div className='col-1 mx-auto'>
                    <CircularProgress />
                </div>
            </div>
        )
    }
    return (
        <div className='row justify-content-center align-items-center' style={{ height: "75vh" }}>
            <div className="col-xl-4 col-md-6 mt-5">

                <div className="row" style={{ justifyContent: 'center' }}>
                    <div className="col-6 offset-xl-4 offset-lg-3 offset-md-3 offset-sm-2" style={{ marginBottom: '20px' }} >
                        <img src={Bread_Bskt} style={{ width: '115px', height: '110px' }} />
                    </div>
                </div>

                {/*---------------- -------- -------- --------UNDER LOGO SECTION -------- -------- -------- --------  */}
                {!passReset ?
                    <div className='row offset-xl-1'
                        style={{
                            justifyContent: 'center', marginTop: '20px', border: 'solid gray',
                            borderWidth: '0.5px', borderRadius: '5px'
                        }}>
                        <div className='col-12 '>
                            <div className='row' style={{ padding: "20px 15px 0 15px" }}>
                                <p>Forgot Password?</p>
                                <hr style={{ borderWidth: '0.5px' }} />
                            </div>


                            <div className='row' style={{ padding: "10px 15px 0 15px" }}>
                                <p>Please enter a new password.</p>
                            </div>
                            <div className='row' style={{ width: '100%', paddingLeft: '20px', paddingBottom: "25px" }}>
                                {/* <div className='col-xl-12 offset-xl-2'> */}
                                <TextField label="Email" size="small"
                                    defaultValue={email}
                                    InputProps={{
                                        readOnly: true,
                                    }} />
                                {/* </div> */}
                            </div>
                            <div className='row' style={{ width: '100%', paddingLeft: '20px', paddingBottom: "25px" }}>
                                {/* <div className='col-xl-12 offset-xl-2'> */}
                                <TextField label="New Password" size="small" type='password'
                                    onChange={(e) => setPasswordInput(e.target.value)} value={passwordInput} />
                                {/* </div> */}
                            </div>
                            <div className='row' style={{ width: "100%", padding: "25px 10px 0 10px", marginBottom: "25px" }}>
                                <button type='submit' onClick={submitPassword}
                                    style={{
                                        background: "#1fb6ff", border: "none", boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px", color: "white",
                                        height: "42px", borderRadius: "5px", margin: "0 0 0 10px"
                                    }}
                                >Reset password</button>
                            </div>
                        </div>

                    </div>
                    :
                    <div className='row offset-xl-4 offset-lg-3' style={{ justifyContent: 'center', marginTop: '30px' }}>
                        <div className='col-12 '>
                            <Link to={"/login"} target="_blank" style={{ textDecoration: 'none', fontSize: '20px', fontWeight: 'normal' }}> Head back to the login... </Link>
                        </div>
                    </div>

                }
            </div>
            <Snackbar
                open={openSuccess}
                autoHideDuration={5000}
                onClose={() => setOpenSuccess(false)}>
                <Alert onClose={() => setOpenSuccess(false)} severity="success">Password reset!</Alert>
            </Snackbar>
            <Snackbar
                open={openFail}
                autoHideDuration={5000}
                onClose={() => setOpenFail(false)}>
                <Alert onClose={() => setOpenSuccess(false)} severity="error">Unable to reset password!</Alert>
            </Snackbar>

        </div >
    );
};
