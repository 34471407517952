import React, { useEffect, useState } from 'react';
import useFetch from "../../../../services/useFetch.js"
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Autocomplete from '@mui/material/Autocomplete';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import { UoMMenuOptions } from '../../../Helpers/UoMMenuOptions.js';

export default function EditCustomPosItems({ menuItems, updateMenuItems, customSize }) {

    const { data: getMenuItems, error, load } = useFetch('api/menuItemsRecipes', false)

    // const UoMs = ["oz", "grams", "gallons", "lbs", "units"]
    const UoMsOptions = UoMMenuOptions(customSize)
    const UoMs = Object.values(UoMsOptions)

    const [menuItemList, setMenuItemList] = useState([])

    const [addMenuItem, setAddMenuItem] = useState(menuItems)

    useEffect(() => {
        if (getMenuItems) {
            getMenuItems.map(ingr => {
                setMenuItemList(prevIng => [...prevIng, ingr.posMenuItem + ' - ' + ingr.posMenuItemVariant])
            })
        }
    }, [getMenuItems])


    const handleNameChange = (index, val, reason) => {

        if (reason !== 'clear') {
            const namePart = val.split(" - ")[0]
            const varPart = val.split(" - ")[1]

            const nmIndx = getMenuItems.findIndex(item => item.posMenuItem + ' - ' + item.posMenuItemVariant === val)
            const newId = [...addMenuItem]
            const newIngredient = [...addMenuItem]
            newIngredient[index]['name'] = namePart
            newIngredient[index]['variant'] = varPart
            newId[index]['menuId'] = getMenuItems[nmIndx].posMenuItemId

            updateMenuItems(newIngredient)

            // handleMeasureChange(val, index)
            setAddMenuItem(newIngredient)
        }
    }

    const handleAmountChange = (index, { target }) => {
        const newIngredient = [...addMenuItem]

        newIngredient[index].menuAmount = parseFloat(target.value)
        newIngredient[index].units[0].amount = parseFloat(target.value)
        updateMenuItems(newIngredient)
    }
    /* ---------------------------------------------------------------------------------------------------------------------
    Will grab the measurement of the ingredient based on the ingredient chosen by the user 
    ---------------------------------------------------------------------------------------------------------------------*/
    const handleMeasureChange = async (index, { target }) => {
        // value = item (Lid) -> we want to find the index of the Lid and pull the measurement from that index

        const newIngredient = [...addMenuItem]

        newIngredient[index].menuUoM = target.value

        newIngredient[index].units[0].amount = addMenuItem[index].menuAmount

        newIngredient[index]['units'][0].measurement = target.value
        updateMenuItems(newIngredient)
    }

    function onAdd() {
        setAddMenuItem([...addMenuItem, { name: "", variant: null, menuUoM: null, menuAmount: null, units: [{ amount: null, measurement: customSize }] }])
    }

    /* ---------------------------------------------------------------------------------------------------------------------
        This will delete the most recently added item to the Menu Item
    ---------------------------------------------------------------------------------------------------------------------*/
    const deleteRow = (index) => {

        const ingredientList = [...addMenuItem]
        const last = addMenuItem.length - 1

        if (index !== 0) {
            const indFrom = ingredientList.splice(0, index)
            const indTo = ingredientList.splice(index, last)
            const newList = indFrom.concat(indTo)

            setAddMenuItem(newList)
            updateMenuItems(newList)
        } else {
            setAddMenuItem(ingredientList.splice(1, last))
            updateMenuItems(ingredientList.splice(1, last))
        }
    }

    return (
        <div>
            {addMenuItem.map((elem, ind) =>

                <div className='row' style={{ marginBottom: "10px" }} key={ind}>

                    {/* This is the actual ingredient name */}
                    <div className='col-3'>
                        <Autocomplete options={menuItemList} size='small'
                            onChange={(e, val, reason) => handleNameChange(ind, val, reason)}
                            value={addMenuItem[ind].name ? addMenuItem[ind].name + ' - ' + addMenuItem[ind].variant : ""}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: 'black', // Change outline color
                                    },

                                    '&:hover fieldset': {
                                        borderColor: '#1fb6ff', // Change outline color on hover
                                    },
                                },
                            }}
                            renderInput={(params) => <TextField {...params}
                                placeholder={addMenuItem[ind].name ? `${addMenuItem[ind].name} - ${addMenuItem[ind].variant}` : "Select an ingredient..."}
                                name='Ingredient'
                                value={elem}
                            />}
                        />
                    </div>

                    {/* This is the Units for that specific ingredient in the menu item  */}

                    <div className='col-2'>
                        <TextField
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: 'black', // Change outline color
                                    },

                                    '&:hover fieldset': {
                                        borderColor: '#1fb6ff', // Change outline color on hover
                                    },
                                },
                            }}
                            placeholder='Add Ingredient Units...'
                            style={{ width: "100%" }}
                            type="number"
                            onChange={(e) => handleAmountChange(ind, e)}
                            value={elem.menuAmount}
                            size='small'
                        />
                    </div>

                    {/* This is the meaurement for that specific ingredient in the menu item -- this is auto added based on the ingredient  */}

                    <div className='col-2'>
                        <TextField style={{ width: "100%" }}
                            select
                            disabled={!elem.menuAmount}
                            onChange={(e) => handleMeasureChange(ind, e)}
                            type="text"
                            value={elem.menuUoM}
                            size='small'
                            placeholder="Item UoM (oz, cups...)"
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: 'black', // Change outline color
                                    },

                                    '&:hover fieldset': {
                                        borderColor: '#1fb6ff', // Change outline color on hover
                                    },
                                },
                            }}
                        >
                            {UoMs[0]?.map((option, ind) =>
                                <MenuItem key={ind} value={option}>
                                    {option}
                                </MenuItem>
                            )}
                        </TextField>
                    </div>

                    <div className='col-2' style={{ alignSelf: 'center' }}>
                        <AddCircleOutlineIcon onClick={onAdd} fontSize='medium' sx={{ marginRight: '10px', cursor: 'pointer' }} />
                        <DeleteIcon onClick={() => deleteRow(ind)} sx={{ cursor: 'pointer' }} fontSize='medium' />
                    </div>
                </div>
            )}
        </div>
    );
};
