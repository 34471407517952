import React, { useState, useEffect } from 'react';
import useFetch from "../../services/useFetch.js"
import CircularProgress from '@mui/material/CircularProgress';
// import FilterListIcon from '@mui/icons-material/FilterList';
import CategoryFilter from './Helper/CategoryFilter.jsx';
import GetData from '../../services/GetData.js';
import Chip from '@mui/material/Chip';


export default function ExpensiveIngredient({ startDate, endDate, reload, showLoad }) {
    const [val, setVal] = useState(0.00)

    const [categoryFilter, setCategoryFilter] = useState(null)
    const [filtData, setFiltData] = useState(null)

    const [catBool, setCatBool] = useState(false)

    const [reloadData, setReloadData] = useState(reload)
    const [load, setLoad] = useState(true)

    const apiUrl = `api/expensive-item?startDateStr=${startDate}&endDateStr=${endDate}`

    const { data, error } = useFetch(apiUrl, reload)

    useEffect(() => {

        if (data === null) {
            return
        } else if (filtData === null && catBool) {
            setLoad(true)
        }
        else if (filtData !== null && catBool) {
            setVal(filtData.unitPrice)
            setLoad(false)
        }
        else {
            setVal(data.unitPrice ?? "N/a")
            setLoad(false)
            showLoad()
            if (reloadData) setReloadData(false)
        }
    }, [data, filtData])

    const categoryNameFilter = async (filter) => {
        // call GetData with the filter as the parameter to filter on

        setCatBool(true)
        setCategoryFilter(filter)
        const { getData, load, error } = await GetData("api/expensive-filtered-item",
            filter, 'filterCategory', startDate, endDate)
        if (load) { setLoad(true) }
        else if (error) { console.log(error) }
        else {
            setFiltData(getData)
            setLoad(false)
        }
    }

    const clearFilter = () => {
        setCatBool(false)
        setFiltData(null)
        setCategoryFilter(null)
    }


    if (load) {
        return (<div className="container"><CircularProgress /></div>)
    }
    return (
        <>
            <div className='row' style={{ margin: "15px 0 0 0" }}>
                <div className='col-10'>
                    <p className='dsh-hr-ttl'>Most Expensive Ingredient</p>
                </div>
                <div className='col-2'>
                    <CategoryFilter category={categoryNameFilter} />
                </div>
            </div>
            {catBool &&
                <div className='row' style={{ margin: "0 0 0 0" }}>
                    <div className='col-6' style={{ paddingLeft: '10px', paddingBottom: '10px' }}>
                        <Chip label={categoryFilter} variant="outlined" sx={{ color: 'gray' }} onDelete={clearFilter} />
                    </div>
                </div>
            }
            {/* <hr style={{ margin: "5px 0 15px 0" }} /> */}
            <div className='row' style={{ margin: "0 0 10px 0" }}>
                <div className='col-8'>
                    {data || filtData ? <p
                        style={{
                            textOverflow: "ellipsis",
                            overflow: 'hidden', whiteSpace: 'nowrap',
                        }}>{!filtData ? data.ingredient : filtData.ingredient}:</p> :
                        <p className='dsh-hr-inf' style={{ color: '#0e1111' }}>No data for dates</p>}
                </div>
                <div className='col-2'>
                    <p className='dsh-hr-inf' style={{ color: '#1fb6ff' }}>{(val > 0) ? '$' + val.toFixed(2) : val}</p>
                </div>
            </div>
        </>
    );
};
