import React, { useState } from "react";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';


export default function MenuActionsButton({ selection, itemsSelected }) {
    const [hamburgerAnchor, setHamburgerAnchor] = useState(null)
    const [hamburgerOpen, setHamburgerOpen] = useState(false)
    const open = Boolean(hamburgerAnchor)

    const onClick = ({ target }) => {
        setHamburgerOpen(!hamburgerOpen)
        setHamburgerAnchor(target)
    }

    const handleClose = (val) => {
        setHamburgerOpen(false)
        selection(val)
    }

    return (

        <>
            <div className="col-lg-6 justify-content-start" style={{ minWidth: '125px' }}>

                <button id={"action-bttn"}
                    onClick={onClick}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span style={{ paddingTop: '2px', paddingRight: '10px', borderRight: 'solid' }}>ACTIONS</span>
                        <ArrowDropDownIcon
                            fontSize='medium'
                            sx={{ color: "white", '&.MuiSvgIcon-root': { marginLeft: '7px' } }} />
                    </div>
                </button>

                {hamburgerOpen &&
                    <Menu
                        anchorEl={hamburgerAnchor}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={open}
                        onClose={(val) => setHamburgerOpen(!hamburgerOpen)}
                        sx={{ ".MuiPaper-root": { borderRadius: "5px" } }}
                    >
                        <MenuItem onClick={() => handleClose('pos refresh')}>POS Refresh</MenuItem>
                        <MenuItem disabled={!itemsSelected} onClick={() => handleClose('copy recipe')}>Copy Recipe</MenuItem>
                        {/* <MenuItem onClick={() => handleClose('Upload PDF/Image')}>Upload Invoice</MenuItem> */}
                    </Menu>
                }
            </div>

        </>
    )
}