import React, { useState, useEffect } from 'react';
import useFetch from "../../../services/useFetch.js"
import SyncOutlinedIcon from '@mui/icons-material/SyncOutlined';
import { DataGrid, GridToolbarContainer } from "@mui/x-data-grid"
import GetData from '../../../services/GetData.js';
import { CircularProgress } from '@mui/material';
import MergeIngredient from './MergeIngredient.jsx';
import { Dialog } from "@mui/material";
import SnackbarEx from '../../Helpers/SnackbarEx.jsx';

export default function QuickbooksItems() {
    const [row, setRow] = useState([])
    const [col, setCol] = useState([])
    const [reload, setReload] = useState(false)
    const [syncing, setSyncing] = useState(false)
    const [load, setLoad] = useState(true)
    const [selSingleIngredient, setSelSingleIngredient] = useState(null)
    const [selIngrIdArr, setSelIngrIdArr] = useState(null)
    const [mergeOpen, setMergeOpen] = useState(false)
    const [openTestSnack, setOpenTestSnack] = useState({
        color: "",
        message: "",
        open: false
    })

    const { data: qbItems, error } = useFetch("api/check-quickbooks-items/items", reload)
    useEffect(() => {

        if (qbItems) {
            setLoad(false)
            setData()
        }
    }, [qbItems])

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <div className="col-xl-2 col-lg-3 me-auto d-flex align-items-center">
                    <button style={{
                        marginTop: '0', display: "flex", alignItems: "center", color: selIngrIdArr?.length > 0 ? "#1fb6ff" : "lightgray",
                        height: '32px', border: selIngrIdArr?.length > 0 ? "solid 1px #1fb6ff" : "solid 1px lightgray", maxWidth: '300px'
                    }}
                        onClick={() => setMergeOpen(true)}
                        className={selIngrIdArr?.length === 1 ? "delete-bttn" : "delete-dis"}
                        disabled={selIngrIdArr?.length === 0 ? true : false}>{selIngrIdArr?.length > 1 ? "Merge ingredients" : "Merge ingredient"}</button>
                </div>
                <div>
                    <button
                        onClick={syncQBItems}
                        className='submit'
                        style={{ display: "flex", width: '155px', height: 'fit-content', alignItems: 'center' }}>
                        <div style={{ width: 'fit-content', padding: '5px 8px' }}>
                            {syncing ?
                                <CircularProgress size={18} sx={{ color: 'white' }} />
                                :
                                <SyncOutlinedIcon fontSize='small' />
                            }
                        </div>
                        <div style={{ width: 'fit-content', padding: '5px 0' }}>
                            <p style={{ width: 'fit-content', margin: '0' }}>Sync QB Items</p>
                        </div>
                    </button>
                </div>
            </GridToolbarContainer >
        )
    }

    const setData = () => {
        setRow(qbItems.map(e => {
            return {
                id: e.ingredientId,
                rawItem: e.ingredient,
                qboId: e.qboItemId
            }
        }).filter(item => item !== null));

        setCol([
            { field: 'rawItem', headerName: 'Item Name', flex: 1 },
            { field: 'qboId', headerName: 'QBO Item Id', flex: .45 }
        ]);
    }

    const CustomNoRowsOverlay = () => {
        return (
            <div style={{ position: 'relative', left: '42%', top: '35%' }}>
                <p style={{ margin: '0' }}>Please sync to get data from Quickbooks</p>
                <button
                    onClick={syncQBItems}
                    className='submit'
                    style={{ display: "flex", width: '155px', marginLeft: '55px', marginTop: '10px', height: 'fit-content' }}>
                    <div style={{ width: 'fit-content', padding: '5px 8px' }}>
                        {syncing ?
                            <CircularProgress size={18} sx={{ color: 'white' }} />
                            :
                            <SyncOutlinedIcon fontSize='small' />
                        }
                    </div>
                    <div style={{ width: 'fit-content', padding: '5px 0' }}>
                        <p style={{ width: 'fit-content', margin: '0' }}>Sync QB Items</p>
                    </div>
                </button>
            </div>
        )
    }

    const syncQBItems = async () => {
        setSyncing(true)
        const { getData, load, error } = await GetData("api/sync-to-quickbooks/items", null, null, null, null)
        if (getData) {
            setOpenTestSnack({ color: "success", message: "Items successfully added!", open: true })
            refreshPage()
            setSyncing(false)
        }
    }

    const refreshPage = () => {
        setReload(true)
        setTimeout(() => {
            // Code to be executed after the delay
            setReload(false)
        }, 200);
    }
    const selectClick = (e) => {
        if (e.length === 1) {
            // const findData = data.find((element) => e[0] === element.itemId)

            setSelSingleIngredient(e[0])
            setSelIngrIdArr(e)
        } else if (e.length > 1) {
            setSelSingleIngredient(false)
            setSelIngrIdArr(e)

        } else {
            setSelSingleIngredient(false)
            setSelIngrIdArr(null)
        }
    }

    const handlePopupClose = (val) => {
        switch (val) {
            case "success":
                setMergeOpen(false)
                setOpenTestSnack({ color: "success", message: "Item merged successfully!", open: true })
                break;

            default:
                setMergeOpen(false)
                break;
        }

    }

    return (
        <div>
            <Dialog fullScreen open={mergeOpen} onClose={handlePopupClose} >
                <MergeIngredient close={handlePopupClose} selItems={selIngrIdArr} mergeType={"ingredient"} />
            </Dialog>
            <div className='row' style={{ width: "100%" }}>
                <div className='col-12 menu-list dt' style={{ marginTop: '0', height: '70vh' }}>
                    <DataGrid
                        checkboxSelection
                        onRowSelectionModelChange={selectClick}
                        rows={row}
                        columns={col}
                        rowHeight={50}
                        loading={load}
                        slots={{
                            noRowsOverlay: !load ? CustomNoRowsOverlay : undefined,
                            toolbar: CustomToolbar
                        }}
                        // processRowUpdate={(updatedRow, originalRow) => editCell(updatedRow, originalRow)}
                        // onProcessRowUpdateError={(e) => (console.log(e))}

                        // onCellEditStart={editStart}                        
                        sx={{
                            padding: "0 0 0 25px", border: "none", marginLeft: "10px", overflowY: "scroll",
                            '.MuiToolbar-root': { alignItems: "baseline" },
                            // '.MuiDataGrid-cell': { /*borderRight: 'none'*/ borderLeft: 'solid .3px gray' },
                            '.MuiDataGrid-main': { fontSize: 'small', marginTop: '0' },
                            '.MuiFormControl-root': { margin: '0 10px 5px 0' },
                            '.MuiSvgIcon-root': { margin: 0 },
                            '.MuiDataGrid-main': { margin: "0 20px 0 0" },
                            '.MuiDataGrid-toolbarContainer': { justifyContent: 'flex-end', marginRight: "10px", paddingLeft: '0' },
                            '.MuiDataGrid-columnHeaderTitle': {
                                whiteSpace: "break-spaces",
                                lineHeight: "normal",
                                fontWeight: '600',
                                '@media (max-width:1080px)': { fontSize: '11px' }

                            }
                        }}
                    />
                </div>
            </div>
            {
                openTestSnack.open && <SnackbarEx color={openTestSnack.color} message={openTestSnack.message} open={openTestSnack.open}
                    close={() => setOpenTestSnack({
                        color: "",
                        message: "",
                        open: false
                    })} />
            }
        </div>
    );
};
