import React, { useEffect, useState, useRef } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import FoodBev from '../components/Inventory/FoodBev.jsx';

export default function Inventory({ reload }) {
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    // Function to return the component based on the tab index
    const tabPage = (index) => {
        switch (index) {
            case 0:
                return <FoodBev reload={reload} pageTab="FrontOfHouse" />;
            case 1:
                return <FoodBev reload={reload} pageTab="BackOfHouse" />;
            default:
                return null;
        }
    };

    return (
        <div>
            <div className='sticky-header'>
                <div className='row pg-hdr' style={{ width: "98%" }}>
                    <div className='col-xl-9 col-lg-8 col-md-8' /*style={{ paddingLeft: '30px' }}*/ >
                        <h2>Current Inventory</h2>
                        <p style={{ marginBottom: '0' }}>Select Invoice button to add an invoice</p>
                    </div>
                </div>
                <hr style={{ margin: '0' }} />
            </div>

            <Tabs value={value} onChange={handleChange} sx={{ marginLeft: '18px' }}>
                <Tab label="Front of House" sx={{ textTransform: 'none' }} />
                <Tab label="Back of House" sx={{ textTransform: 'none' }} />
            </Tabs>
            <hr style={{ margin: '0' }} />
            {tabPage(value)}  {/* Display the component corresponding to the current tab */}

        </div>
    )
};

