import React, { useEffect } from 'react';
import useFetch from "../../services/useFetch.js"
import CircularProgress from '@mui/material/CircularProgress';

export default function LowestMenuItem({ reload }) {
    const apiUrl = `api/menu-margin/lowest`

    const { data, error, load } = useFetch(apiUrl, reload)

    useEffect(() => {
        if (data === null) {
            return
        }
    }, [data])


    if (load) {
        return (<div className="container"><CircularProgress /></div>)
    }
    return (
        <>
            <div className='row' style={{ margin: "15px 0 0 0" }}>
                <p className='dsh-hr-ttl'>Lowest Margin Menu Item</p>
            </div>
            {/* <hr style={{ margin: "5px 0 15px 0" }} /> */}
            <div className='row' style={{ margin: "0 20px 10px 0" }}>
                <div className='col-8'>
                    <p >{data.posMenuItem}:</p>
                </div>
                <div className='col-2'>
                    <p style={{ color: '#1fb6ff' }}>{data.profitMargin && data.profitMargin.toFixed(2)}%</p>
                </div>
            </div>
        </>
    );
};
