/* 
    Page with 3 tabs: Menu, Vendors, Raw ingredients
    Each tab should allow the user to select and add to locations not current at
*/
import React, { useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useLocation } from "../context/LocationContext.js"
import IngredientConfig from '../components/MerchantConfig/IngredientConfig';
import MenuConfig from '../components/MerchantConfig/MenuConfig'
import VendorConfig from '../components/MerchantConfig/VendorConfig'
import CustomMenuConfig from '../components/MerchantConfig/CustomMenuConfig';

export default function ConfigureMerchant() {
    const [value, setValue] = useState(0);
    const { selectedLocation } = useLocation();
    const [selLocation, setSelLocation] = useState(selectedLocation)

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    // Function to return the component based on the tab index
    const tabPage = (index) => {
        switch (index) {
            case 0:
                return <MenuConfig selLoc={selLocation} setLoc={(loc) => setSelLocation(loc)} />;
            case 1:
                return <CustomMenuConfig selLoc={selLocation} setLoc={(loc) => setSelLocation(loc)} />;
            case 2:
                return <IngredientConfig selLoc={selLocation} setLoc={(loc) => setSelLocation(loc)} />;
            case 3:
                return <VendorConfig selLoc={selLocation} setLoc={(loc) => setSelLocation(loc)} />;
            default:
                return null;
        }
    };
    return (
        <div>
            <div className="sticky-header">
                <div className='row pg-hdr' style={{ width: "100%" }}>
                    <div className="col-xl-9 col-lg-8 col-md-8" >
                        <h2>Multi-location Setup</h2>
                        <p style={{ marginBottom: '0' }}>Add and copy recipes, stock items, and vendors</p>
                    </div>
                </div>
                <hr style={{ margin: '0', marginBottom: '0', width: "100%" }} />
            </div>

            <Tabs value={value} onChange={handleChange} sx={{ marginLeft: '18px' }}>
                <Tab label="Menu Recipes" sx={{ textTransform: 'none' }} />
                <Tab label="Custom Menu" sx={{ textTransform: 'none' }} />
                <Tab label="Ingredients" sx={{ textTransform: 'none' }} />
                <Tab label="Vendors" sx={{ textTransform: 'none' }} />
            </Tabs>
            <hr style={{ margin: '0', marginBottom: '5px' }} />
            {tabPage(value)}

        </div>
    );
};
