import React from 'react';
import { GridToolbarContainer, GridToolbarQuickFilter } from "@mui/x-data-grid"
import SetupLocation from './SetupLocation';
import SetupActions from './SetupActions';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';


export default function SetupCustomToolBar({ actionChoice, selLoc, locVal, selItem, menuPg, openDrawer }) {

    const actionOptions = (type) => {
        switch (type) {
            case "add_item":
                actionChoice("add_item")
                break;

            case "copy_to_location":
                actionChoice("copy_to_location")
                break;
        }
    }
    return (
        <GridToolbarContainer sx={{ paddingTop: '10px' }}>
            <div className="col-xl-2 col-lg-3 me-auto d-flex align-items-center">
                <SetupActions selection={actionOptions} selectedItems={selItem} />
                {menuPg && <div className="col-xl-2 col-lg-3 me-auto d-flex align-items-center" style={{ width: '300px' }}>
                    <button style={{
                        marginTop: '0', display: "flex", alignItems: "center",
                        height: '32px', border: selItem?.length === 1 ? "solid 1px #1fb6ff" : "solid 1px lightgray", maxWidth: '300px'
                    }} className={selItem?.length === 1 ? "delete-bttn" : "delete-dis"}
                        disabled={selItem.length === 0 ? true : false}
                        onClick={() => openDrawer(true)}
                    >
                        <AssignmentOutlinedIcon sx={{
                            color: selItem?.length === 1 ? "#1fb6ff" : "lightgray",
                            '&:hover': { color: selItem.length === 1 ? "#1fb6ff" : "lightgray" }, cursor: "pointer"
                        }} fontSize="small" />
                        <span style={{ paddingTop: '2px', paddingLeft: '5px', color: selItem?.length === 1 ? "#1fb6ff" : "lightgray" }}>SEE RECIPE</span>
                    </button>
                </div>}
            </div>
            <GridToolbarQuickFilter
                variant="outlined"
                size="small"
                sx={{
                    border: 'solid #1fb6ff', borderWidth: '1px', borderRadius: '5px', width: '225px', padding: '0', height: '32px',
                    '&.MuiFormControl-root': {
                        margin: '0'
                    }, '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderColor: 'transparent', // Removes the default border color
                        },
                        '&:hover fieldset': {
                            borderColor: 'transparent',
                            backgroundColor: "#1976d20a" // Keeps the border color transparent on hover
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: 'transparent', // Ensures the border color remains the same when focused
                        },
                        '.MuiSvgIcon-root': { color: '#1fb6ff' },
                        '&.MuiInputBase-root input': { fontSize: 'small', color: '#1fb6ff', padding: '5px 0' }
                    }
                }}
            />
            <SetupLocation locVal={locVal} locationParam={(loc) => selLoc(loc)} />
        </GridToolbarContainer>
    )
};
