import React, { useState, useEffect } from 'react';
import { states } from '../Inventory/UsStates';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import PostData from '../../services/PostData.js';
import CircularProgress from '@mui/material/CircularProgress';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import useFetchWithParams from "../../services/useFetchWithParams.js"
import SnackbarEx from '../Helpers/SnackbarEx.jsx';

export default function EditVendorInformation({ close, vendId }) {

    const { data: vendorInfo, load, error } = useFetchWithParams("api/get-vendor-info", { vendorId: vendId.vendorId })
    const [submittingData, setSubmittingData] = useState(false)
    const [openTestSnack, setOpenTestSnack] = useState({
        color: "",
        message: "",
        open: false
    })

    const [vendorDetails, setVendorDetails] = useState(null)

    useEffect(() => {
        if (vendorInfo) {
            setVendorDetails({
                ...vendorInfo,
                "vendor": vendorInfo.vendor,
                "vendorId": vendorInfo.vendorId,
                "street": vendorInfo.street,
                "city": vendorInfo.city,
                "zip": vendorInfo.zip,
                "state": vendorInfo.state,
                "vendorEmail": vendorInfo.vendorEmail ?? null,
                "emailOrdering": vendorInfo.emailOrdering ?? false
            })
        }
    }, [vendorInfo])

    const handleInputChange = ({ target }, key) => {
        setVendorDetails(prevVals => ({ ...prevVals, [key]: target.value }))
    }

    const handleStateSelection = ({ target }, val, key) => {
        setVendorDetails(prevVals => ({ ...prevVals, [key]: val }))
    }

    const updateEmailPref = () => {
        const curEmailPref = vendorDetails.emailOrdering
        setVendorDetails(prevVals => ({ ...prevVals, ["emailOrdering"]: !curEmailPref }))
    }

    const closePop = () => {
        close(false)
    }

    const handleSubmit = async () => {
        setSubmittingData(true)
        const { success: vendorEditSuccess, error: vendorEditErr } = await PostData(vendorDetails, "api/edit-existing-vendor", false)
        if (vendorEditSuccess) {
            setSubmittingData(false)
            close('success_edit')
        } else {
            setSubmittingData(false)
            setOpenTestSnack({ color: "error", message: "Unable to successfully edit.", open: true })
        }
    }

    if (load) {
        return (<div className="container"><CircularProgress /></div>)
    } else if (submittingData) {
        return (
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", position: "relative", left: "33%", height: "42vh", width: "max-content" }}>
                <CircularProgress />
                <p style={{ marginTop: "8px", color: "black", width: "max-content" }}>Updating vendor information...</p>
            </div>
        )
    }
    return (
        <div style={{ height: "72vh", width: "100%" }}>
            <div className='row' style={{ position: 'relative', left: '3%', top: '2%', padding: '10px' }}>
                <div className='col-9'>
                    <h4 style={{ marginBottom: "0" }}>Add Vendor: </h4>
                </div>
                <div className='col-3' style={{ position: 'relative', left: "60px" }}>
                    <button type="button" className='blank-bttn'>
                        <CloseIcon onClick={closePop} />
                    </button>
                </div>

            </div>
            <hr style={{ marginBottom: '35px' }} />

            <div className='row' style={{ marginBottom: "0", justifyContent: "center", alignItems: "center", marginLeft: '15px' }}>
                <div className='col-4' style={{ paddingRight: '0' }}>

                    <TextField
                        disabled
                        value={"Vendor Name"}
                        sx={{
                            width: '100%',
                            maxWidth: '187px',
                            '&.MuiTextField-root .MuiInputBase-root': {
                                backgroundColor: 'lightgray',
                                borderRadius: '0',
                            },
                            ".MuiOutlinedInput-input.Mui-disabled": {
                                WebkitTextFillColor: "#2c2c2c",
                            },
                        }}
                        size='small' />
                </div>
                <div className='col-8' style={{ paddingLeft: "0" }}>
                    <TextField
                        required
                        value={vendorDetails?.vendor ?? ""}
                        onChange={(e) => handleInputChange(e, 'vendor')}
                        sx={{
                            borderRadius: '5px',
                            width: '300px',
                            '&.MuiTextField-root .MuiInputBase-root': {
                                borderRadius: '0'
                            },
                            '&.MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: 'gray', // Default outline color
                                },
                            }
                        }}
                        size='small' />
                </div>
            </div>

            <div className='row' style={{ marginBottom: "0", justifyContent: "center", alignItems: "center", marginLeft: '15px' }}>
                <div className='col-4' style={{ paddingRight: '0' }}>

                    <TextField
                        disabled
                        value={"Street"}
                        sx={{
                            width: '100%',
                            maxWidth: '187px',
                            '&.MuiTextField-root .MuiInputBase-root': {
                                backgroundColor: 'lightgray',
                                borderRadius: '0',
                            },
                            ".MuiOutlinedInput-input.Mui-disabled": {
                                WebkitTextFillColor: "#2c2c2c",
                            },
                        }}
                        size='small' />
                </div>
                <div className='col-8' style={{ paddingLeft: "0" }}>
                    <TextField
                        required
                        value={vendorDetails?.street ?? ""}
                        onChange={(e) => handleInputChange(e, 'street')}
                        sx={{
                            borderRadius: '5px',
                            width: '300px',
                            '&.MuiTextField-root .MuiInputBase-root': {
                                borderRadius: '0'
                            },
                            '&.MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: 'gray', // Default outline color
                                },
                            }
                        }}
                        size='small' />
                </div>
            </div>

            <div className='row' style={{ marginBottom: "0", justifyContent: "center", alignItems: "center", marginLeft: '15px' }}>
                <div className='col-4' style={{ paddingRight: '0' }}>

                    <TextField
                        disabled
                        value={"City"}
                        sx={{
                            width: '100%',
                            maxWidth: '187px',
                            '&.MuiTextField-root .MuiInputBase-root': {
                                backgroundColor: 'lightgray',
                                borderRadius: '0',
                            },
                            ".MuiOutlinedInput-input.Mui-disabled": {
                                WebkitTextFillColor: "#2c2c2c",
                            },
                        }}
                        size='small' />
                </div>
                <div className='col-8' style={{ paddingLeft: "0" }}>
                    <TextField
                        required
                        value={vendorDetails?.city ?? ""}
                        onChange={(e) => handleInputChange(e, 'city')}
                        sx={{
                            borderRadius: '5px',
                            width: '300px',
                            '&.MuiTextField-root .MuiInputBase-root': {
                                borderRadius: '0'
                            },
                            '&.MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: 'gray', // Default outline color
                                },
                            }
                        }}
                        size='small' />
                </div>
            </div>

            <div className='row' style={{ marginBottom: "0", justifyContent: "center", alignItems: "center", marginLeft: '15px' }}>
                <div className='col-4' style={{ paddingRight: '0' }}>

                    <TextField
                        disabled
                        value={"State"}
                        sx={{
                            width: '100%',
                            maxWidth: '187px',
                            '&.MuiTextField-root .MuiInputBase-root': {
                                backgroundColor: 'lightgray',
                                borderRadius: '0',
                            },
                            ".MuiOutlinedInput-input.Mui-disabled": {
                                WebkitTextFillColor: "#2c2c2c",
                            },
                        }}
                        size='small' />
                </div>
                <div className='col-8' style={{ paddingLeft: "0" }}>
                    <Autocomplete
                        required
                        options={states}
                        value={vendorDetails?.state ?? ""}
                        onChange={(e, val) => handleStateSelection(e, val, 'state')}
                        sx={{
                            borderRadius: '0',
                            width: '300px',
                            '.MuiInputBase-root': {
                                borderRadius: '0'
                            },
                            '&.MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: 'gray', // Default outline color
                                },
                            }
                        }}
                        size='small'
                        renderInput={(params) => (
                            <TextField
                                {...params}
                            />
                        )}>
                    </Autocomplete>
                </div>
            </div>

            <div className='row' style={{ marginBottom: "0", justifyContent: "center", alignItems: "center", marginLeft: '15px' }}>
                <div className='col-4' style={{ paddingRight: '0' }}>

                    <TextField
                        disabled
                        value={"Zip"}
                        sx={{
                            width: '100%',
                            maxWidth: '187px',
                            '&.MuiTextField-root .MuiInputBase-root': {
                                backgroundColor: 'lightgray',
                                borderRadius: '0',
                            },
                            ".MuiOutlinedInput-input.Mui-disabled": {
                                WebkitTextFillColor: "#2c2c2c",
                            },
                        }}
                        size='small' />
                </div>
                <div className='col-8' style={{ paddingLeft: "0" }}>
                    <TextField
                        required
                        value={vendorDetails?.zip ?? ""}
                        onChange={(e) => handleInputChange(e, 'zip')}
                        sx={{

                            width: '300px',
                            '&.MuiTextField-root .MuiInputBase-root': {
                                borderRadius: '0'
                            },
                            '&.MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: 'gray', // Default outline color
                                },
                            }
                        }}
                        size='small' />
                </div>
            </div>

            <div className='row' style={{ marginLeft: '15px', marginTop: '20px', alignItems: 'center' }}>
                <div className='col-1' style={{ paddingRight: "0", width: "5%" }}>
                    <Checkbox sx={{ padding: "0" }} checked={vendorDetails?.emailOrdering ?? false} onChange={updateEmailPref} />
                </div>
                <div className='col-3'>
                    <p style={{ margin: '0' }}>Email ordering?</p>
                </div>
            </div>

            {vendorDetails?.emailOrdering &&
                <div className='row' style={{ marginBottom: "0", marginLeft: '15px', marginTop: "15px" }}>
                    <div className='col-4' style={{ paddingRight: '0' }}>

                        <TextField
                            disabled
                            value={"Email address"}
                            sx={{
                                width: '100%',
                                maxWidth: '187px',
                                '&.MuiTextField-root .MuiInputBase-root': {
                                    backgroundColor: 'lightgray',
                                    borderRadius: '0',
                                },
                                ".MuiOutlinedInput-input.Mui-disabled": {
                                    WebkitTextFillColor: "#2c2c2c",
                                },
                            }}
                            size='small' />
                    </div>

                    <div className='col-8' style={{ paddingLeft: "0" }}>
                        <TextField
                            required
                            placeholder='email@vendor.com'
                            value={vendorDetails?.vendorEmail ?? null}
                            onChange={(e) => handleInputChange(e, 'vendorEmail')}
                            sx={{
                                borderRadius: '5px',
                                width: '300px',
                                '&.MuiTextField-root .MuiInputBase-root': {
                                    borderRadius: '0'
                                },
                                '&.MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: 'gray', // Default outline color
                                    },
                                }
                            }}
                            size='small' />
                    </div>
                </div>

            }


            <div className='row'

                style={{ paddingBottom: "0", paddingLeft: '5.5%', justifyContent: 'start', position: "relative", top: vendorDetails?.emailOrdering ? '22.46%' : '30.7%' }}>
                <button className='submit' type='submit' style={{ width: "90%", marginBottom: '10px' }} onClick={handleSubmit}>
                    Submit
                </button>
            </div>

            {openTestSnack.open && <SnackbarEx color={openTestSnack.color} message={openTestSnack.message} open={openTestSnack.open}
                close={() => setOpenTestSnack({
                    color: "",
                    message: "",
                    open: false
                })} />}

        </div>
    );
};
