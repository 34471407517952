import React from 'react';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';

export default function ConfirmCopyCustom({ copyItem, curLoc }) {
    const handleNo = () => {
        copyItem(false)
    }
    const handleYes = () => {
        copyItem(true)
    }
    return (
        <div style={{ height: '20vh', margin: '15px 35px 20px 35px' }}>
            <div className='row' style={{ position: 'relative', left: '4%', top: '10%', marginBottom: "20px" }}>
                <div className='col-1' style={{ padding: '0', width: 'max-content' }}>
                    <WarningAmberRoundedIcon sx={{ color: 'orange', marginBottom: '5px' }} fontSize='small' />
                </div>
                <div className='col-10' style={{ margin: '0', paddingLeft: '5px' }}>
                    <p style={{ fontWeight: 'bold', margin: '0' }}>Item contains custom menu item.</p>
                </div>
            </div>
            <div className='row' style={{ position: 'relative', left: '1%', width: "375px" }}>
                <p>Select <i>Copy</i> to also copy custom menu item from {curLoc}.</p>
            </div>
            <div className='row' style={{ marginTop: '25px', marginBottom: '20px', position: 'relative', left: '17%' }}>
                <div className='col-4'>
                    <button className='submit' onClick={handleNo} style={{ width: "100px", height: "30px", backgroundColor: "#e0e0e0", color: 'black' }}>No</button>
                </div>

                <div className='col-6'>
                    <button className='submit' onClick={handleYes} style={{ width: "100px", height: "30px", margin: "0 10px 0 0", backgroundColor: "#FFBF00", color: 'black' }}>Copy</button>
                </div>
            </div>

        </div>
    );
};
