import React, { useEffect, useState } from 'react';
import useFetch from '../../services/useFetch.js'
import { DataGrid } from "@mui/x-data-grid"
import CircularProgress from '@mui/material/CircularProgress';

export default function CategoryTable({ startDate, endDate, reload }) {

    const [row, setRow] = useState([])
    const [col, setCol] = useState([])
    const { data, error, load } = useFetch(`api/menu-category-costs?startDateStr=${startDate}&endDateStr=${endDate}`, reload)

    useEffect(() => {
        if (data === null) {
            return
        } else {
            setData()
        }

    }, [data])

    const setData = () => {
        setRow(data.map((e, ind) =>
            ({ id: ind, col1: e.category ? e.category : "Misc", col2: "$" + e.menuCategoryPurchasePrice.toFixed(2), col3: "$" + e.menuCategoryAvgPurchasePrice.toFixed(2) })
        ))

        setCol([
            { field: 'col1', headerName: 'Menu Category', flex: 1 },
            { field: 'col2', headerName: 'Total Spent', flex: 1 },
            { field: 'col3', headerName: 'Average Spent', flex: 1 }

        ]);
    }
    if (load) return (<div className="container"><CircularProgress /></div>)
    return (
        <>
            <div className='row'>
                <p style={{ padding: "15px 20px 0px 20px", color: "gray" }}>Spending by Menu Category</p>
            </div>
            <hr style={{ margin: "0" }} />
            <div className='row' >
                <div className="col-12" style={{ height: "45vh" }} >
                    <DataGrid
                        rows={row}
                        columns={col}
                        rowHeight={35}
                        pageSize={10}
                        pageSizeOptions={[10, 25]}

                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 10,
                                },
                            },
                        }}
                        sx={{
                            padding: "10px 0 0 25px", border: "none", overflowY: "scroll",
                            '.MuiToolbar-root': { alignItems: "baseline" },
                            '.MuiSvgIcon-root': { margin: 0 },
                            '&.MuiDataGrid-main': { marginRight: "20px" },
                            '&.MuiDataGrid-root': { paddingLeft: "0" },
                            '.MuiDataGrid-columnHeaderTitle': { fontSize: '14px', fontWeight: '600', '@media (max-width:1080px)': { fontSize: '11px' } },
                            '.MuiDataGrid-toolbarContainer': { justifyContent: 'flex-end', marginRight: "10px" },
                            '.MuiDataGrid-cellContent': { fontSize: "small" }
                        }}
                    />
                </div>
            </div >

        </>
    );
};
