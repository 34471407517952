/* 
    For now this will simply contain the header input for the invoice manual entry.
    I.e. vendor name, Street, etc + date and inv. number
*/

import React, { useEffect, useState } from 'react';
import { TextField, Autocomplete, InputAdornment } from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import CircularProgress from '@mui/material/CircularProgress';
import dayjs from 'dayjs';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import useFetch from "../../services/useFetch.js"
import GetData from '../../services/GetData.js';
import { states } from "./UsStates.js"
import { FirstUpper } from '../../utils/FirstUpper.js';
import { DateField } from '@mui/x-date-pickers';

const apiUrl = "api/vendors"

export default function ManualEntryHeader({ updateHeader, invoiceOrigin, invOriginVendor, uploadEdit }) {

    const [startAnchorElem, setStartAnchorElem] = useState(null);
    const [startDate, setStartDate] = useState(null)
    const openStartCal = Boolean(startAnchorElem);

    const dateOptions = { month: '2-digit', day: '2-digit', year: 'numeric' }
    const [curVendor, setCurVendor] = useState(false)
    const stateArr = states.sort()

    const { data, error, load } = useFetch(apiUrl, false)

    const [rowData, setRowData] = useState([
        {
            vendor: "",
            vendorId: null,
            street: "",
            city: "",
            zip: "",
            state: "",
            invoiceNum: "",
            date: ""
        }
    ]);

    useEffect(() => {
        if (invoiceOrigin && invOriginVendor) {
            fetchInvoice()
        }
    }, [])
    const fetchInvoice = async () => {
        const { getData, error } = await GetData("api/invoicedetail",
            { "invnum": invoiceOrigin, "vendorId": invOriginVendor }, null, null, null)

        if (getData) {
            const origVendor = [...rowData]
            origVendor[0]["vendor"] = getData.vendor
            if (uploadEdit) {
                const uploadDate = new Date(getData.date)
                const reformDate = uploadDate.toLocaleDateString('en-US', dateOptions)
                origVendor[0]["date"] = reformDate
                setStartDate(reformDate)
                origVendor[0]["invoiceNum"] = getData.invoiceNum
            }
            setRowData(origVendor)
        }
        if (error) { console.log(error) }
    }
    const handleVenChange = (e, val, reason, rowKey) => {

        if (reason !== 'clear') {

            let upperVal;
            if (val.length > 0) upperVal = FirstUpper(val)
            const newVendor = [...rowData]
            newVendor[0][rowKey] = upperVal
            let nmIndx

            nmIndx = data.findIndex(item => item.vendor === val)

            if (nmIndx >= 0) {
                newVendor[0]['street'] = data[nmIndx].street
                newVendor[0]['vendorId'] = data[nmIndx].vendorId
                newVendor[0]['city'] = data[nmIndx].city
                newVendor[0]['state'] = data[nmIndx].state

                newVendor[0]['zip'] = data[nmIndx].zip
            }
            setRowData(newVendor)
            updateHeader(newVendor)
        } else {
            setRowData([
                {
                    vendor: "",
                    vendorId: null,
                    street: "",
                    city: "",
                    zip: "",
                    state: "",
                    invoiceNum: "",
                    date: ""
                }
            ])
            setCurVendor(false)
        }
    }

    const handleInputChange = (e, val, rowKey) => {
        if (rowKey === 'state') {
            const uin = val
            const newData = [...rowData]
            newData[0][rowKey] = uin
            setRowData(newData)
        } else if (rowKey === 'date') {
            setStartDate(e)
            const newData = [...rowData]
            const date = new Date(e)
            const reformDate = date.toLocaleDateString('en-US', dateOptions)
            newData[0][rowKey] = reformDate
            // (MM/dd/yyyy)
            setRowData(newData)
        }
        else {
            const uin = e.target.value
            const newData = [...rowData]
            newData[0][rowKey] = uin

            setRowData(newData)
        }
    }

    const handleDateDropdown = (elem) => {
        const elClicked = elem.currentTarget
        setStartAnchorElem(elClicked)
    }

    const handleStartDate = ({ target }) => {
        if (!target.dataset['timestamp']) { return }
        const selDate = target.dataset['timestamp']
        const date = new Date(parseInt(selDate))
        const reformDate = date.toLocaleDateString('en-US', dateOptions)

        setStartDate(reformDate)
        setStartAnchorElem(false)

        const newData = [...rowData]
        newData[0]['date'] = reformDate

        setRowData(newData)
    }

    if (load) {
        return (<div className="container"><CircularProgress /></div>)
    }
    return (
        <>
            <div className='col-6' style={{ height: 'fit-content' }}>
                <div className='row vndr'>
                    {/* Vendor name */}
                    <Autocomplete
                        freeSolo
                        onInputChange={(e, val, reason) => handleVenChange(e, val, reason, 'vendor')}
                        options={data.map((vndr) => vndr.vendor)}
                        value={rowData[0]["vendor"] || ''}

                        renderInput={(params) => <TextField {...params}
                            required
                            placeholder="Vendor Name" variant="standard"
                            sx={{ width: '340px', '.MuiInputLabel-root, .MuiInput-root': { fontSize: "small" } }} />}
                    />
                </div>
                {!curVendor ?
                    <>
                        <div className='row vndr' style={{ marginTop: '10px' }}>
                            <TextField className="inv-hd" variant="standard" placeholder='Street'
                                required
                                value={rowData[0].street}
                                onChange={(e) => handleInputChange(e, null, 'street')}
                                sx={{ '.MuiInputLabel-root, .MuiInput-root,.MuiInput-root': { fontSize: "small" } }} />
                        </div>
                        <div className='row' style={{ margin: '10px 0 0 3%' }}>
                            {/* Vendor City, St, Zip */}
                            <div className='col-3'>
                                <TextField className="inv-hd" variant="standard" placeholder='City'
                                    required
                                    value={rowData[0].city}
                                    onChange={(e) => handleInputChange(e, null, 'city')}
                                    sx={{ '.MuiInputLabel-root, .MuiInput-root,.MuiInput-root': { fontSize: "small" } }} />
                            </div>

                            {/* This is the state -- should make this an Autocomplete with all the state abbreviations */}
                            <div className='col-2' >
                                <Autocomplete
                                    freeSolo
                                    value={rowData[0].state}
                                    onInputChange={(e, val, reason) => handleInputChange(e, val, 'state')}
                                    options={stateArr}
                                    renderInput={(params) => <TextField {...params} required
                                        placeholder="State" variant="standard" sx={{ '.MuiInputLabel-root, .MuiInput-root': { fontSize: "small" } }} />}
                                />

                            </div>
                            <div className='col-2'>
                                <TextField className="inv-hd" variant="standard" placeholder='Zip'
                                    required
                                    value={rowData[0].zip}
                                    onChange={(e) => handleInputChange(e, null, 'zip')}
                                    sx={{ '.MuiInputLabel-root, .MuiInput-root,.MuiInput-root': { fontSize: "small" } }} />
                            </div>
                        </div>
                    </> :
                    <>
                        <div className='row vndr' style={{ marginTop: '10px' }}>
                            <TextField className="inv-hd" variant="standard"
                                required
                                value={rowData[0].street}
                                onChange={(e) => handleInputChange(e, null, 'street')}
                                sx={{ '.MuiInputLabel-root, .MuiInput-root,.MuiInput-root': { fontSize: "small" } }} />
                        </div>
                        <div className='row' style={{ margin: '10px 0 0 3%' }}>
                            {/* Vendor City, St, Zip */}
                            <div className='col-3'>
                                <TextField className="inv-hd" variant="standard" required
                                    value={rowData[0].city}
                                    onChange={(e) => handleInputChange(e, null, 'city')}
                                    sx={{ '.MuiInputLabel-root, .MuiInput-root,.MuiInput-root': { fontSize: "small" } }} />
                            </div>

                            {/* This is the state -- should make this an Autocomplete with all the state abbreviations */}
                            <div className='col-2' >
                                <TextField className="inv-hd" variant="standard" required
                                    value={rowData[0].state}
                                    onChange={(e) => handleInputChange(e, null, 'state')}
                                    sx={{ '.MuiInputLabel-root, .MuiInput-root,.MuiInput-root': { fontSize: "small" } }} />
                            </div>
                            <div className='col-2'>
                                <TextField className="inv-hd" variant="standard" required
                                    value={rowData[0].zip}
                                    onChange={(e) => handleInputChange(e, null, 'zip')}
                                    sx={{ '.MuiInputLabel-root, .MuiInput-root,.MuiInput-root': { fontSize: "small" } }} />
                            </div>
                        </div>
                    </>}
            </div>
            <div className='col-6' style={{ height: 'fit-content' }}>
                <div className='row' style={{ flexDirection: 'column', alignContent: 'end' }}>
                    <div className='col-5' >
                        <TextField
                            className="inv-hd" placeholder="Invoice #"
                            variant="standard" required={true}
                            value={rowData[0].invoiceNum || ""}
                            onChange={(e) => handleInputChange(e, null, 'invoiceNum')}
                            label='Invoice #'
                            InputLabelProps={{
                                shrink: !!rowData[0].invoiceNum, // Forces label to shrink when there's a value
                            }}
                            sx={{ '.MuiInputLabel-root, .MuiInput-root': { fontSize: "small" } }} />
                    </div>
                    <div className='col-5' style={{ marginTop: '15px' }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateField
                                variant='standard'
                                label="Invoice Date"
                                value={startDate ? dayjs(startDate) : ""}
                                sx={{
                                    '.MuiInputLabel-root, .MuiInput-root': { fontSize: "small" }
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <button className='blank-bttn' onClick={handleDateDropdown}>
                                                <CalendarMonthIcon onChange={(e) => handleInputChange(e, null, 'date')} />
                                            </button>
                                        </InputAdornment>
                                    ),
                                }}
                                onChange={(e) => handleInputChange(e, null, 'date')}
                            />
                        </LocalizationProvider>
                    </div>
                </div>
            </div>
            <div>
                <Menu open={openStartCal} onClose={() => setStartAnchorElem(false)} anchorEl={startAnchorElem}
                    sx={{
                        ".MuiPaper-root": { borderRadius: "15px", height: "200px", width: "200px" },
                        ".MuiDateCalendar-root": { height: "100%" },
                        ".MuiList-root": { height: "200px" },
                        ".MuiButtonBase-root": { height: "210px" },
                        ".MuiPickersYear-root": { height: "20px" },
                        ".MuiTouchRipple-root": { height: "30px" },
                        ".MuiPickersCalendarHeader-root": { padding: 0, fontSize: "small", margin: '0' },
                        ".MuiPickersCalendarHeader-labelContainer": { height: "30px" },
                        ".MuiPickersArrowSwitcher-button": { padding: 0, fontSize: "medium", height: '30px' },
                        ".MuiPickersCalendarHeader-switchViewButton": { padding: 0, fontSize: "small", height: '30px' },
                        ".MuiPickersCalendarHeader-labelContainer": { padding: 0, fontSize: "small" },
                        ".MuiPickersDay-root": { height: "20px" },
                        ".MuiPickersYear-yearButton": { height: '20px', padding: 0, fontSize: "small" },
                        ".Mui-selected": { height: '20px', padding: 0, fontSize: "small" },
                        ".MuiYearCalendar-root": { width: '200px', padding: 0, fontSize: "small" }
                    }}
                >
                    <MenuItem>

                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateCalendar value={startDate ? dayjs(startDate) : dayjs(new Date())}
                                onClick={handleStartDate}
                            />
                        </LocalizationProvider>

                    </MenuItem>
                </Menu>
            </div>
        </>
    );
};
