import React, { useState, useEffect, useContext } from 'react'
// import { locationContext } from '../../context/LocationContext.js';
import useFetch from '../../../../services/useFetch.js';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import Checkbox from '@mui/material/Checkbox';

/* 
    This is the module/component that will allow a user to add a menu item and then dynamically add ingredients
    that the menu item is composed of.
    Once the menu item is submitted, it will be used to determine the inventory lost when that item is purchased.
    Ingredient measurements do not need to be exact, but this tool will assume that each time the menu item is puchased by a customer,
        the ingredients used are to a very similar unit of measure as every other time the item has been purchased.
*/
export default function EditAddIngredients({ ingrData, updateIngredients, itemCategory }) {

    /*Fetches the data ingredients to display in the dropdown */
    const [load, setLoad] = useState(false)
    const [selectedData, setSelectedData] = useState(false)
    const { data, error } = useFetch('api/ingredients', false)

    const [ingredients, setIngredients] = useState([
        {
            name: "",
            type: "regular",
            id: 0,
            toGoOnly: false,
            modOverride: false,
            units: [{ amount: "", measurement: "" }]
        }
    ]);

    useEffect(() => {
        if (ingrData !== null) {
            setLoad(false)
            if (ingrData && ingrData.ingredients) {

                updateIngredients(ingrData.ingredients)
                setIngredients(ingrData.ingredients);
            } else {
                console.log("No ingredients found");
            }
        } else {
            console.log("NULL DATA")
        }

    }, [ingrData])

    const handleNameChange = (index, val, reason) => {

        if (reason !== 'clear') {
            const newId = [...ingredients]
            const newIngredient = [...ingredients]
            newIngredient[index]['name'] = val.ingredient
            newId[index]['id'] = val.ingredientId

            updateIngredients(newIngredient)
            handleIdChange(newId)

            handleMeasureChange(val, index)
            setIngredients(newIngredient)
        }
    }

    const handleIdChange = (ingredientId) => {
        updateIngredients(ingredientId)
    }

    const handleAmountChange = (index, { target }) => {
        const newIngredient = [...ingredients]
        newIngredient[index]['units'][0].amount = parseFloat(target.value)

        updateIngredients(newIngredient)
    }

    /* ---------------------------------------------------------------------------------------------------------------------
    Will grab the measurement of the ingredient based on the ingredient chosen by the user 
    ---------------------------------------------------------------------------------------------------------------------*/
    const handleMeasureChange = (value, index) => {
        // value = item (Lid) -> we want to find the index of the Lid and pull the measurement from that index

        const newIngredient = [...ingredients]

        let fin = data.find(ele => ele.ingredientId === value.ingredientId)

        newIngredient[index]['units'][0].measurement = fin.recipeUoM
    }

    const handleCheck = (e, val, index, checkType) => {
        const newIngredient = [...ingredients]
        if (checkType === 'togo') {
            newIngredient[index]["toGoOnly"] = !newIngredient[index]["toGoOnly"]
        } else {
            newIngredient[index]["modOverride"] = !newIngredient[index]["modOverride"]
        }
        updateIngredients(newIngredient)
    }

    /* ---------------------------------------------------------------------------------------------------------------------
        This will delete the most recently added item to the Menu Item
    ---------------------------------------------------------------------------------------------------------------------*/
    const deleteRow = (index) => {
        const ingredientList = [...ingredients]
        const last = ingredients.length - 1

        if (index !== 0) {
            const indFrom = ingredientList.splice(0, index)
            const indTo = ingredientList.splice(index, last)
            const newList = indFrom.concat(indTo)

            setIngredients(newList)
            updateIngredients(newList)
        } else {
            setIngredients(ingredientList.splice(1, last))
            updateIngredients(ingredientList.splice(1, last))
        }


    }

    /* ---------------------------------------------------------------------------------------------------------------------
       This will add a new clothing + category dropdown on click of an add button
    ---------------------------------------------------------------------------------------------------------------------*/
    function onAdd() {
        setIngredients([...ingredients, {
            name: "", type: "regular",
            id: 0,
            toGoOnly: false, modOverride: false,
            units: [{ amount: "", measurement: "" }]
        }])
    }

    if (load) {
        return (<div className="container"><CircularProgress sx={{ position: 'relative', left: '40%', bottom: '50px' }} /></div>)
    }

    return (
        <>
            { /* ----------------------------------------------------------------------------------------------------------------
            Maps through the ingredient state value (default is single empy arr val) and it adds a row each time the user
            selects the + button
            ----------------------------------------------------------------------------------------------------------------*/}
            {ingredients.map((elem, ind) =>

                <div className='row' style={{ margin: "0 25px 5px 25px", alignItems: 'center' }} key={ind}>

                    {/* 
                    This is the actual ingredient name
                     */}

                    <div className='col-3'>
                        <Autocomplete options={data} size='small'
                            onChange={(e, val, reason) => handleNameChange(ind, val, reason)}
                            getOptionLabel={(option) => option.ingredient}
                            renderInput={(params) => <TextField {...params} placeholder={ingredients[ind].name ? ingredients[ind].name : "Select an ingredient..."}
                                // helperText={"Menu Item Ingredient"}
                                name='Ingredient'
                                sx={{ '::placeholder': { background: 'black' } }}
                            />}
                        />
                    </div>

                    {/* 
                    This is the Units for that specific ingredient in the menu item 
                    */}

                    {itemCategory !== "Modifier" &&
                        <div className='col-2'>
                            <TextField
                                placeholder='Add Ingredient Units...'
                                // helperText={"Ingredient Units"}
                                style={{ width: "100%" }}
                                type="number" onChange={(e) => handleAmountChange(ind, e)}
                                value={ingredients[ind].units[0].amount}
                                size='small'
                            />
                        </div>
                    }

                    {/* 
                    This is the meaurement for that specific ingredient in the menu item -- this is auto added based on the ingredient 
                    */}

                    <div className='col-2'>
                        <TextField style={{ width: "100%" }}
                            type="text"
                            value={elem.units[0].measurement} disabled size='small'
                        />
                    </div>

                    <div className="col-4" style={{ display: "flex", alignContent: "center" }}>
                        <div className='col-4' id='add-ingredient-togo'>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <Checkbox
                                    checked={ingredients[ind]["toGoOnly"]}
                                    onChange={(e, val) => handleCheck(e, val, ind, 'togo')} /> <label>To-Go only</label>
                            </div>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <Checkbox
                                    checked={ingredients[ind]["modOverride"]}
                                    onChange={(e, val) => handleCheck(e, val, ind, 'override')} /> <label>Mod. Override</label>
                            </div>

                        </div>

                        <div className='col-6' style={{ alignSelf: 'center' }}>
                            <AddCircleOutlineIcon onClick={onAdd} fontSize='medium' sx={{ marginRight: '10px', cursor: 'pointer' }} />
                            <DeleteIcon onClick={() => deleteRow(ind)} sx={{ cursor: 'pointer' }} fontSize='medium' />
                        </div>
                    </div>
                </div>

            )}
            {ingredients.length === 0 &&
                <div className='row' style={{ marginLeft: "20px" }}>
                    <div className='col-12' style={{ alignSelf: 'center', marginBottom: '20px' }}>
                        <button className='recipe-bttn' onClick={onAdd} style={{ display: 'flex', justifyContent: 'center', fontSize: '16px' }}>
                            <AddCircleOutlineIcon onClick={onAdd} fontSize='medium' sx={{ marginRight: '10px', cursor: 'pointer' }} />
                            Add Ingredients
                        </button>

                        {/* <DeleteIcon onClick={() => deleteRow(null)} sx={{ cursor: 'pointer' }} fontSize='medium' /> */}
                    </div>
                </div>
            }
        </>
    )
}