import axios from "axios"

axios.defaults.withCredentials = true
const baseUrl = process.env.REACT_APP_NETLIFY_API_URL || process.env.REACT_APP_API_URL


export default async function PostData(data, url, auth) {
    // const tkn = localStorage.getItem("urtkn")
    const tkn = sessionStorage.getItem("urtkn")
    const location = localStorage.getItem('usrlctn')

    if (!auth) {

        try {

            const response = await axios.post(`${baseUrl}/${url}`, { data, location: location },
                {
                    headers: { Authorization: `Bearer ${tkn}` }
                })

            if (response.status === 200) {

                return { success: true, error: false, load: false }
            } else { return { success: false, error: true, load: false } }

        } catch (err) {
            return { success: false, error: err, load: false }
        }
    }
    else if (auth) {

        try {
            const response = await axios.post(`${baseUrl}/${url}`,
                data)

            if (response.data.status === 200) {

                const usrNm = response.data.usrName
                const dbAcc = response.data.locationAccess
                const usrRole = response.data.role
                const token = response.data.tk

                return { success: true, usr: usrNm, db: dbAcc, role: usrRole, token: token, error: false, load: false }
            } else { return { success: false, error: true, load: false } }

        } catch (err) {
            return { success: false, error: err, load: false }
        }
    }
}