import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';

export default function EditIngredientModifier({ ingrData, updateModifiers }) {

    const [load, setLoad] = useState(false)
    const [selectedData, setSelectedData] = useState(false)
    // const { data, error } = useFetch('api/ingredients', false)

    const [modifiers, setModifiers] = useState([
        {
            modifiers: [{
                modifierName: "",
                modifierId: "",
                modifierPrice: 0,
                modOverride: false,
                ingredients: [{
                    name: "",
                    units: [{ amount: "", measurement: "" }]
                }]
            }]
        }
    ]);
    useEffect(() => {
        if (ingrData !== null) {

            if (ingrData && ingrData.modifiers) {
                updateModifiers(ingrData.modifiers)
                setModifiers(ingrData.modifiers);
                setLoad(false)
            } else {
                setLoad(false)
                console.log("No ingredients found");
            }
        } else {
            console.log("NULL DATA")
        }
    }, [ingrData])

    const handleAmountChange = (ingredientIndex, { target }, modifierIndex) => {

        setSelectedData(true)

        setModifiers(currentModifiers => {
            // Make a deep copy to ensure no mutation
            const newModifiers = currentModifiers.map((mod, idx) => {
                if (idx === ingredientIndex) {
                    return {
                        ...mod,
                        ingredients: mod.ingredients.map((ing, i) => {
                            if (i === modifierIndex) {
                                return {
                                    ...ing,
                                    units: ing.units.map(unit => ({
                                        ...unit,
                                        amount: parseFloat(target.value)  // Update the measurement for all units
                                    }))
                                };
                            }
                            return ing;
                        })
                    };
                }
                return mod;
            });
            updateModifiers(newModifiers)
            return newModifiers;
        });
    }

    /* ---------------------------------------------------------------------------------------------------------------------
    Will grab the measurement of the ingredient based on the ingredient chosen by the user 
    ---------------------------------------------------------------------------------------------------------------------*/
    const handleMeasureChange = (value, index) => {
        // value = item (Lid) -> we want to find the index of the Lid and pull the measurement from that index
        const measure = value.measurement
        const newIngredient = [...modifiers]

        newIngredient[index]['units'][0].measurement = measure
    }

    const handleCheck = (e, val, index) => {
        const newModifier = [...modifiers]
        newModifier[index]["modOverride"] = !newModifier[index]["modOverride"]
        updateModifiers(newModifier)
    }

    if (load) return (<div style={{ position: 'relative', left: '45%', top: '15%' }}><CircularProgress size={30} /></div>)
    return (
        <div>
            <div className='row' style={{ margin: "20px 0 0 20px" }}>
                <p style={{ fontSize: '20px' }}>Modifier Ingredients:</p>
            </div>
            {modifiers?.map((elem, ind) =>

                <div className='row' style={{ marginTop: "10px", marginBottom: '20px' }} key={ind}>
                    <div className='row'>
                        <div className='col-6' style={{ maxWidth: '745px' }}>

                            <TextField helperText={"Modifier Name"} style={{ margin: "0 0 20px 40px", width: "fit-content" }}
                                value={elem.modifierName} name='itemName' disabled
                                size='small' variant='standard'
                                sx={{
                                    "& .MuiInputBase-input.Mui-disabled": {
                                        WebkitTextFillColor: "#000000"
                                    },
                                    "& .MuiFormHelperText-root.Mui-disabled": {
                                        WebkitTextFillColor: "#000000"
                                    }
                                }}
                            />

                        </div>
                        <div className='col-3'>
                            <Checkbox
                                checked={Boolean(elem.modOverride)}
                                onChange={(e, val) => handleCheck(e, val, ind)} /> <label>Mod. Override</label>
                        </div>
                    </div>
                    {elem.ingredients && elem.ingredients.map((ingredient, ingIndex) => (
                        <div className='row' style={{ margin: "0 25px 10px 25px" }}>
                            <div className='col-3'>

                                <TextField placeholder={"Modifier Ingredient"}
                                    style={{ width: "100%" }} size='small'
                                    value={ingredient.name ? ingredient.name : ""}
                                    disabled
                                    sx={{
                                        "& .MuiInputBase-input.Mui-disabled": {
                                            WebkitTextFillColor: "#656565"
                                        },

                                    }}
                                />
                            </div>
                            <div className='col-2'>
                                <TextField placeholder='Add Ingredient Units...' style={{ width: "100%" }} size='small'
                                    type="number" onChange={(e) => handleAmountChange(ind, e, ingIndex)}
                                    value={ingredient.units ? ingredient.units[0].amount : ""}

                                />
                            </div>

                            <div className='col-2'>
                                <TextField style={{ width: "100%" }}
                                    value={ingredient.units ? ingredient.units[0].measurement : ""}
                                    size='small'
                                    placeholder={"Ingredient Measurement"} type="text" disabled
                                    sx={{
                                        "& .MuiInputBase-input.Mui-disabled": {
                                            WebkitTextFillColor: "#656565"
                                        }
                                    }} />
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};
