import React, { useEffect, useState } from 'react'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';
import MenuIcon from '@mui/icons-material/Menu';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ConfiguredDates from './ConfiguredDates';
import dayjs from 'dayjs';
import Popover from '@mui/material/Popover';

import { TextField, InputAdornment } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';


export default function DateFilter({ ingredient, dates, style, defaultDays, showLoading }) {

    const [startAnchorElem, setStartAnchorElem] = useState(null);
    const [endAnchorElem, setEndAnchorElem] = useState(null);
    const [hoverAnchorEl, setHoverAnchorEl] = useState(null);

    const openStartCal = Boolean(startAnchorElem);
    const openEndCal = Boolean(endAnchorElem);
    const openHoverCal = Boolean(hoverAnchorEl);

    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)

    const [hamburgerOpen, setHamburgerOpen] = useState(false)
    const [hamburgerAnchor, setHamburgerAnchor] = useState(null)

    const dateOptions = { month: '2-digit', day: '2-digit', year: 'numeric' }

    useEffect(() => {

        if (startDate === null && endDate === null) {
            const { start, end } = initializeDates()

            handleSubmit(start, end)
            setStartDate(start)
            setEndDate(end)
        }
        else if (startDate !== null && endDate !== null) {

            handleSubmit(startDate, endDate)
        }

    }, [ingredient])

    const initializeDates = () => {

        let todayDate = new Date()

        const lastWeekDate = new Date(todayDate);
        lastWeekDate.setDate(todayDate.getDate() - defaultDays);
        todayDate = todayDate.toLocaleDateString('en-US', dateOptions)
        const dateOne = lastWeekDate.toLocaleDateString('en-US', dateOptions)

        return { start: dateOne, end: todayDate }
    }

    const handleStartDateDropdown = (elem) => {
        const elClicked = elem.currentTarget
        setStartAnchorElem(elClicked)
    }

    const handleEndDateDropdown = (elem) => {
        const elClicked = elem.currentTarget
        setEndAnchorElem(elClicked)
    }

    const handleStartDate = ({ target }) => {
        if (!target.dataset['timestamp']) { return }
        const selDate = target.dataset['timestamp']
        const date = new Date(parseInt(selDate))
        const reformDate = date.toLocaleDateString('en-US', dateOptions)

        setStartDate(reformDate)
        setStartAnchorElem(false)
    }
    const handleEndDate = ({ target }) => {
        if (!target.dataset['timestamp']) { return }
        const selDate = target.dataset['timestamp']
        const date = new Date(parseInt(selDate))
        const reformDate = date.toLocaleDateString('en-US', dateOptions)

        setEndDate(reformDate)
        setEndAnchorElem(false)
    }

    const hamburger = ({ target }) => {
        setHamburgerOpen(!hamburgerOpen)
        setHamburgerAnchor(target)
    }

    const hamburgerChoice = (selection) => {
        let todayDate = new Date()

        switch (selection) {
            case 'Last 7 days':
                const lastWeekDate = new Date(todayDate);
                lastWeekDate.setDate(todayDate.getDate() - 7);
                const dateOneSeven = lastWeekDate.toLocaleDateString('en-US', dateOptions)

                setStartDate(dateOneSeven)
                break;

            case 'Last 14 days':
                const lastfourteen = new Date(todayDate);
                lastfourteen.setDate(todayDate.getDate() - 14);
                const dateOneFourteen = lastfourteen.toLocaleDateString('en-US', dateOptions)

                setStartDate(dateOneFourteen)
                break;

            case 'Last 30 days':
                const lastThirty = new Date(todayDate);
                lastThirty.setDate(todayDate.getDate() - 30);
                const dateOneThirty = lastThirty.toLocaleDateString('en-US', dateOptions)

                setStartDate(dateOneThirty)
                break;

            case 'Last 12 Months':
                const lastTwelve = new Date(todayDate);
                lastTwelve.setDate(todayDate.getDate() - 365);
                const dateOneTwelve = lastTwelve.toLocaleDateString('en-US', dateOptions)

                setStartDate(dateOneTwelve)
                break;
        }
    }

    const handleSubmit = (start, end) => {
        if (startDate === null && endDate === null) {
            setHoverAnchorEl(false)
            dates(start, end)
        } else {
            setHoverAnchorEl(false)
            dates(startDate, endDate)
        }

    }

    return (
        <>
            <div className={`row ${style ? 'dsh-dt' : 'date-filter'}`} style={{ alignItems: 'center' }} >

                <div className={'col-xl-1 col-lg-1 col-md-1'} id={`${style ? 'dt-rng' : 'mnu-dt'}`}>
                    <button className='blank-bttn'>
                        <MenuIcon onClick={(e) => hamburger(e)} />
                    </button>
                    {hamburgerOpen && <ConfiguredDates anchor={hamburgerAnchor} close={(val) => setHamburgerOpen(!hamburger)} hamburgerChoice={hamburgerChoice} />}
                </div>

                <div className={`${style ? 'col-xl-2 col-lg-2 col-md-4' : 'col-xl-2 col-lg-4 col-md-4'}`} /*style={{ paddingLeft: "25px" }}*/ >

                    <TextField className="inv-hd" placeholder="Start Date" size='small'
                        value={startDate ? startDate : ""}
                        sx={{
                            '.MuiInputLabel-root, .MuiInput-root': { fontSize: "small" }
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <button className='blank-bttn' onClick={handleStartDateDropdown}>
                                        <CalendarMonthIcon sx={{ '&.MuiSvgIcon-root': { color: "#1fb6ff" } }} />
                                    </button>
                                </InputAdornment>
                            ),
                        }} />

                </div>
                <div className={`${style ? 'col-xl-2 col-lg-2 col-md-4' : 'col-xl-2 col-lg-4 col-md-4'}`}  >
                    <TextField className="inv-hd" placeholder="End Date" size='small'
                        value={endDate ? endDate : ""}

                        sx={{
                            '.MuiInputLabel-root, .MuiInput-root': { fontSize: "small" }
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <button className='blank-bttn' onClick={handleEndDateDropdown}>
                                        <CalendarMonthIcon sx={{ '&.MuiSvgIcon-root': { color: "#1fb6ff" } }} />
                                    </button>
                                </InputAdornment>
                            ),
                        }} />
                </div>
                <div className='col-xl-1 col-lg-1 col-md-1' id="df-sub">
                    <button type='submit'
                        style={{ background: "#1fb6ff", border: "none", color: "white", borderRadius: "5px", width: "35px" }}
                        onClick={handleSubmit}>
                        {!showLoading ? <ArrowForwardIcon
                            onMouseEnter={(event) => {
                                setHoverAnchorEl(event.currentTarget)
                            }}
                            onMouseLeave={() => setHoverAnchorEl(false)} /> :
                            <CircularProgress size={15} style={{ color: 'white' }} />
                        }
                    </button>
                </div>
                {/* -------------------------- -------------------------- -------------------------- -------------------------- */}

            </div >
            <div>
                <Menu open={openStartCal} onClose={() => setStartAnchorElem(false)} anchorEl={startAnchorElem}
                    sx={{
                        ".MuiPaper-root": { borderRadius: "15px", height: "200px", width: "200px" },
                        ".MuiDateCalendar-root": { height: "100%" },
                        ".MuiList-root": { height: "200px" },
                        ".MuiButtonBase-root": { height: "210px" },
                        ".MuiPickersYear-root": { height: "20px" },
                        ".MuiTouchRipple-root": { height: "30px" },
                        ".MuiPickersCalendarHeader-root": { padding: 0, fontSize: "small", margin: '0' },
                        ".MuiPickersCalendarHeader-labelContainer": { height: "30px" },
                        ".MuiPickersArrowSwitcher-button": { padding: 0, fontSize: "medium", height: '30px' },
                        ".MuiPickersCalendarHeader-switchViewButton": { padding: 0, fontSize: "small", height: '30px' },
                        ".MuiPickersCalendarHeader-labelContainer": { padding: 0, fontSize: "small" },
                        ".MuiPickersDay-root": { height: "20px" },
                        ".MuiPickersYear-yearButton": { height: '20px', padding: 0, fontSize: "small" },
                        ".Mui-selected": { height: '20px', padding: 0, fontSize: "small" },
                        ".MuiYearCalendar-root": { width: '200px', padding: 0, fontSize: "small" }
                    }}>
                    <MenuItem>

                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateCalendar value={dayjs(startDate)}
                                onClick={handleStartDate}
                            />
                        </LocalizationProvider>

                    </MenuItem>
                </Menu>
            </div>
            <div>
                <Menu open={openEndCal} onClose={() => setEndAnchorElem(false)} anchorEl={endAnchorElem}
                    sx={{
                        ".MuiPaper-root": { borderRadius: "15px", height: "200px", width: "200px" },
                        ".MuiDateCalendar-root": { height: "100%" },
                        ".MuiList-root": { height: "200px" },
                        ".MuiButtonBase-root": { height: "210px" },
                        ".MuiPickersYear-root": { height: "20px" },
                        ".MuiTouchRipple-root": { height: "30px" },
                        ".MuiPickersCalendarHeader-root": { padding: 0, fontSize: "small", margin: '0' },
                        ".MuiPickersCalendarHeader-labelContainer": { height: "30px" },
                        ".MuiPickersArrowSwitcher-button": { padding: 0, fontSize: "medium", height: '30px' },
                        ".MuiPickersCalendarHeader-switchViewButton": { padding: 0, fontSize: "small", height: '30px' },
                        ".MuiPickersCalendarHeader-labelContainer": { padding: 0, fontSize: "small" },
                        ".MuiPickersDay-root": { height: "20px" },
                        ".MuiPickersYear-yearButton": { height: '20px', padding: 0, fontSize: "small" },
                        ".Mui-selected": { height: '20px', padding: 0, fontSize: "small" },
                        ".MuiYearCalendar-root": { width: '200px', padding: 0, fontSize: "small" }
                    }}>
                    <MenuItem>

                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateCalendar value={dayjs(endDate)}
                                onClick={handleEndDate}
                            />
                        </LocalizationProvider>

                    </MenuItem>
                </Menu>
            </div>


            {/* -------------------------- -------------------------- -------------------------- -------------------------- -------------------------- --------------------------*/}

            <div>
                <Popover
                    slotProps={{
                        paper: {
                            style: {
                                backgroundColor: 'white',
                                border: 'solid #1fb6ff',
                                borderWidth: '0.2px',
                                color: '#1fb6ff',
                                fontSize: 'small'
                            }
                        }
                    }}
                    sx={{
                        pointerEvents: 'none',
                        height: "60px",
                    }}
                    open={openHoverCal}
                    anchorEl={hoverAnchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    onClose={() => setHoverAnchorEl(false)}
                    disableRestoreFocus
                >
                    <p style={{ padding: "0 10px", marginTop: '5px' }}>Submit</p>
                </Popover>

                {/* </div > */}
            </div >
        </>

    )
}   