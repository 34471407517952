import React, { useEffect, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import useFetch from "../../../services/useFetch.js"
import CircularProgress from '@mui/material/CircularProgress';
import { handleQuickBooksAuth } from '../../Account/AccountPages/QuickBooksOAuth.js';
import QuickbooksItems from './QuickbooksItems.jsx';
import QuickbooksVendors from './QuickbooksVendors.jsx';
import { useLocation } from 'react-router-dom';
import ConfirmLocations from './ConfirmLocations.jsx';
import { Dialog } from "@mui/material";
import SelectLocations from './SelectLocations.jsx';
import SnackbarEx from '../../Helpers/SnackbarEx.jsx';

export default function QuickbooksSetupPage({ reload }) {
    const [openTestSnack, setOpenTestSnack] = useState({
        color: "",
        message: "",
        open: false
    })
    const qbAuthLocation = useLocation()
    const [value, setValue] = useState(0);
    const [openConfirmClass, setOpenConfirmClass] = useState(false)
    const [openSelectClass, setOpenSelectClass] = useState(false)
    const { authKey } = qbAuthLocation.state || {};
    useEffect(() => {
        authKey && setOpenConfirmClass(true)
    }, [])

    // Rest of your component code...

    const { data: qboExists, load, error } = useFetch("api/check-quickbooks-exists", reload)
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleConfirmClass = async (val) => {
        if (val) {
            await getQBClasses()
        }
        setOpenConfirmClass(false)
    }
    const getQBClasses = async () => {
        setOpenConfirmClass(false)
        setOpenSelectClass(true)
    }

    const handleClassIdSelectClose = () => {
        setOpenSelectClass(false)
        setOpenTestSnack({ color: "success", message: "Quickbooks Class added successfully!", open: true })
    }

    const tabPage = (index) => {
        switch (index) {
            case 0:
                return (<QuickbooksItems />);
            case 1:
                return (<QuickbooksVendors />);
        }
    }
    if (load) {
        <div>
            <div className="sticky-header">
                <div className='row pg-hdr' style={{ width: "100%" }}>
                    <div className="col-xl-9 col-lg-8 col-md-8" >
                        <h2>Quickbooks Setup</h2>
                        <p style={{ marginBottom: '0' }}>Integrate and merge items from QBO</p>
                    </div>
                </div>
                <hr style={{ margin: '0', marginBottom: '0', width: "100%" }} />
            </div>
            <CircularProgress />
        </div>
    }

    return (
        <div>
            <div className="sticky-header">
                <div className='row pg-hdr' style={{ width: "100%" }}>
                    <div className="col-xl-9 col-lg-8 col-md-8" >
                        <h2>Quickbooks Setup</h2>
                        <p style={{ marginBottom: '0' }}>Integrate and merge items from QBO</p>
                    </div>
                </div>
                <hr style={{ margin: '0', marginBottom: '0', width: "100%" }} />
            </div>

            {!qboExists ?
                <div>
                    <div style={{ position: 'relative', left: '40%', marginTop: '10%' }}>
                        <button
                            onClick={() => handleQuickBooksAuth()}
                            style={{ width: '205px', background: '#2baeed' }}
                            className='submit'>Connect to Quickbooks</button>
                    </div>
                </div>
                :
                <>
                    <Tabs value={value} onChange={handleChange} sx={{ marginLeft: '18px' }}>
                        <Tab label="Inventory Items" sx={{ textTransform: 'none' }} />
                        <Tab label="Vendors" sx={{ textTransform: 'none' }} />
                    </Tabs>
                    <hr style={{ margin: '0', marginBottom: '5px' }} />
                    {tabPage(value)}
                </>
            }
            <Dialog maxWidth={'sm'} fullWidth PaperProps={{
                style: {
                    width: '350px'
                },
            }} open={openConfirmClass} onClose={() => setOpenConfirmClass(false)}>
                <ConfirmLocations close={(val) => handleConfirmClass(val)} />
            </Dialog>

            <Dialog maxWidth={'sm'} fullWidth PaperProps={{
                style: {
                    width: '500px',
                    height: '40vh'
                },
            }} open={openSelectClass} onClose={() => setOpenSelectClass(false)}>
                <SelectLocations close={handleClassIdSelectClose} />
            </Dialog>
            {
                openTestSnack.open && <SnackbarEx color={openTestSnack.color} message={openTestSnack.message} open={openTestSnack.open}
                    close={() => setOpenTestSnack({
                        color: "",
                        message: "",
                        open: false
                    })} />
            }
        </div>
    );
};
