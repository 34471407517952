import React, { useState, useEffect, useContext } from 'react';
import useFetchWithParams from "../../../services/useFetchWithParams.js"
import useFetch from "../../../services/useFetch.js"
import { LocationContext } from '../../../context/LocationContext.js';
import CloseIcon from '@mui/icons-material/Close';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import DoubleArrowRoundedIcon from '@mui/icons-material/DoubleArrowRounded';
import PostData from '../../../services/PostData.js';
import { CircularProgress } from '@mui/material';
import SnackbarEx from '../../Helpers/SnackbarEx.jsx';

export default function MergeIngredient({ close, selItems, mergeType }) {
    const { selectedLocation } = useContext(LocationContext)
    const bbLoc = selectedLocation
    const [load, setLoad] = useState(true)
    const [postLoad, setPostLoad] = useState(false)
    const [openSnackbar, setOpenSnackbar] = useState({
        color: "",
        message: "",
        open: false
    })
    const [filteredItems, setFilteredItems] = useState([])
    // mergetype is either vendor or ingredient
    const { data: getItems, error } = useFetchWithParams(`api/copy-recipe-menu`, { "arrayItem": selItems, "type": mergeType, "pullFromLoc": bbLoc })

    const { data: getCopyItems, error: menuErr } = useFetch(`api/${mergeType}s`, false)
    const [recipes, setRecipes] = useState([])
    useEffect(() => {
        let nameArr;
        switch (mergeType) {
            case "ingredient":
                nameArr = getItems?.filter(item => selItems?.includes(item.itemId));
                getItems?.map(ing => setRecipes(cur => [...cur, {
                    copyFromName: ing.itemName,
                    copyFromId: ing.itemId,
                    copyToName: [],
                    copyToId: null,
                }]))
                break;
            case "vendor":
                nameArr = getItems?.filter(item => selItems?.includes(item.itemId));
                getItems?.map(ing => setRecipes(cur => [...cur, {
                    copyFromName: ing.vendorName,
                    copyFromId: ing.itemId,
                    copyToName: null,
                    copyToId: null,
                }]))
                break;
        }
    }, [getItems])
    useEffect(() => {
        let filtItemArr;
        // exclude get items for         
        filtItemArr = getCopyItems?.filter(item => !selItems.includes(item.ingredientId))
        setFilteredItems(filtItemArr)
    }, [getCopyItems])
    const handleToChange = (index, val, reason) => {
        if (reason !== 'clear') {
            const newRecipe = [...recipes]
            if (mergeType === "ingredient") {
                newRecipe[index]["copyToName"] = val.ingredient
                newRecipe[index]["copyToId"] = val.ingredientId
            } else {
                newRecipe[index]["copyToName"] = val.vendor
                newRecipe[index]["copyToId"] = val.vendorId
            }
            setRecipes(newRecipe)
        }
    }

    const handleSubmit = async () => {
        const valid = validateForm()
        if (valid) {
            setPostLoad(true)
            const { success, error } = await PostData(recipes, `api/quickbooks-item-merge/${mergeType}`, false)
            if (success) {
                close("success")
                setPostLoad(false)
            }
        } else {
            setOpenSnackbar({ color: "error", message: "Please fill out all fields.", open: true })
        }
    }

    const validateForm = () => {
        const allBodyFilled = recipes.every(value => value.copyToName && value.copyToName.length > 0);
        if (allBodyFilled) {
            return true
        }
        else { return false };
    };


    return (
        <div className='container-fluid d-flex flex-column' style={{ height: '60vh' }}>
            <div className='row inv-hdr' style={{ alignItems: 'baseline', marginBottom: '18px' }}>
                <div className='col-10'>
                    <h4 style={{ margin: "20px 0 20px 4%" }}>Merge Items: </h4>
                </div>
                <div className='col-2' style={{ position: 'relative', left: '10%' }}>
                    <button type='button' className='blank-bttn' onClick={() => close(false)}>
                        <CloseIcon sx={{ cursor: 'pointer' }} />
                    </button>
                </div>

            </div>
            <div className='row' style={{ height: '77%', overflowY: 'scroll', overflowX: 'hidden' }}>
                <div className='row' style={{ flexDirection: "column", paddingTop: '5px' }}>
                    <div className='col-12'>
                        {recipes?.map((elem, ind) =>
                            <div className='row' style={{ marginBottom: "20px", alignItems: 'center', justifyContent: "center", height: "max-content" }}>
                                <div className='col-5'>
                                    <TextField
                                        disabled
                                        size='small'
                                        value={recipes[ind]["copyFromName"] || ""}
                                        sx={{
                                            width: "100%",
                                            "& .MuiInputBase-input.Mui-disabled": {
                                                WebkitTextFillColor: "#000000"
                                            },
                                            "& .MuiFormHelperText-root.Mui-disabled": {
                                                WebkitTextFillColor: "#000000"
                                            }
                                        }} />
                                </div>
                                <div className='col-1'>
                                    <DoubleArrowRoundedIcon />
                                </div>
                                <div className='col-5'>
                                    <Autocomplete
                                        // options={getCopyItems}
                                        options={filteredItems || []}
                                        size='small'
                                        onChange={(e, val, reason) => handleToChange(ind, val, reason)}
                                        getOptionLabel={(option) => (mergeType === "ingredient" ? option.ingredient : option.vendor)}
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    borderColor: 'black', // Change outline color
                                                },

                                                '&:hover fieldset': {
                                                    borderColor: '#1fb6ff', // Change outline color on hover
                                                },
                                            },
                                        }}
                                        renderInput={(params) => <TextField {...params}
                                            name='copyFrom' value={elem} label="Copy To"
                                        />}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <div className='row' style={{ height: "75px", justifyContent: 'end', position: "fixed", background: 'white', bottom: '0', width: '100%' }}>
                <div className='col-12'>
                    <button className='submit' type='submit' style={{ width: "15%", marginLeft: '5%' }} onClick={handleSubmit}  >
                        {!postLoad ? "Submit" : <CircularProgress size={20} sx={{ color: 'white' }} />}
                    </button>
                </div>

            </div>

            {openSnackbar.open &&
                <SnackbarEx color={openSnackbar.color} message={openSnackbar.message} open={openSnackbar.open}
                    close={() => setOpenSnackbar({
                        color: "",
                        message: "",
                        open: false
                    })} />}
        </div>
    );
};
