import React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

export default function AccountDrop({ target, close, role }) {

    const open = Boolean(target);

    const handleClose = (selection) => {
        close(selection)
    };

    return (
        <div>
            <Menu
                id="basic-menu"
                anchorEl={target}
                open={open}
                onClose={handleClose}
                sx={{ fontSize: 'small' }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {role !== "AnalyticsOnly" ?
                    <div>
                        <MenuItem onClick={() => handleClose('settings')}>Settings</MenuItem>
                        <MenuItem onClick={() => handleClose('logout')}>Logout</MenuItem>
                    </div>
                    :
                    <MenuItem onClick={() => handleClose('logout')}>Logout</MenuItem>
                }
            </Menu>
        </div>
    );
};
