import React, { useState, useEffect } from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import PostData from '../../../../services/PostData.js'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

export default function ShopifyAuth() {

    const location = useLocation();
    const [codeParam, setCodeParam] = useState(null)
    const [shopParam, setShopParam] = useState(null)
    const [hmacParam, setHmacParam] = useState(null)
    const [nav, setNav] = useState(false)
    const [load, setLoad] = useState(false)

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const code = params.get('code')
        const shop = params.get('shop')
        const hmac = params.get('hmac')

        if (code && shop && hmac) {
            setCodeParam(code)
            setShopParam(shop)
            setHmacParam(hmac)
        }
    }, [location.search])

    useEffect(() => {
        if (codeParam && shopParam && hmacParam) {
            postCode();
        }
    }, [codeParam, shopParam, hmacParam]);

    const postCode = async () => {
        setLoad(true)

        const { success, error } = await PostData({ code: codeParam, shop: shopParam, vendor: "Shopify" }, 'api/shopify-oauth', false)
        if (success) {
            setNav(true)
        }
    }


    return (
        <>
            <Backdrop
                sx={{ flexDirection: 'column', color: 'rgb(31, 182, 255)', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={load}
                onClick={() => setLoad(false)}
            // onClick={handleClose}
            >
                <CircularProgress color="inherit" />
                <p style={{ marginTop: "8px", color: "#fff" }}>Connecting...</p>
            </Backdrop>

            <div style={{ position: 'relative', top: '50%', left: '40%' }}>
                <p style={{ color: 'lightgray' }}>Processing...</p>
            </div>
            {nav && (
                <Navigate to="/menu" replace={true} />
            )}
        </>
    );
};
