import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import useFetch from "../../services/useFetch.js"
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import SnackbarEx from '../Helpers/SnackbarEx.jsx';
import PostFulfilledInvoice from '../../services/PostFulfilledInvoice.js';

export default function FulfillInvoice({ invoiceInfo, close, newIngredients }) {

    const [submitting, setSubmitting] = useState(false)
    const [errorField, setErrorField] = useState({
        "vendorNm": invoiceInfo.vendor ?? false,
        "invoiceNum": false
    })
    const [vendorInfo, setVendorInfo] = useState({
        "vendorNm": invoiceInfo.vendor,
        "vendorId": invoiceInfo.vendorId,
        "orderNumber": invoiceInfo.orderNumber,
        "invoiceNum": null
    })

    useEffect(() => {
        if (invoiceInfo.vendor === "Odeko" || invoiceInfo.vendor === "CBS Distributing, Inc.") {
            setErrorField(prevVals => ({ ...prevVals, ["invoiceNum"]: false }))
            setVendorInfo(prevVal => ({ ...prevVal, ["invoiceNum"]: invoiceInfo.orderNumber }))
        }
    })

    const [openTestSnack, setOpenTestSnack] = useState({
        color: "",
        message: "",
        open: false
    })

    const [vendorOpts, setVendorOpts] = useState([])
    const { data: vendorOptions, load, error } = useFetch("api/vendors", false)

    useEffect(() => {
        if (vendorOptions) {
            vendorOptions.map(vend => {
                setVendorOpts(prevVal => [...prevVal, vend.vendor])
            }
            )
        }
    }, [vendorOptions])

    const handleClose = () => {
        close("exitIcon")
    }

    const invoiceNumberChange = ({ target }) => {
        setErrorField(prevVals => ({ ...prevVals, ["invoiceNum"]: false }))
        setVendorInfo(prevVal => ({ ...prevVal, ["invoiceNum"]: target.value }))
    }

    function checkValid() {
        let err = false
        if (!vendorInfo["invoiceNum"]) {
            setErrorField(prevVals => ({ ...prevVals, ["invoiceNum"]: true }))
            err = true
        } else if (!vendorInfo["vendorNm"]) {
            setErrorField(prevVals => ({ ...prevVals, ["vendorNm"]: true }))
            err = true
        }
        return err
    }

    const handleSubmit = async () => {
        setSubmitting(true)
        const checkErr = checkValid()
        if (!checkErr) {
            const { success: invFulfilled, error, retIngredients } = await PostFulfilledInvoice(vendorInfo, "api/fulfill-invoice/non-upload")
            if (invFulfilled && retIngredients["newIngs"].length > 0) {

                setSubmitting(false)
                newIngredients(retIngredients, retIngredients.invoiceNum)
                close("success")
            } else if (invFulfilled) {
                setSubmitting(false)
                close("success")
            } else {
                setSubmitting(false)
                setOpenTestSnack({ color: "error", message: error.response.data, open: true })
            }
        } else {
            setSubmitting(false)
            return
        }
        // Post this invoice to the backend and await response
        // If successful --> close and display success message
        // If error --> stop load and display error
    }
    if (load) {
        return (<div className="container"><CircularProgress /></div>)
    } else if (submitting) {
        return (
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", position: "relative", left: "27%", height: "42vh", width: "max-content" }}>
                <CircularProgress />
                <p style={{ marginTop: "8px", color: "black", width: "max-content" }}>Uploading invoice and updating stock...</p>
            </div>
        )
    }
    return (
        <div style={{ height: "42vh", overflowY: 'scroll', overflowX: 'hidden' }}>
            <div className='row' style={{ width: '100%', top: "5%", left: "5%", position: "relative" }}>
                <div className='col-9'>
                    <h4 style={{ marginBottom: "10px" }}>Fulfill Invoice: </h4>
                </div>
                <div className='col-3' style={{ position: 'relative', left: "50px", marginBottom: "10px" }}>
                    <button type="button" className='blank-bttn'>
                        <CloseIcon onClick={handleClose} />
                    </button>
                </div>

            </div>
            <hr style={{ marginBottom: "35px" }} />

            <div className='row' style={{ top: "1%", left: "5%", position: "relative" }}>
                <div className='col-5'>
                    <Autocomplete
                        size='small'
                        options={vendorOpts ? vendorOpts : []}
                        value={vendorInfo.vendorNm || ""}
                        sx={{ width: '300px' }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={"Vendor"}
                                placeholder='Vendor Name'
                            />
                        )} />
                </div>
            </div>
            <div className='row' style={{ top: "7%", left: "5%", position: "relative" }}>

                <div className='col-5'>
                    <TextField
                        size='small'
                        value={vendorInfo.invoiceNum}
                        label={"Invoice Number"}
                        error={errorField.invoiceNum}
                        helperText={errorField.invoiceNum ? 'Invoice number required' : ''}
                        sx={{ '&.MuiTextField-root': { width: '300px' } }}
                        onChange={(e) => invoiceNumberChange(e)}
                    />
                </div>
            </div>

            <div className='row' style={{ justifyContent: 'flex-start', position: "relative", top: errorField.invoiceNum ? "23%" : "30%", marginLeft: '3%' }}>
                <button className='submit' type='submit' style={{ width: "51%" }} onClick={handleSubmit} >
                    Submit
                </button>
            </div>
            {
                openTestSnack.open && <SnackbarEx color={openTestSnack.color} message={openTestSnack.message} open={openTestSnack.open}
                    close={() => setOpenTestSnack({
                        color: "",
                        message: "",
                        open: false
                    })} />
            }
        </div >
    );
};
