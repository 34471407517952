import React from 'react'
import { NavLink, useLocation } from 'react-router-dom';
import AssessmentIcon from '@mui/icons-material/Assessment';

export default function LookerSidebar() {

    return (
        <div className="sidebar">
            <div style={{ position: "relative", top: "2%", left: "10px", width: '220px' }}>

                < div className='row' style={{ width: '220px' }}>
                    <NavLink to="/looker-analytics" >
                        <div className='row' style={{ alignItems: "center" }}>
                            <div className='col-1'>
                            </div>
                            <div className='col-10' style={{ paddingLeft: 0 }}>
                                <div className='row' style={{ alignItems: "center" }}>
                                    <div className='col-2'>
                                        <AssessmentIcon fontSize='small' />
                                    </div>
                                    <div className='col-7 sidebar-name'>
                                        Dashboard
                                    </div>
                                </div>
                            </div>
                        </div>
                    </NavLink>

                </div>
            </div >
        </div>

    )
}