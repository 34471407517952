import React, { createContext, useState, useEffect } from 'react';

const SidebarContext = createContext();

const SidebarProvider = ({ children }) => {
    // 1. State to manage sidebar open/close state
    const [isOpen, setIsOpen] = useState(() => {
        // Initialize state from sessionStorage if exists, otherwise default to false
        const storedIsOpen = sessionStorage.getItem('usrsdbropn');
        return storedIsOpen ? JSON.parse(storedIsOpen) : false;
    });

    // 2. useEffect to sync isOpen state with sessionStorage
    useEffect(() => {
        sessionStorage.setItem('usrsdbropn', JSON.stringify(isOpen));
    }, [isOpen]);

    // 3. Function to toggle sidebar state
    const toggleSidebar = () => {
        setIsOpen(prev => !prev);
    };

    return (
        <SidebarContext.Provider value={{ isOpen, toggleSidebar }}>
            {children}
        </SidebarContext.Provider>
    );
};

export { SidebarProvider, SidebarContext };
