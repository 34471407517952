import axios from "axios"

axios.defaults.withCredentials = true
const baseUrl = process.env.REACT_APP_NETLIFY_API_URL || process.env.REACT_APP_API_URL

export default async function PostPDF(data, url, vendor) {
    // const tkn = localStorage.getItem("urtkn")
    const tkn = sessionStorage.getItem("urtkn")
    const location = localStorage.getItem('usrlctn')
    try {

        const response = await axios.post(`${baseUrl}/${url}`, data,
            {
                "content-type": "multipart/form-data",
                headers: { Authorization: `Bearer ${tkn}` },
                params: { location: location, vendor: vendor }
            })

        if (response.status === 200) {

            return { success: true, error: false, load: false, unknowns: response.data }
        } else { return { success: false, error: true, load: false } }

    } catch (err) {
        return { success: false, error: err, load: false }
    }
}