import React, { useState, useEffect, useContext } from 'react';
// import { locationContext } from '../../context/LocationContext';
import useFetch from '../../services/useFetch.js'
import { DataGrid } from "@mui/x-data-grid"
import { currencyFormatter } from '../Helpers/CurrencyFormat.js';
import CloseIcon from '@mui/icons-material/Close';

export default function ModifierDrawer({ modifierData, close }) {
    // const userLocs = useContext(locationContext)

    const [row, setRow] = useState([])
    const [col, setCol] = useState([])

    useEffect(() => {
        if (modifierData === null) {
            return
        } else {
            setData()
        }

    }, [])

    const setData = () => {
        setRow(modifierData.map((e, ind) =>
        ({
            id: ind, modName: e.modifier_name,
            quantity: e.quantity_sold,
            amtSld: e.total_modifier_sales_amount
        })
        ))



        setCol([

            { field: 'modName', headerName: 'Product', flex: 1 },
            { field: 'quantity', headerName: 'Quantity', flex: 1 },
            {
                field: 'amtSld', headerName: 'Total Modifier Revenue', valueFormatter: ({ value }) => {
                    return value !== null ? currencyFormatter(value, 2) : 'None';
                }, flex: 1
            },

        ])
    }

    const closeDrawer = () => {
        close()
    }


    return (
        <div style={{ width: '80%', position: "relative", left: "10%", }}>
            <div className='row'>
                <div className='col-10' style={{ position: "relative", left: "2%", top: "20px" }}  >
                    <p style={{ fontWeight: '600' }}>Modifiers sold: </p>
                </div>
                <div className='col-2' style={{ position: "relative", left: "10%", top: "20px" }}>
                    <CloseIcon sx={{ cursor: 'pointer' }} onClick={closeDrawer} />
                </div>

            </div>
            <div className="row " >
                <div className="col-12" style={{ padding: '25px' }} >
                    <DataGrid
                        rows={row}
                        columns={col}
                        rowHeight={35}
                        // slots={{
                        //     // toolbar: GridToolbar,
                        //     toolbar: CustomToolbar
                        // }}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 10,
                                },
                            },
                        }}
                        sx={{
                            padding: "10px 0 0 25px", border: "solid black", borderWidth: "0.5px", overflowY: "scroll",
                            '.MuiToolbar-root': { alignItems: "baseline" },
                            '.MuiSvgIcon-root': { margin: 0 },
                            '.MuiFormControl-root': { margin: '0 10px 5px 0' },
                            '.MuiDataGrid-main': { marginRight: "20px" },
                            '.MuiDataGrid-columnHeaderTitle': { fontSize: '14px', fontWeight: '600', '@media (max-width:1080px)': { fontSize: '11px' } },
                            '.MuiDataGrid-toolbarContainer': { justifyContent: 'flex-end', marginRight: "10px" },
                            '.MuiDataGrid-cellContent': { fontSize: "small" }
                        }}
                    />
                </div>

            </div>
        </div>
    );
};
