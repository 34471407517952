import React from 'react';
import Snackbar from "@mui/material/Snackbar"
import Alert from '@mui/material/Alert';

export default function SnackbarEx({ color, message, open, close }) {

    return (
        <div>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={open}
                autoHideDuration={4000}
                onClose={close}>
                <Alert onClose={close} severity={color}>{message}</Alert>
            </Snackbar>
        </div>
    );
};
