import axios from "axios"

axios.defaults.withCredentials = true
const baseUrl = process.env.REACT_APP_NETLIFY_API_URL || process.env.REACT_APP_API_URL

export default async function DeleteData(url, data) {

    // const tkn = localStorage.getItem("urtkn")
    const tkn = sessionStorage.getItem("urtkn")
    const location = localStorage.getItem('usrlctn')

    try {

        const response = await axios.delete(`${baseUrl}/${url}`, {
            data,
            headers: { Authorization: `Bearer ${tkn}` },
            params: { location: location },

        });

        if (response.status === 200) {
            return { success: true, error: false, load: false }

        } else { return { success: false, error: true, load: false } }

    } catch (err) {
        return { success: false, error: err, load: false }
    }

}