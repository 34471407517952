
export function recipeMeasurementConversions(recipeUoM, itemUom, size) {

    return new Promise((resolve, reject) => {
        const conversionFactors = {
            "tsp_tbsp": 3,
            "tbsp_tbs": 0.333,
            "grams_lbs": 453.6,
            "oz_lbs": 16,
            "floz_tbsp": 0.5,
            "tbsp_floz": 2,
            "oz_gallons": 128,
            "floz_gallons": 128,
            "floz_cup": 8,
            "oz_grams": 28.35,
            "floz_quarts": 32,
            "floz_pint": 16,
            "mL_liter": 1000,
            "liter_mL": .001,
            "gallons_quarts": 4,
            "quarts_gallons": 0.25,
            "quarts_floz": .03125,
            "quarts_cup": 3.943,
            "cup_quarts": .2536,
            "cup_gallons": .0625,
            "gallons_oz": 0.0078125,
            "grams_oz": 0.035274,
            "pint_floz": 0.0625,
            "units_units": 1,
            "oz_oz": 1,
            "floz_floz": 1,
            "quarts_quarts": 1,
            "grams_grams": 1,
            "gallons_gallons": 1,
            "cup_lbs": 1.92,
            "cup_gallons": 16,
            "none_none": 1
        };

        const key = recipeUoM + "_" + itemUom;

        if (conversionFactors.hasOwnProperty(key)) {
            const unitQuantity = parseFloat(size * conversionFactors[key]);
            resolve(unitQuantity);
        } else {
            reject(null);
        }
    })

}

