import React, { useEffect } from 'react';
import useFetch from "../../services/useFetch.js"
import CircularProgress from '@mui/material/CircularProgress';

export default function HighestMenuItem({ reload }) {
    const apiUrl = `api/menu-margin/highest`

    const { data, error, load } = useFetch(apiUrl, reload)
    useEffect(() => {
        if (data === null) {
            return
        }
    }, [data])


    if (load) {
        return (<div className="container"><CircularProgress /></div>)
    }
    return (
        <>
            <div className='row' style={{ margin: "15px 0 0 0" }}>
                <p className='dsh-hr-ttl'>Highest Margin Menu Item</p>
            </div>

            <div className='row' style={{ margin: "0 20px 10px 0" }}>
                <div className='col-8'>
                    <p style={{ textOverflow: "ellipsis", overflow: 'hidden', whiteSpace: 'nowrap' }}>{data.posMenuItem}:</p>
                </div>
                <div className='col-2'>
                    <p style={{ color: '#1fb6ff' }}>{data.profitMargin && data.profitMargin.toFixed(2)}%</p>
                </div>
            </div>
        </>
    );
};
