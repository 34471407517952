import React, { useState, useEffect } from 'react';

import DateFilter from '../components/Menu/DateFilter.jsx';
import CircularProgress from '@mui/material/CircularProgress';
import OrdersTable from '../components/OrdersAnalytics/OrdersTable.jsx';

export default function OrdersLog({ reload }) {

    const dateOptions = { month: '2-digit', day: '2-digit', year: 'numeric' }
    const [load, setLoad] = useState(true)
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)

    useEffect(() => {

        if (startDate === null && endDate === null) {
            initializeDates()
        }
        setLoad(false)

    }, [startDate, endDate])

    const initializeDates = () => {

        let todayDate = new Date()

        const lastWeekDate = new Date(todayDate);
        lastWeekDate.setDate(todayDate.getDate() - 1);
        todayDate = todayDate.toLocaleDateString('en-US', dateOptions)
        const dateOne = lastWeekDate.toLocaleDateString('en-US', dateOptions)

        // return { start: dateOne, end: todayDate }
        setStartDate(dateOne)
        setEndDate(todayDate)
        return
    }

    const dateClicked = async (start, end) => {
        setStartDate(start)
        setEndDate(end)
    }

    if (load) {
        return (<div className="container"><CircularProgress /></div>)
    }

    return (
        <div>
            {/* ---------------------------- Page Header ------------------------ */}
            <div className='sticky-header'>
                <div className='row pg-hdr' style={{ width: "100%" }}>
                    <div className="col-xl-1 col-lg-1 col-md-1" >
                        <h2 style={{ marginBottom: "0", width: 'max-content' }}>Orders Log</h2>
                    </div>
                    <div className='col-xl-11 col-lg-11 col-md-11'>
                        <DateFilter ingredient='null' dates={dateClicked} style="dashboard" defaultDays={1} />
                        {/* <UserLocation /> */}
                    </div>
                </div>
                <hr style={{ marginTop: '0', marginBottom: "15px" }} />
            </div>

            <div>
                <OrdersTable startDate={startDate} endDate={endDate} reload={reload} />
            </div>
        </div>
    );
};
