import React, { useState, useEffect } from 'react'
import useFetch from '../../../../services/useFetch.js';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';
import PostData from "../../../../services/PostData.js"
import EditAddIngredients from './EditAddIngredients.jsx';
import EditIngredientModifier from './EditIngredientModifier.jsx';

export default function EditMenuIngredients({ ingredientId, close, itemCategory }) {

    const [load, setLoad] = useState(true)
    const [submitNewItem, setSubmitNewItem] = useState(false)
    const [enableSubm, setEnableSubm] = useState(false)

    const { data, error } = useFetch(`api/menuItems/${ingredientId}`, false)

    const { data: itemIngredients, error: itemIngredientError } = useFetch(`api/menuItemsRecipe/${ingredientId}`, false)

    const [values, setValues] = useState({
        itemName: "",
        variant: "",
        itemId: ingredientId,
        category: "",
        ingredients: [{
            name: "",
            units: [{ amount: "", measurement: "" }]
        }],
        modifiers: [{
            modifierName: "",
            modifierId: "",
            modifierPrice: 0,
            ingredients: [{
                name: "",
                units: [{ amount: "", measurement: "" }]
            }]
        }]
    })

    useEffect(() => {

        if (data !== null && itemIngredients !== null) {

            setLoad(false)
            setValues({
                ...values,
                itemName: data[0].itemName,
                category: data[0].category,
                variant: data[0].variant
            })
        }


    }, [data, itemIngredients])


    const handleSubmit = async () => {

        const { success, load, error } = await PostData(values, "api/update-menu-ingredients", false)
        if (success !== 'success') {

            close(false, 'success')
        } else {
            close(false, 'error')
        }
        if (load) {
            return (<div className="container"><CircularProgress /></div>)
        }
    }

    const closePop = () => {
        close(false)
    }

    /* Grabs the ingredients from the helper function AddItemIngredients, and updates the ingredients
        Array accordingly */
    const updateIngredients = (value) => {

        setValues(prevValues => ({
            ...prevValues,
            ingredients: value
        }));
    }
    const updateModifiers = (value) => {

        setValues(prevValues => ({
            ...prevValues,
            modifiers: value
        }));
    }


    if (load) {
        return (<div className="container"><CircularProgress /></div>)
    }
    return (

        <div className='row' >
            <div className='row inv-hdr' style={{ alignItems: 'baseline' }}>
                <div className='col-10'>
                    <h4 style={{ margin: "20px 0 20px 5%" }}>Edit Item: </h4>
                </div>
                <div className='col-2' style={{ position: 'relative', left: '10%' }}>
                    <button type='button' className='blank-bttn' onClick={closePop}>
                        <CloseIcon sx={{ cursor: 'pointer' }} />
                    </button>
                </div>
                {/* <hr /> */}
            </div>
            <div className='row' style={{ margin: "10px 0 0 0" }}>
                <div className='col' style={{ paddingLeft: '35px' }}>

                    <TextField helperText={"Menu Item"} style={{ width: "100%" }}
                        disabled name='menuItem'
                        // value={values.itemName}
                        value={values.variant ? values.itemName + " - " + values.variant : values.itemName + " - " + 'Modifier'}
                        sx={{
                            "& .MuiInputBase-input.Mui-disabled": {
                                WebkitTextFillColor: "#000000"
                            },
                            "& .MuiFormHelperText-root.Mui-disabled": {
                                WebkitTextFillColor: "#000000"
                            }
                        }} />
                </div>
                <div className='col' style={{ paddingRight: '35px' }}>

                    <TextField helperText={"Menu Item Category"} style={{ width: "100%" }}
                        disabled name='category' value={values.category} sx={{
                            "& .MuiInputBase-input.Mui-disabled": {
                                WebkitTextFillColor: "#000000"
                            },
                            "& .MuiFormHelperText-root.Mui-disabled": {
                                WebkitTextFillColor: "#000000"
                            }
                        }} />
                </div>
                <hr style={{ marginBottom: '0', marginTop: "15px" }} />
            </div>

            <div className='row' style={{ margin: "20px 0 15px 20px" }}>
                <p style={{ fontSize: '20px' }}>{data[0].itemName} Ingredients:</p>
            </div>

            <EditAddIngredients submit={handleSubmit} ingrData={itemIngredients && itemIngredients} updateIngredients={updateIngredients} itemCategory={itemCategory} />

            {(data[0].itemId !== null && itemCategory !== "Modifier") &&
                <EditIngredientModifier submit={handleSubmit} ingrData={itemIngredients && itemIngredients} updateModifiers={updateModifiers} />
            }
            <hr style={{ marginBottom: '20px', marginTop: '10px' }} />
            <div className='row' style={{ width: "20%", marginBottom: '20px', position: 'relative', left: "75%" }}>
                <button type='submit' className={'submit'}

                    onClick={handleSubmit} >Submit</button>
            </div>


        </div >


    );
};
