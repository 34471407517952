import React, { useEffect, useState } from 'react'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function GraphRange({ rangeDates, view }) {

    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [selection, setSelection] = useState('six months')

    const dateOptions = { month: '2-digit', day: '2-digit', year: 'numeric' }

    useEffect(() => {
        if (startDate === null && endDate === null) {
            const { start, end } = initializeDates()
            rangeDates(selection, start, end)
            setStartDate(start)
            setEndDate(end)
        } else {
            rangeDates(selection, startDate, endDate)
        }

    }, [startDate])

    const initializeDates = () => {
        let todayDate = new Date()

        const lastSix = new Date(todayDate);
        lastSix.setDate(todayDate.getDate() - 182);
        todayDate = todayDate.toLocaleDateString('en-US', dateOptions)
        const dateOne = lastSix.toLocaleDateString('en-US', dateOptions)

        return { start: dateOne, end: todayDate }
    }


    const handleMenuChange = ({ target }) => {
        setSelection(target.value)
        const userSelect = target.value
        let todayDate = new Date()

        switch (userSelect) {
            case 'week':
                const lastWeekDate = new Date(todayDate);
                lastWeekDate.setDate(todayDate.getDate() - 7);
                const dateOneSeven = lastWeekDate.toLocaleDateString('en-US', dateOptions)

                setStartDate(dateOneSeven)
                break;


            case 'month':
                const lastThirty = new Date(todayDate);
                lastThirty.setDate(todayDate.getDate() - 30);
                const dateOneThirty = lastThirty.toLocaleDateString('en-US', dateOptions)

                setStartDate(dateOneThirty)
                break;

            case 'six months':
                const lastSix = new Date(todayDate);
                lastSix.setDate(todayDate.getDate() - 182);
                const dateSix = lastSix.toLocaleDateString('en-US', dateOptions)

                setStartDate(dateSix)
                break;

            case 'year':
                const lastTwelve = new Date(todayDate);
                lastTwelve.setDate(todayDate.getDate() - 365);
                const dateOneTwelve = lastTwelve.toLocaleDateString('en-US', dateOptions)

                setStartDate(dateOneTwelve)
                break;
        }
    };


    return (
        <>
            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <InputLabel id="demo-select-small-label">Range</InputLabel>
                <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={view}
                    label="Range"
                    onChange={handleMenuChange}
                >
                    <MenuItem value={'week'}>1 Week</MenuItem>
                    <MenuItem value={'month'}>30 Days</MenuItem>
                    <MenuItem value={'six months'}>6 Months</MenuItem>
                    <MenuItem value={'year'}>12 Months</MenuItem>
                </Select>
            </FormControl>
        </>
    );
};
