import React, { useState, useEffect } from 'react'
import useFetch from '../../../../services/useFetch.js'
import CircularProgress from '@mui/material/CircularProgress';
import { DataGrid } from "@mui/x-data-grid"

export default function DisplayIngredients({ ingredientId, category, refresh }) {

    const [row, setRow] = useState([])
    const [col, setCol] = useState([])
    const [load, setLoad] = useState(true)
    const [refreshData, setRefreshData] = useState(false)

    const { data, error } = useFetch(`api/menuItemsRecipe/${ingredientId}`, refreshData)

    useEffect(() => {
        if (data === null) {
            setLoad(true)
        } else {
            setLoad(false)
            setData()
        }

    }, [data])

    useEffect(() => {
        if (refresh) {
            setRefreshData(true)
            setTimeout(() => {
                // Code to be executed after the delay
                setRefreshData(false)
            }, 200);
        }
    }, [refresh])

    const setData = () => {
        setRow(data.ingredients?.map(e =>
        ({
            id: e.name, col1: e.name,
            col2: e.units[0].amount,
            col3: e.units[0].measurement
        })
        ))

        setCol([
            { field: 'col1', headerName: 'Item', flex: 2 },
            { field: 'col2', headerName: 'Amount', flex: 1, hide: true },
            { field: 'col3', headerName: 'Measurement', flex: 1 }
        ]);
    }

    if (load) return (<div style={{ position: 'relative', left: '45%', top: '15%' }}><CircularProgress size={30} /></div>)

    return (
        <>
            {/* Header row with the menu item name */}
            <div className='row' style={{
                position: 'sticky', top: "0", zIndex: '1',
                backgroundColor: 'white', padding: "15px 0 0 10px"
            }}>
                <div className='col-10'>
                    <h4 style={{ marginBottom: "15px", fontSize: 'medium' }}>Main Ingredients:</h4>
                </div>

            </div>
            <div className='row'>
                <hr style={{ margin: " 0 0 5px 0" }} />
            </div>

            {/* Table with each row being the ingredient in the menu item */}
            <div className='row' >

                <div className="col-12" style={{ height: "auto" }} >
                    <DataGrid
                        rows={row}
                        columns={col}
                        rowHeight={35}
                        pageSize={10}
                        pageSizeOptions={[10, 25]}
                        // getRowId={(row) => row}
                        // slots={{
                        //     // toolbar: GridToolbar,
                        //     toolbar: CustomToolbar
                        // }}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 10,
                                },
                            },
                        }}
                        sx={{
                            height: '38.5vh',
                            padding: "10px 0 0 25px", border: "none", overflowY: "scroll",
                            '.MuiToolbar-root': { alignItems: "baseline" },
                            '.MuiSvgIcon-root': { margin: 0 },
                            '&.MuiDataGrid-main': { marginRight: "20px" },
                            '&.MuiDataGrid-root': { paddingLeft: "0" },
                            '&::placeholder': { color: 'black' },
                            '.MuiDataGrid-columnHeaderTitle': { fontSize: 'small', fontWeight: '600', '@media (max-width:1080px)': { fontSize: '11px' } },
                            '.MuiDataGrid-toolbarContainer': { justifyContent: 'flex-end', marginRight: "10px" },
                            '.MuiDataGrid-cellContent': { fontSize: "small" },
                            '.MuiDataGrid-virtualScroller': { minHeight: '50px' }
                        }}

                    />
                </div>
            </div>
        </>
    )


}