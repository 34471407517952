import React, { useState, useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Chip } from "@mui/material"
import WarningIcon from '@mui/icons-material/Warning';
import { DataGrid } from "@mui/x-data-grid"
import CircularProgress from '@mui/material/CircularProgress';

export default function NoRecipeItems({ menuItemsReturned, close }) {
    const [row, setRow] = useState(null)
    const [col, setCol] = useState(null)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if (menuItemsReturned) {
            setLoading(false)
            setData()
        }

    }, [menuItemsReturned])

    const setData = () => {
        setRow(menuItemsReturned.map(e => {

            return {
                id: e?.posMenuItemId,
                item: e?.posMenuItem,
                variant: e?.posMenuItemVariant ?? "Modifier",
                recipe: e?.ingredients
            }
        }).filter(i => i !== null));

        setCol([
            { field: 'item', headerName: 'Item Name', flex: 1.3 },
            { field: 'variant', headerName: 'Item Variant', flex: 1.5 },
            {
                field: 'recipe', headerName: 'Recipe Exists?', flex: 1, renderCell: (params) => {
                    let lbl = "No Recipe";

                    return <Chip icon={<WarningIcon sx={{ '&.MuiSvgIcon-root': { marginLeft: '5px' } }} />} label={lbl} variant={"outlined"} color={"error"} />;
                }
            }


        ]);
    }

    if (loading) {
        return (<div style={{ position: 'relative', top: '5em', left: "45%" }}><CircularProgress /></div>)
    }
    return (
        <div className='container-fluid d-flex flex-column' style={{ height: '60vh' }}>
            <div className='row' style={{ justifyContent: "center" }}>
                <div className='col-9' style={{ paddingTop: '15px', paddingLeft: '28px' }}>
                    <h4 style={{ marginBottom: "0" }}>Menu Items - No Recipe: </h4>
                </div>
                <div className='col-3' style={{ position: 'relative', left: "70px", paddingTop: '15px' }}>
                    <button type="button" className='blank-bttn'>
                        <CloseIcon onClick={() => close('exit')} />
                    </button>
                </div>

            </div>
            <div className='row'>
                <p style={{ paddingLeft: "28px", margin: '0' }}>Recipes needed to properly subtract daily inventory.</p>
            </div>
            <hr style={{ marginBottom: "10px" }} />
            <div />

            <div className="row " style={{ width: "600px" }}>
                <div className="col-12" >
                    <DataGrid
                        // checkboxSelection
                        // onRowSelectionModelChange={selectClick}
                        rows={row}
                        columns={col}
                        rowHeight={35}
                        // slots={{
                        //     toolbar: CustomToolbar
                        // }}

                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 10,
                                },
                            },
                        }}
                        sx={{
                            /*padding: "10px 0 0 25px", */height: '50vh', border: "none", overflowY: "scroll",
                            '.MuiToolbar-root': { alignItems: "baseline" },
                            '.MuiSvgIcon-root': { margin: 0 },
                            '.MuiFormControl-root': { margin: '0 10px 5px 0' },
                            '.MuiDataGrid-main': { marginRight: "0", border: "solid 1px lightgray" },
                            '.MuiDataGrid-columnHeaderTitle': { fontSize: 'small', fontWeight: '600', '@media (max-width:1080px)': { fontSize: '11px' } },
                            '.MuiDataGrid-toolbarContainer': { justifyContent: 'flex-end', marginRight: "10px" },
                            '.MuiDataGrid-cellContent': { fontSize: "small" }
                        }}

                    />
                </div>
            </div>

        </div>
    );
};
