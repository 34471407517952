import React from 'react';
import { useLocation } from '../../context/LocationContext';
import MenuItem from '@mui/material/MenuItem';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';


export default function SetupLocation({ locVal, locationParam }) {

    const { locationAccess } = useLocation();

    const locCount = locationAccess.length

    const handleChange = ({ target }) => {
        const value = target.value
        locationParam(value);
    }

    return (
        <>
            {(locCount > 1) ?

                <>
                    {/* <InputLabel ><PlaceOutlinedIcon /> Location</InputLabel> */}
                    <TextField
                        select
                        size='small'
                        label={"Copy from location"}
                        onChange={(e) => handleChange(e)}
                        value={locVal}
                        sx={{
                            width: '215px', marginLeft: '5.5%', color: '#4798e9',
                            '&.MuiFormControl-root': { height: '32px', marginBottom: '0' },
                            '.MuiInputBase-root': { height: '32px', color: "#4798e9", fontSize: '14px' },
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: '#1fb6ff', // Removes the default border color
                                },
                                '&:hover fieldset': {
                                    borderColor: '#1fb6ff',
                                    backgroundColor: "#1976d20a" // Keeps the border color transparent on hover
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: '#1fb6ff', // Ensures the border color remains the same when focused
                                },
                            },
                            ".MuiFormLabel-root": { color: "#1fb6ff", fontSize: '14px' }
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <PlaceOutlinedIcon sx={{ color: "#4798e9", fontSize: "larger" }} />
                                </InputAdornment>
                            ),
                        }}
                    // helperText="Busines"
                    >
                        {locationAccess.map((loc, ind) => (
                            <MenuItem key={ind} value={loc}>{loc}</MenuItem>
                        ))}

                    </TextField>
                </>
                :

                <>
                    {/* <InputLabel ><PlaceOutlinedIcon /> Location</InputLabel> */}
                    <TextField
                        defaultValue={locVal}
                        size='small'
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <PlaceOutlinedIcon />
                                </InputAdornment>
                            ),
                            readOnly: true
                        }}
                        sx={{
                            width: '215px', marginLeft: '5.5%', color: 'gray',
                            '.MuiFormControl-root': { border: 'solid blue', height: '32px', marginBottom: '0' },
                            '.MuiInputBase-root': { height: '32px' }
                        }} />
                </>
            }
        </>

    );
};
