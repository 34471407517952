import axios from "axios";

const baseUrl = process.env.REACT_APP_NETLIFY_API_URL || process.env.REACT_APP_API_URL

export default async function LogoutUser() {
    const url = '/api/logout'

    try {
        const response = await axios.get(`${baseUrl}/api/logout`)
        if (response.status === 200) {

            const success = true
            return { success }
        } else {
            const success = false
            return { success }
        }
    } catch (err) {
        const success = false
        return { success }
    }
}