import React, { useEffect, useState } from 'react';
import useFetch from '../../services/useFetch.js'
import { LineChart, ChartsXAxis } from '@mui/x-charts';
import CircularProgress from '@mui/material/CircularProgress';
import VendorFilter from './Helper/VendorFilter.jsx';
import GetData from '../../services/GetData.js';
import Chip from '@mui/material/Chip';
import GraphRange from './Helper/GraphRange.jsx';


export default function VendorLineGraph({ reload }) {
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)

    const [row, setRow] = useState([])
    const [col, setCol] = useState([])

    const [tblData, setTblData] = useState(null)
    const [load, setLoad] = useState(false)
    const [vendBool, setVendBool] = useState(false)
    const [vendFilter, setVendFilter] = useState(null)
    const [graphDate, setGraphDate] = useState("")

    // Will need to pull the first value in the vendor list

    useEffect(() => {
        if (tblData === null) {
            return
        }
        else {
            setLoad(false)
            setGraphData()
        }
    }, [tblData])

    useEffect(() => {
        if (vendFilter) {
            vendorNameFilter(vendFilter)
        }
    }, [reload])

    const setGraphData = () => {
        const newRow = tblData.map(e => (
            new Date(e.date).toLocaleDateString()
        ))
        const newCol = tblData.map(e => (e.totalPrice))

        setRow(newRow);
        setCol(newCol);
    }

    const vendorNameFilter = async (filter) => {
        // call GetData with the filter as the parameter to filter on
        setVendBool(true)
        setVendFilter(filter)
        const { getData, load, error } = await GetData("api/vendor-inv-costs",
            filter, 'vendor', startDate, endDate)
        if (load) { setLoad(true) }
        else if (error) { console.log(error) }
        else {
            setTblData(getData)
            setLoad(false)
        }
    }

    const handleDateRange = async (selection, startDt, endDt) => {
        setStartDate(startDt)
        setEndDate(endDt)
        setGraphDate(selection)

        if (vendBool) {
            const { getData, load, error } = await GetData("api/vendor-inv-costs",
                vendFilter, 'vendor', startDt, endDt)
            if (load) { setLoad(true) }
            else if (error) { console.log(error) }
            else {
                setTblData(getData)
                setLoad(false)
            }
        }
    }



    if (load) return (
        <>
            {/*-----------------------------------------------------LOAD BEFORE DATA-------------------------------------------------------------------------------------------  */}
            <div className='row' style={{ margin: "5px 0 0 0 ", alignItems: 'center' }}>
                <div className='col-xl-2 col-lg-2 col-md-3' style={{ paddingRight: "0", width: '14%' }}>
                    <p className='dsh-hr-ttl'>Vendor Invoice Cost</p>
                </div>
                <div className='col-xl-2 col-lg-2 col-md-1' style={{ paddingLeft: "0", width: '30px' }}>
                    <button className='blank-bttn'>
                        <VendorFilter vendor={vendorNameFilter} />
                    </button>
                </div>
                {vendBool &&
                    <div className='col-xl-2 col-lg-2' style={{ paddingBottom: '10px' }}>
                        <Chip label={vendFilter} variant="outlined" sx={{ color: 'gray' }} />
                    </div>
                }
                <div className='col-xl-2 col-lg-2 col-md-1 ms-auto ing-gph-rng'>
                    <GraphRange rangeDates={handleDateRange} view={graphDate} />
                </div>
            </div>
            <hr style={{ marginTop: '0', marginBottom: "15px" }} />
            <div className='row'>
                <div className='container' ><CircularProgress size={25} /></div>
            </div>
        </>
    )
    return (
        <>

            {/*-----------------------------------------------------GRAPH HEADER-------------------------------------------------------------------------------------------  */}
            <div className='row' style={{ margin: "5px 0 0 0 ", alignItems: 'center' }}>
                <div className='col-xl-2 col-lg-3 col-md-4' style={{ paddingRight: "0" }}>
                    <p className='dsh-hr-ttl'>Vendor Invoice Cost</p>
                </div>
                <div className='col-xl-2 col-lg-3 col-md-3' style={{ paddingLeft: "0", width: '30px' }}>
                    <button className='blank-bttn'>
                        <VendorFilter vendor={vendorNameFilter} />
                    </button>
                </div>
                {vendBool &&
                    <div className='col-xl-2 col-lg-3 col-md-3' style={{ paddingBottom: '10px' }}>
                        <Chip label={vendFilter} variant="outlined" sx={{ color: 'gray' }} />
                    </div>
                }

                {/*-----------------------------------------------------DATE RANGE DROP-------------------------------------------------------------------------------------------  */}
                <div className='col-xl-2 col-lg-3 col-md-4 ms-auto'>
                    <GraphRange rangeDates={handleDateRange} view={graphDate} />
                </div>
            </div>
            <hr style={{ marginTop: '0', marginBottom: "15px" }} />

            {/*-----------------------------------------------------GRAPH BELOW-------------------------------------------------------------------------------------------  */}


            {/*--------Data ? Line graph -> Else -> Tell user to get select Vendor------------  */}
            {tblData ?
                <div className='row align-items-center'>
                    <div className='col-12 d-flex justify-content-center'>
                        <LineChart
                            xAxis={[{
                                scaleType: 'band', tickLabelStyle: { angle: 10, textAnchor: 'start' }, data: row, label: 'Invoice Date',
                                labelStyle: { fontSize: '14px', marginTop: '20px' }
                            }]}
                            yAxis={[{
                                label: 'Cost of Invoice ($)', tickLabelStyle: { angle: 10, textAnchor: 'end' },
                                labelStyle: { fontSize: '14px' }
                            }]}
                            series={[
                                {
                                    data: col, color: '#1fb6ff'
                                },
                            ]}

                            width={1000}
                            height={300}
                        />
                    </div >
                </div>
                :
                <div className='row align-items-center' style={{ marginTop: '25px' }}>
                    <div className='col d-flex justify-content-center'>
                        <p> Select a vendor from the filter to view the graph</p>
                    </div>
                </div>

            }



        </>
    );
};
