import React, { useState, useEffect, useContext } from 'react';
import { LocationContext } from '../../../context/LocationContext.js';
import PostData from '../../../services/PostData.js';
import useFetchWithParams from "../../../services/useFetchWithParams.js"
import useFetch from "../../../services/useFetch.js"
import CloseIcon from '@mui/icons-material/Close';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import DoubleArrowRoundedIcon from '@mui/icons-material/DoubleArrowRounded';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import CircularProgress from '@mui/material/CircularProgress';
import Checkbox from '@mui/material/Checkbox';


/* --------------------------------------------------------------------IMPORTS END ---------------------------------------------------------------- */
export default function CopyMenuItemRecipes({ close, selIngredients }) {

    const { selectedLocation } = useContext(LocationContext)
    const bbLoc = selectedLocation
    const [load, setLoad] = useState(true)

    const { data: getItems, error } = useFetchWithParams(`api/copy-recipe-menu`, { "arrayItem": selIngredients, "type": "recipe", "pullFromLoc": bbLoc })
    const { data: getMenuItems, error: menuErr } = useFetch('api/menuItems', false)

    const [postLoad, setPostLoad] = useState(false)
    const [recipes, setRecipes] = useState([])
    useEffect(() => {
        let nameArr;
        if (getItems) {
            setLoad(false)
            nameArr = getItems?.filter(item => selIngredients?.includes(item.itemId));
            getItems?.map(ing => setRecipes(cur => [...cur, {
                copyFromName: ing.itemName + ' -- ' + (ing.variant ?? "Modifier"),
                copyFromId: ing.itemId,
                copyFromPOSId: ing.posId,
                copyToName: null,
                copyToId: null,
                copyToPosId: null
            }]))
        }
    }, [getItems])

    const handleToChange = (index, val, reason) => {
        if (reason !== 'clear') {
            const newRecipe = [...recipes]

            newRecipe[index]["copyToName"] = val.itemName
            newRecipe[index]["copyToId"] = val.itemId
            newRecipe[index]["copyToPosId"] = val.posItemId
            setRecipes(newRecipe)
        }
    }

    const handleSubmit = async () => {

        setPostLoad(true)
        const { success, error } = await PostData(recipes, 'api/copy-recipe', false)
        if (success) {
            close(false, "copied")
            setPostLoad(false)
        }
    }

    if (load || postLoad) {
        return (<div className="container" style={{ top: "5px", left: "45%" }}><CircularProgress /></div>)
    }
    return (
        <div className='container-fluid d-flex flex-column' style={{ height: '60vh' }}>
            <div className='row inv-hdr' style={{ alignItems: 'baseline', marginBottom: '18px' }}>
                <div className='col-10'>
                    <h4 style={{ margin: "20px 0 20px 4%" }}>Copy Recipes: </h4>
                </div>
                <div className='col-2' style={{ position: 'relative', left: '10%' }}>
                    <button type='button' className='blank-bttn' onClick={() => close(false)}>
                        <CloseIcon sx={{ cursor: 'pointer' }} />
                    </button>
                </div>

            </div>
            <div className='row' style={{ height: '77%', overflowY: 'scroll', overflowX: 'hidden' }}>
                <div className='row' style={{ flexDirection: "column", paddingTop: '5px' }}>
                    <div className='col-12'>
                        {recipes?.map((elem, ind) =>
                            <div className='row' style={{ marginBottom: "20px", alignItems: 'center', justifyContent: "center", height: "max-content" }}>
                                <div className='col-5'>
                                    <TextField
                                        disabled
                                        size='small'
                                        value={recipes[ind]["copyFromName"] || ""}
                                        sx={{
                                            width: "100%",
                                            "& .MuiInputBase-input.Mui-disabled": {
                                                WebkitTextFillColor: "#000000"
                                            },
                                            "& .MuiFormHelperText-root.Mui-disabled": {
                                                WebkitTextFillColor: "#000000"
                                            }
                                        }} />
                                </div>
                                <div className='col-1'>
                                    <DoubleArrowRoundedIcon />
                                </div>
                                <div className='col-5'>
                                    <Autocomplete
                                        options={getMenuItems} size='small'
                                        onChange={(e, val, reason) => handleToChange(ind, val, reason)}
                                        getOptionLabel={(option) => (option.variant ? option.itemName + ' - ' + option.variant : option.itemName)}
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    borderColor: 'black', // Change outline color
                                                },

                                                '&:hover fieldset': {
                                                    borderColor: '#1fb6ff', // Change outline color on hover
                                                },
                                            },
                                        }}
                                        renderInput={(params) => <TextField {...params}
                                            name='copyFrom' value={elem} label="Copy To"
                                        />}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <div className='row' style={{ height: "75px", justifyContent: 'end', position: "fixed", background: 'white', bottom: '0', width: '100%' }}>
                <div className='col-12'>
                    <button className='submit' type='submit' style={{ width: "15%", marginLeft: '5%' }} onClick={handleSubmit}  >
                        Submit
                    </button>
                </div>

            </div>

        </div>
    );
};