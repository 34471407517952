/* Will copy over the Copy to logic and add to this page instead of the menu page */
import React, { useState, useEffect } from 'react';
import { Chip, Dialog } from "@mui/material";
import useSetup from "../../services/useSetup.js"
import CircularProgress from '@mui/material/CircularProgress';
import { DataGrid } from "@mui/x-data-grid"
import SetupCustomToolBar from './SetupCustomToolBar.jsx';
import CheckIcon from '@mui/icons-material/Check';
import WarningIcon from '@mui/icons-material/Warning';
import { Drawer } from '@mui/material';
import RecipeItems from './RecipeItems.jsx';
import CopyRecipes from '../Menu/HelperDialogs/CopyRecipes.jsx';
import SnackbarEx from '../Helpers/SnackbarEx.jsx';
import ConfirmCopyCustom from './ConfirmCopyCustom.jsx';

export default function MenuConfig({ selLoc, setLoc }) {
    const [refresh, setRefresh] = useState(false)

    const { data: merchantMenu, load, error } = useSetup(`api/configuration/menu/${selLoc}`, refresh)
    const { data: menuRecipes, load: loadRecipes, } = useSetup(`api/configuration/recipes/${selLoc}`, refresh)

    const [row, setRow] = useState([])
    const [col, setCol] = useState([])
    const [selAction, setSelAction] = useState({
        "openCopy": false,
        "openCustom": false,
        "copyCustom": false
    })

    const [selMenIdArr, setSelMenIdArr] = useState([])
    const [openRecipeDrawer, setOpenRecipeDrawer] = useState(false)
    const [hasCustom, setHasCustom] = useState(false)

    const [openTestSnack, setOpenTestSnack] = useState({
        color: "",
        message: "",
        open: false
    })

    useEffect(() => {
        if (merchantMenu !== null) {
            setData()
        }
    }, [merchantMenu, menuRecipes])


    const setData = () => {
        setRow(merchantMenu.map(e => {
            const itemIngredients = menuRecipes?.find(ing => ing.posMenuItemId === e.itemId);

            if (!itemIngredients) {
                return null; // or any other action to skip this iteration
            }

            return {
                id: e.itemId,
                iName: e.itemName,
                variant: e.variant ? e.variant : 'None',
                category: e.category,
                recipe: itemIngredients ? itemIngredients["ingredients"].length : 0
            }
        }).filter(item => item !== null));

        setCol([
            {
                field: "iName", headerName: 'Item Name', flex: 1
            },
            {
                field: "variant", headerName: 'Item Variation', flex: 1
            },
            { field: "category", headerName: 'Cost Category', flex: 1 },
            {
                field: 'recipe', headerName: 'Recipe Exists?', minWidth: '145', renderCell: (params) => {
                    const noRecipe = params.value === 0
                    let lbl;
                    if (noRecipe) {
                        lbl = "No Recipe"
                    } else { lbl = "Recipe" }
                    return <Chip icon={noRecipe ? <WarningIcon sx={{ position: 'relative', left: '5%' }} /> : <CheckIcon sx={{ position: 'relative', left: '5%' }} />} label={lbl} variant={"outlined"} color={noRecipe ? "error" : "success"} />;
                }
            }
        ]);
    }
    const changeLoc = (val) => {
        setLoc(val)
        refreshPage()
    }

    const actionChoice = (act) => {
        if (act === "copy_to_location" && hasCustom) {
            setSelAction(cur => ({ ...cur, ["openCustom"]: true }))
        }
        else if (act === "copy_to_location") {
            setSelAction(cur => ({ ...cur, ["openCopy"]: true }))
        }
    }

    function CustomToolbar() {
        return (
            <SetupCustomToolBar actionChoice={actionChoice} selLoc={changeLoc}
                locVal={selLoc} selItem={selMenIdArr} menuPg={true} openDrawer={(bool) => setOpenRecipeDrawer(bool)} />
        )
    }

    const selectClick = (e) => {

        if (e.length === 1) {
            setSelMenIdArr(e)
            const customEx = menuRecipes.some(item => e.includes(item.posMenuItemId) &&
                item.ingredients.some(ingredient => ingredient.type === 'custom'
                )
            );
            if (customEx) {
                setHasCustom(true)
            }
        } else if (e.length > 1) {
            setSelMenIdArr(e)
            const customEx = menuRecipes.some(item => e.includes(item.posMenuItemId) &&
                item.ingredients.some(ingredient => ingredient.type === 'custom'
                )
            );
            if (customEx) {
                setHasCustom(true)
            }

        } else {
            setHasCustom(false)
            setSelMenIdArr([])
        }
    }

    const toggleDrawer = (newOpen) => () => {
        setOpenRecipeDrawer(newOpen);
    };

    const handleClose = (bool, val) => {
        setSelAction(cur => ({ ...cur, ["openCopy"]: false }))
        if (val === "copied") {
            setOpenTestSnack({ color: "success", message: "Successfully copied item!", open: true })
            refreshPage()
            setSelMenIdArr([])
        }
    }

    const handleCopy = (bool) => {
        setSelAction(cur => ({ ...cur, ["openCustom"]: false, ["copyCustom"]: bool, ["openCopy"]: true }))
    }

    const refreshPage = () => {
        setRefresh(true)
        setTimeout(() => {
            // Code to be executed after the delay
            setRefresh(false)
        }, 200);
    }

    if (load || !merchantMenu) {
        return (<div className="container"><CircularProgress /></div>)
    }
    return (
        <div style={{ height: '48vh' }}>
            <div className='row' style={{ width: "100%" }}>
                <div className='col-12 menu-list dt' style={{ marginTop: '10px' }}>
                    <DataGrid
                        checkboxSelection
                        onRowSelectionModelChange={selectClick}
                        rowSelectionModel={selMenIdArr}
                        rows={row}
                        columns={col}
                        rowHeight={40}
                        slots={{
                            toolbar: CustomToolbar
                        }}
                        pageSizeOptions={[30]}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 30,
                                },
                            },
                        }}
                        sx={{
                            padding: "10px 0 0 25px", border: "none", marginLeft: "10px", overflowY: "scroll",
                            '.MuiToolbar-root': { alignItems: "baseline" },
                            '&.MuiDataGrid-root': { height: '70vh', paddingTop: '0' },
                            '.MuiDataGrid-main': { fontSize: 'small' },
                            '.MuiFormControl-root': { margin: '0 10px 5px 0' },
                            '.MuiSvgIcon-root': { margin: 0 },
                            '.MuiDataGrid-main': { margin: "10px 20px 0 0" },
                            '.MuiDataGrid-toolbarContainer': { justifyContent: 'flex-end', marginRight: "10px", paddingLeft: '0' },
                            '.MuiDataGrid-columnHeaderTitle': {
                                whiteSpace: "break-spaces",
                                lineHeight: "normal",
                                fontWeight: '600',
                                '@media (max-width:1080px)': { fontSize: '11px' }

                            }
                        }}
                    />
                </div>
            </div>
            <Drawer anchor={'bottom'} open={openRecipeDrawer} onClose={toggleDrawer(false)} sx={{ background: 'lighgray' }}>
                <RecipeItems ingredientId={selMenIdArr ? selMenIdArr[0] : []} selLoc={selLoc} />
            </Drawer>

            <Dialog fullScreen open={selAction["openCopy"]} onClose={handleClose} >
                <CopyRecipes close={handleClose} selIngredients={selMenIdArr} copyType={"recipe"} bbLoc={selLoc} copCust={selAction["copyCustom"]} />
            </Dialog>

            <Dialog maxWidth={'sm'} open={selAction["openCustom"]} onClose={() => setSelAction(cur => ({ ...cur, ["openCustom"]: false }))}>
                <ConfirmCopyCustom copyItem={(bool) => handleCopy(bool)} curLoc={selLoc} />
            </Dialog>
            {
                openTestSnack.open && <SnackbarEx color={openTestSnack.color} message={openTestSnack.message} open={openTestSnack.open}
                    close={() => setOpenTestSnack({
                        color: "",
                        message: "",
                        open: false
                    })} />
            }
        </div>
    );
};
