
export const handleQuickBooksAuth = () => {

    //   const clientId = process.env.REACT_APP_QUICKBOOKS_CLIENT_ID;
    const clientId = "ABiaFb7fioRLqjlZahWLVzfJOEoVz4iH6ZfSpIrtusorfkCrU6"
    // const redirectUri = encodeURIComponent(process.env.REACT_APP_QUICKBOOKS_REDIRECT_URI);
    const redirectUri = encodeURIComponent("https://improved-koala-golden.ngrok-free.app/qb-redirect");
    const scope = encodeURIComponent("com.intuit.quickbooks.accounting");
    const state = "randomState"; // You can generate a dynamic state

    const authUrl = `https://appcenter.intuit.com/connect/oauth2?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&scope=${scope}&state=${state}`;

    // Redirect user to QuickBooks OAuth consent page
    window.location.href = authUrl;
}