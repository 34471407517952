import React, { useState, useEffect, useContext } from 'react';
import { LocationContext } from '../../../context/LocationContext.js';
import useFetch from "../../../services/useFetch.js"
import SyncOutlinedIcon from '@mui/icons-material/SyncOutlined';
import { DataGrid, GridToolbarContainer } from "@mui/x-data-grid"
import GetData from '../../../services/GetData.js';
import { CircularProgress } from '@mui/material';
import MergeIngredient from './MergeIngredient.jsx';
import { Dialog } from "@mui/material";
import SnackbarEx from '../../Helpers/SnackbarEx.jsx';

export default function QuickbooksVendors() {
    const { selectedLocation } = useContext(LocationContext)
    const [row, setRow] = useState([])
    const [col, setCol] = useState([])
    const [reload, setReload] = useState(false)
    const [syncing, setSyncing] = useState(false)
    const [load, setLoad] = useState(true)
    const [selSingleIngredient, setSelSingleIngredient] = useState(null)
    const [selIngrIdArr, setSelIngrIdArr] = useState(null)
    const [mergeOpen, setMergeOpen] = useState(false)
    const [openTestSnack, setOpenTestSnack] = useState({
        color: "",
        message: "",
        open: false
    })

    const { data: qbVendors, error } = useFetch("api/check-quickbooks-items/vendors", reload)

    useEffect(() => {

        if (qbVendors) {
            setLoad(false)
            setData()
        }
    }, [qbVendors])

    const setData = () => {
        setRow(qbVendors.map(e => {
            const vend = e.qboVendorId.find(ven => ven.loc = selectedLocation)
            return {
                id: e.vendorId,
                rawItem: e.vendor,
                qboId: vend?.qboId
            }
        }).filter(item => item !== null));

        setCol([
            { field: 'rawItem', headerName: 'Vendor Name', flex: 1 },
            { field: 'qboId', headerName: 'QBO Vendor Id', flex: .35 },
        ]);
    }

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <div className="col-xl-2 col-lg-3 me-auto d-flex align-items-center">
                    <button style={{
                        marginTop: '0', display: "flex", alignItems: "center", color: selIngrIdArr?.length > 0 ? "#1fb6ff" : "lightgray",
                        height: '32px', border: selIngrIdArr?.length > 0 ? "solid 1px #1fb6ff" : "solid 1px lightgray", maxWidth: '300px'
                    }}
                        onClick={() => setMergeOpen(true)}
                        className={selIngrIdArr?.length === 1 ? "delete-bttn" : "delete-dis"}
                        disabled={selIngrIdArr?.length === 0 ? true : false}>{selIngrIdArr?.length > 1 ? "Merge vendors" : "Merge vendor"}</button>
                </div>
                <div>
                    <button
                        onClick={syncQBVendors}
                        className='submit'
                        style={{ display: "flex", width: '165px', height: '33.5px', alignItems: 'center' }}>
                        <div style={{ width: 'fit-content', padding: '5px 8px' }}>
                            {syncing ?
                                <CircularProgress size={18} sx={{ color: 'white', margin: '0', padding: '0' }} />
                                :
                                <SyncOutlinedIcon fontSize='small' />
                            }

                        </div>
                        <div style={{ width: 'fit-content', padding: '5px 0' }}>
                            <p style={{ width: 'fit-content', margin: '0' }}>Sync QB Vendors</p>
                        </div>
                    </button>
                </div>
            </GridToolbarContainer >
        )
    }

    const CustomNoRowsOverlay = () => {
        return (
            <div style={{ position: 'relative', left: '42%', top: '35%' }}>
                <p style={{ margin: '0' }}>Please sync to get data from Quickbooks</p>
                <button
                    onClick={syncQBVendors}
                    className='submit'
                    style={{ display: "flex", width: '165px', marginLeft: '55px', marginTop: '10px', height: 'fit-content' }}>
                    <div style={{ width: 'fit-content', padding: '5px 8px' }}>
                        {syncing ?
                            <CircularProgress size={18} sx={{ color: 'white', margin: '0', padding: '0' }} />
                            :
                            <SyncOutlinedIcon fontSize='small' />
                        }
                    </div>
                    <div style={{ width: 'fit-content', padding: '5px 0' }}>
                        <p style={{ width: 'fit-content', margin: '0' }}>Sync QB Vendors</p>
                    </div>
                </button>
            </div>

        )
    }

    const syncQBVendors = async () => {
        setSyncing(true)
        const { getData, load, error } = await GetData("api/sync-to-quickbooks/vendors", null, null, null, null)
        if (getData) {
            refreshPage()
            setOpenTestSnack({ color: "success", message: "Vendors successfully added!", open: true })
            setSyncing(false)
        }
    }

    const refreshPage = () => {
        setReload(true)
        setTimeout(() => {
            // Code to be executed after the delay
            setReload(false)
        }, 200);
    }

    const selectClick = (e) => {
        if (e.length === 1) {
            // const findData = data.find((element) => e[0] === element.itemId)

            setSelSingleIngredient(e[0])
            setSelIngrIdArr(e)
        } else if (e.length > 1) {
            setSelSingleIngredient(false)
            setSelIngrIdArr(e)

        } else {
            setSelSingleIngredient(false)
            setSelIngrIdArr(null)
        }
    }

    const handlePopupClose = (val) => {
        setMergeOpen(false)
        refreshPage()
    }

    return (
        <div>
            <Dialog fullScreen open={mergeOpen} onClose={handlePopupClose} >
                <MergeIngredient close={handlePopupClose} selItems={selIngrIdArr} mergeType={"vendor"} />
            </Dialog>
            <div className='row' style={{ width: "100%" }}>
                <div className='col-12 menu-list dt' style={{ marginTop: '0', height: '70vh' }}>
                    <DataGrid
                        checkboxSelection
                        onRowSelectionModelChange={selectClick}
                        rows={row}
                        columns={col}
                        rowHeight={50}
                        loading={load}
                        slots={{
                            noRowsOverlay: !load ? CustomNoRowsOverlay : undefined,
                            toolbar: CustomToolbar
                        }}
                        // processRowUpdate={(updatedRow, originalRow) => editCell(updatedRow, originalRow)}
                        // onProcessRowUpdateError={(e) => (console.log(e))}

                        // onCellEditStart={editStart}                        
                        sx={{
                            padding: "0 0 0 25px", border: "none", marginLeft: "10px", overflowY: "scroll",
                            '.MuiToolbar-root': { alignItems: "baseline" },
                            '.MuiDataGrid-main': { fontSize: 'small', marginTop: '0' },
                            '.MuiFormControl-root': { margin: '0 10px 5px 0' },
                            '.MuiSvgIcon-root': { margin: 0 },
                            '.MuiDataGrid-main': { margin: "0 20px 0 0" },
                            '.MuiDataGrid-toolbarContainer': { justifyContent: 'flex-end', marginRight: "10px", paddingLeft: '0' },
                            '.MuiDataGrid-columnHeaderTitle': {
                                whiteSpace: "break-spaces",
                                lineHeight: "normal",
                                fontWeight: '600',
                                '@media (max-width:1080px)': { fontSize: '11px' }

                            }
                        }}
                    />
                </div>
            </div>
            {
                openTestSnack.open && <SnackbarEx color={openTestSnack.color} message={openTestSnack.message} open={openTestSnack.open}
                    close={() => setOpenTestSnack({
                        color: "",
                        message: "",
                        open: false
                    })} />
            }
        </div>
    );
};
