

export default function ShopifyOAuth(shopDomain) {

    const clientId = process.env.REACT_APP_NETLIFY_SHOPIFY_CLIENTID || process.env.REACT_APP_SHOPIFY_CLIENTID

    const redirectUri = process.env.REACT_APP_NETLIFY_SHOPIFY_REDIRECT || process.env.REACT_APP_SHOPIFY_REDIRECT

    const encredirectUrl = encodeURIComponent(redirectUri);
    const scopes = "read_orders,write_orders, read_product_listings, read_customers,read_locations" //,read_products,read_product_listings"
    const nonce = process.env.REACT_APP_NETLIFY_SHOPIFY_SECURITY_NONCE || process.env.REACT_APP_SHOPIFY_SECURITY_NONCE


    const oauthUrl = `https://${shopDomain}.myshopify.com/admin/oauth/authorize?client_id=${clientId}&scope=${scopes}&redirect_uri=${encredirectUrl}&state=${nonce}`

    window.location.href = oauthUrl
}

