import React, { createContext, useState, useEffect, useContext } from "react"

const LocationContext = createContext();


export const LocationProvider = ({ children }) => {
    const [locationAccess, setLocationAccess] = useState([])

    const [selectedLocation, setSelectedLocation] = useState(() => {

        // Initialize with the value from localStorage if available, otherwise default to null
        const storedLocation = localStorage.getItem('usrlctn');
        return storedLocation !== null ? storedLocation : null;
    });

    useEffect(() => {
        // Save selected location to sessionStorage whenever it changes
        localStorage.setItem('usrlctn', selectedLocation);
    }, [selectedLocation]);

    return (
        <LocationContext.Provider value={{ selectedLocation, setSelectedLocation, locationAccess, setLocationAccess }}>
            {children}
        </LocationContext.Provider>
    );
};

export const useLocation = () => useContext(LocationContext);
export { LocationContext }
// export const locationContext = React.createContext(null);
