import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import MenuItem from '@mui/material/MenuItem';
import useFetch from '../../../services/useFetch.js'

export default function ImportPDFFile({ vendorSel }) {
    const { data: vends, error } = useFetch('api/vendors', false)
    const [vendorNames, setVendorNames] = useState([])
    useEffect(() => {
        if (vends) {
            const names = vends.map(vend => vend.vendor); // Extract all vendor names            
            setVendorNames(names); // Update state with the array of names
        }
    }, [vends])
    return (

        <div className='row' style={{ marginTop: '5px', marginBottom: '15px' }}>
            <div className='col-12'>
                <Autocomplete
                    size="small"
                    options={vendorNames ? vendorNames : []}
                    onChange={(e, val) => vendorSel(val, "VendorName")}
                    sx={{ width: "419px", '.MuiInputBase-root': { height: '38px' } }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Select Vendor"
                        />
                    )}>
                    {/* {vendorCsv.map((val, ind) =>
                        <MenuItem key={ind} value={val}>{val}</MenuItem>
                    )} */}
                </Autocomplete>
            </div>
        </div>
    );
};