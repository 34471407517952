import React, { useState, useEffect } from 'react';
import useFetch from "../../services/useFetch.js"
import CircularProgress from '@mui/material/CircularProgress';

export default function TotInventory({ startDate, endDate, reload, showLoad }) {
    const [val, setVal] = useState(0.0)

    const apiUrl = `api/inventory-cost?startDateStr=${startDate}&endDateStr=${endDate}`

    const { data, error, load } = useFetch(apiUrl, reload)

    useEffect(() => {
        if (data === null) {
            return
        } else {
            showLoad()
            setVal(data.sumInventoryCost)
        }
    }, [data])


    if (load) {
        return (<div className="container"><CircularProgress /></div>)
    }
    return (
        <>
            <div className='row' style={{ margin: "15px 0 0 0 " }}>
                <p className='dsh-hr-ttl'>Inventory Sum Cost</p>
            </div>

            <div className='row' style={{ margin: "0 0 0 0" }}>

                <div className='col-12' style={{ paddingRight: '15px' }}>

                    <p className='dsh-hr-inf' style={{ color: val ? '#1fb6ff' : "#0e1111" }}>{val ? '$' + val.toFixed(2) : "No data for dates"}</p>
                </div>
            </div>
        </>
    );
};
