/* Grab the allowable location names from the POS system using the core API */

import React, { useState } from 'react';

import { useNavigate } from "react-router-dom"
import { Dialog } from "@mui/material";
import useFetch from '../../../services/useFetch.js'
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import SnackbarEx from "../../Helpers/SnackbarEx.jsx"
import NewLocationConfirmation from "./NewLocationConfirmation"

export default function AddLocation({ locations }) {
    const nav = useNavigate()

    const { data: apiData, load } = useFetch("api/business-pos-system", false)
    const { data: userEmails, load: usrLoad } = useFetch("api/business-users", false)

    const [selectedLocation, setSelectedLocation] = useState([])
    const [selectedUser, setSelectedUsers] = useState([])
    const [submitLoad, setSubmitLoad] = useState(false)
    const [openCopySelection, setOpenCopySelection] = useState(false)

    const [openTestSnack, setOpenTestSnack] = useState({
        color: "",
        message: "",
        open: false
    })

    const handleLocationNameChange = ({ target }, val, reason) => {

        if (reason === "selectOption") {
            //adding a new value
            const index = val.length - 1
            const record = apiData.find(loc => loc.name === val) //record.id
            setSelectedLocation([...selectedLocation, { "locationName": val, "locationId": record.id }])
        } else if (reason === "removeOption") {
            // removing a value, so find it and remove from array
            const matchingObjects = selectedLocation.filter(obj => val.includes(obj["locationName"]));
            setSelectedLocation(matchingObjects)
        } else {
            setSelectedLocation([])
        }
    }

    const handleUserChange = ({ target }, val, reason) => {

        if (reason === "selectOption") {
            //adding a new value
            const index = val.length - 1

            setSelectedUsers(val)
        } else if (reason === "removeOption") {
            // removing a value, so find it and remove from array
            const matchingObjects = selectedUser.filter(obj => val.includes(obj));

            setSelectedUsers(matchingObjects)
        } else {
            setSelectedUsers([])
        }
    }

    const handleCopyPop = () => {
        setOpenCopySelection(true)
    }
    const handlePopClose = (val) => {
        switch (val) {
            case "close":
                setOpenCopySelection(false)
                break;
            case "success":
                setOpenCopySelection(false)
                setOpenTestSnack({ color: "success", message: "New location added! Log back in to see changes.", open: true })

            default:
                break;
        }
    }

    if (load || usrLoad) {
        return (<div className="container" style={{ left: '15%' }}><CircularProgress size={30} /></div>)
    }
    return (
        <div>
            <Dialog maxWidth={'sm'} fullWidth open={openCopySelection} onClose={() => setOpenCopySelection(false)}>
                <NewLocationConfirmation close={(val) => handlePopClose(val)} selectedLocation={selectedLocation} selectedUser={selectedUser} />
            </Dialog>

            < div className='row' style={{ padding: "5px 0 25px 5px" }}>
                <p style={{ fontSize: '20px', height: '30px', paddingLeft: '15px', margin: '0' }}>Add new locations: </p>
                <hr style={{ borderWidth: '0.5px', margin: '0', width: '315px' }} />
            </div >
            <div className='row' style={{ height: "45%", flexDirection: 'column' }}>
                <div className='row'>
                    <div className='col-12'>
                        <Autocomplete

                            noOptionsText={"Loading..."}
                            size="small"
                            options={apiData?.map((option) => option.name) ?? []}
                            name='locationNames'
                            value={selectedLocation[0]?.locationName || ""}
                            onChange={(e, val, reason) => handleLocationNameChange(e, val, reason)}
                            sx={{ width: "325px" }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={"Select location"}
                                />
                            )}>
                        </Autocomplete>
                    </div>
                </div>

                <div className='row' style={{ marginTop: '20px' }}>
                    <div className='col-12'>
                        <Autocomplete
                            multiple
                            noOptionsText={"Loading..."}
                            size="small"
                            options={userEmails ?? []}
                            name='useNames'
                            // value={selecte || ""}
                            onChange={(e, val, reason) => handleUserChange(e, val, reason)}
                            sx={{ width: "325px" }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={"Select users"}
                                />
                            )}>
                        </Autocomplete>
                    </div>
                </div>
            </div>


            <div className='row settings-bttn' >
                {!submitLoad ? <button className='submit' type='submit' /*onClick={handleSubmit}*/ onClick={handleCopyPop}
                    style={{ width: '305px', /*, position: 'fixed', top: "80.5%", marginLeft: '15px',*/ height: '35px' }}>
                    Submit
                </button> :
                    <button className='submit' type='submit' onClick={handleCopyPop}
                        style={{ width: '305px',/* position: 'fixed', top: "80.5%", marginLeft: '15px',*/ height: '35px' }}>
                        <CircularProgress size={20} sx={{ color: 'white', marginTop: '5px' }} />
                    </button>}
            </div>

            {
                openTestSnack.open && <SnackbarEx color={openTestSnack.color} message={openTestSnack.message} open={openTestSnack.open}
                    close={() => setOpenTestSnack({
                        color: "",
                        message: "",
                        open: false
                    })} />
            }
        </div>
    );
};
