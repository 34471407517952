import React, { useState } from 'react';
import { states } from '../Inventory/UsStates';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import PostData from '../../services/PostData';
import SnackbarEx from '../Helpers/SnackbarEx';


export default function AddNewVendor({ close }) {

    const [submittingData, setSubmittingData] = useState(false)
    const [vendorDetails, setVendorDetails] = useState({
        "vendor": null,
        "street": null,
        "city": null,
        "zip": null,
        "state": null,
        "vendorEmail": null,
        "emailOrdering": false
    })
    const [openTestSnack, setOpenTestSnack] = useState({
        color: "",
        message: "",
        open: false
    })

    const handleInputChange = ({ target }, key) => {
        setVendorDetails(prevVals => ({ ...prevVals, [key]: target.value }))
    }

    const handleStateSelection = ({ target }, val, key) => {
        setVendorDetails(prevVals => ({ ...prevVals, [key]: val }))
    }

    const updateEmailPref = () => {
        const curEmailPref = vendorDetails.emailOrdering
        setVendorDetails(prevVals => ({ ...prevVals, ["emailOrdering"]: !curEmailPref }))
    }

    const closePop = () => {
        close(false)
    }

    const handleSubmit = async () => {
        setSubmittingData(true)
        const { success: vendorAddSuccess, error: vendorAddErr } = await PostData(vendorDetails, "api/add-vendor", false)
        if (vendorAddSuccess) {
            setSubmittingData(false)
            close('success_add')
        } else {
            setSubmittingData(false)
            setOpenTestSnack({ color: "error", message: "Unable to add vendor.", open: true })
        }
    }

    // if (load) {
    //     return (<div className="container"><CircularProgress /></div>)
    // }
    return (
        <div style={{ height: "72vh", width: "100%" }}>
            <div className='row' style={{ position: 'relative', left: '3%', top: '2%', padding: '10px' }}>
                <div className='col-9'>
                    <h4 style={{ marginBottom: "0" }}>Add Vendor: </h4>
                </div>
                <div className='col-3' style={{ position: 'relative', left: "60px" }}>
                    <button type="button" className='blank-bttn'>
                        <CloseIcon onClick={closePop} />
                    </button>
                </div>

            </div>
            <hr style={{ marginBottom: '35px' }} />

            <div className='row' style={{ marginBottom: "0", justifyContent: "center", alignItems: "center", marginLeft: '15px' }}>
                <div className='col-xl-4' style={{ paddingRight: '0' }}>

                    <TextField
                        disabled
                        value={"Vendor Name"}
                        sx={{
                            width: '100%',
                            '&.MuiTextField-root .MuiInputBase-root': {
                                backgroundColor: 'lightgray',
                                borderRadius: '0',
                            },
                            ".MuiOutlinedInput-input.Mui-disabled": {
                                WebkitTextFillColor: "#2c2c2c",
                            },
                        }}
                        size='small' />
                </div>
                <div className='col-xl-8' style={{ paddingLeft: "0" }}>
                    <TextField
                        required

                        onChange={(e) => handleInputChange(e, 'vendor')}
                        sx={{
                            borderRadius: '5px',
                            width: '300px',
                            '&.MuiTextField-root .MuiInputBase-root': {
                                borderRadius: '0'
                            },
                            '&.MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: 'gray', // Default outline color
                                },
                            }
                        }}
                        size='small' />
                </div>
            </div>

            <div className='row' style={{ marginBottom: "0", justifyContent: "center", alignItems: "center", marginLeft: '15px' }}>
                <div className='col-xl-4' style={{ paddingRight: '0' }}>

                    <TextField
                        disabled
                        value={"Street"}
                        sx={{
                            width: '100%',
                            '&.MuiTextField-root .MuiInputBase-root': {
                                backgroundColor: 'lightgray',
                                borderRadius: '0',
                            },
                            ".MuiOutlinedInput-input.Mui-disabled": {
                                WebkitTextFillColor: "#2c2c2c",
                            },
                        }}
                        size='small' />
                </div>
                <div className='col-xl-8' style={{ paddingLeft: "0" }}>
                    <TextField
                        required

                        onChange={(e) => handleInputChange(e, 'street')}
                        sx={{
                            borderRadius: '5px',
                            width: '300px',
                            '&.MuiTextField-root .MuiInputBase-root': {
                                borderRadius: '0'
                            },
                            '&.MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: 'gray', // Default outline color
                                },
                            }
                        }}
                        size='small' />
                </div>
            </div>

            <div className='row' style={{ marginBottom: "0", justifyContent: "center", alignItems: "center", marginLeft: '15px' }}>
                <div className='col-xl-4' style={{ paddingRight: '0' }}>

                    <TextField
                        disabled
                        value={"City"}
                        sx={{
                            width: '100%',
                            '&.MuiTextField-root .MuiInputBase-root': {
                                backgroundColor: 'lightgray',
                                borderRadius: '0',
                            },
                            ".MuiOutlinedInput-input.Mui-disabled": {
                                WebkitTextFillColor: "#2c2c2c",
                            },
                        }}
                        size='small' />
                </div>
                <div className='col-xl-8' style={{ paddingLeft: "0" }}>
                    <TextField
                        required

                        onChange={(e) => handleInputChange(e, 'city')}
                        sx={{
                            borderRadius: '5px',
                            width: '300px',
                            '&.MuiTextField-root .MuiInputBase-root': {
                                borderRadius: '0'
                            },
                            '&.MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: 'gray', // Default outline color
                                },
                            }
                        }}
                        size='small' />
                </div>
            </div>

            <div className='row' style={{ marginBottom: "0", justifyContent: "center", alignItems: "center", marginLeft: '15px' }}>
                <div className='col-xl-4' style={{ paddingRight: '0' }}>

                    <TextField
                        disabled
                        value={"State"}
                        sx={{
                            width: '100%',
                            '&.MuiTextField-root .MuiInputBase-root': {
                                backgroundColor: 'lightgray',
                                borderRadius: '0',
                            },
                            ".MuiOutlinedInput-input.Mui-disabled": {
                                WebkitTextFillColor: "#2c2c2c",
                            },
                        }}
                        size='small' />
                </div>
                <div className='col-xl-8' style={{ paddingLeft: "0" }}>
                    <Autocomplete
                        required
                        options={states}
                        onChange={(e, val) => handleStateSelection(e, val, 'state')}
                        sx={{
                            borderRadius: '0',
                            width: '300px',
                            '.MuiInputBase-root': {
                                borderRadius: '0'
                            },
                            '&.MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: 'gray', // Default outline color
                                },
                            }
                        }}
                        size='small'
                        renderInput={(params) => (
                            <TextField
                                {...params}
                            />
                        )}>
                    </Autocomplete>
                </div>
            </div>

            <div className='row' style={{ marginBottom: "0", justifyContent: "center", alignItems: "center", marginLeft: '15px' }}>
                <div className='col-xl-4' style={{ paddingRight: '0' }}>

                    <TextField
                        disabled
                        value={"Zip"}
                        sx={{
                            width: '100%',
                            '&.MuiTextField-root .MuiInputBase-root': {
                                backgroundColor: 'lightgray',
                                borderRadius: '0',
                            },
                            ".MuiOutlinedInput-input.Mui-disabled": {
                                WebkitTextFillColor: "#2c2c2c",
                            },
                        }}
                        size='small' />
                </div>
                <div className='col-xl-8' style={{ paddingLeft: "0" }}>
                    <TextField
                        required

                        onChange={(e) => handleInputChange(e, 'zip')}
                        sx={{

                            width: '300px',
                            '&.MuiTextField-root .MuiInputBase-root': {
                                borderRadius: '0'
                            },
                            '&.MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: 'gray', // Default outline color
                                },
                            }
                        }}
                        size='small' />
                </div>
            </div>

            <div className='row' style={{ marginLeft: '15px', marginTop: '20px', alignItems: 'center' }}>
                <div className='col-1' style={{ paddingRight: "0", width: "5%" }}>
                    <Checkbox sx={{ padding: "0" }} checked={vendorDetails.emailOrdering} onChange={updateEmailPref} />
                </div>
                <div className='col-3'>
                    <p style={{ margin: '0' }}>Email ordering?</p>
                </div>
            </div>

            {vendorDetails.emailOrdering &&
                <div className='row' style={{ marginBottom: "0", marginLeft: '15px', marginTop: "15px" }}>
                    <div className='col-xl-4' style={{ paddingRight: '0' }}>

                        <TextField
                            disabled
                            value={"Email address"}
                            sx={{
                                width: '100%',
                                '&.MuiTextField-root .MuiInputBase-root': {
                                    backgroundColor: 'lightgray',
                                    borderRadius: '0',
                                },
                                ".MuiOutlinedInput-input.Mui-disabled": {
                                    WebkitTextFillColor: "#2c2c2c",
                                },
                            }}
                            size='small' />
                    </div>

                    <div className='col-xl-8' style={{ paddingLeft: "0" }}>
                        <TextField
                            required
                            placeholder='email@vendor.com'
                            onChange={(e) => handleInputChange(e, 'vendorEmail')}
                            sx={{
                                borderRadius: '5px',
                                width: '300px',
                                '&.MuiTextField-root .MuiInputBase-root': {
                                    borderRadius: '0'
                                },
                                '&.MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: 'gray', // Default outline color
                                    },
                                }
                            }}
                            size='small' />
                    </div>
                </div>

            }


            <div className='row'

                style={{ paddingBottom: "0", paddingLeft: '5.5%', justifyContent: 'start', position: "relative", top: vendorDetails.emailOrdering ? '20.46%' : '30%', }}>
                <button className='submit' type='submit' style={{ width: "90%" }} onClick={handleSubmit}>
                    Submit
                </button>
            </div>

            {openTestSnack.open && <SnackbarEx color={openTestSnack.color} message={openTestSnack.message} open={openTestSnack.open}
                close={() => setOpenTestSnack({
                    color: "",
                    message: "",
                    open: false
                })} />}

        </div>
    );
};
