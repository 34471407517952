/*
    Retrieves the Menu items from the db.
    Creates a dynamic input sheet that allows users to log items that were unsold and had to be thrown away.
    Will showcase the cost of each total menu item that was lost revenue for the business - using the item unit cost.
*/
import React, { useState, useEffect } from 'react';
import useFetch from "../services/useFetch.js"
import { Autocomplete, TextField } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import InputAdornment from '@mui/material/InputAdornment';
import PostData from "../services/PostData.js"
import { Navigate } from 'react-router-dom'
import SnackbarEx from '../components/Helpers/SnackbarEx.jsx'

const posMenuApi = "api/menuItems"
const customMenuApi = "api/custom-menu-item"

export default function WastedInventory() {

    const today = new Date().toDateString()

    const [curIngredient, setCurIngredient] = useState(false)
    const [qntChng, setQntChng] = useState(false)
    const [deadstockSum, setDeadstockSum] = useState(null)
    const [successfulSubmission, setSuccessfulSubmission] = useState(false)
    const [openTestSnack, setOpenTestSnack] = useState({
        color: "",
        message: "",
        open: false
    })
    const [loadSubmit, setLoadSubmit] = useState(false)
    const [combinedArr, setCombinedArr] = useState(null)


    const { data: posMenuItems, error, load } = useFetch(posMenuApi, false)
    const { data: customMenuItem, error: customError, load: customLoad } = useFetch(customMenuApi, false)

    useEffect(() => {
        if (posMenuItems !== null) {
            if (customMenuItem !== null) {
                const updatedMenuItems = customMenuItem.map(item => ({
                    ...item,
                    type: 'customItem'
                }))
                const combinedArray = [...posMenuItems, ...updatedMenuItems]

                setCombinedArr(combinedArray)
            } else {
                setCombinedArr(posMenuItems)
            }
        }
    }, [posMenuItems, customMenuItem])


    const [rowData, setRowData] = useState([
        {
            menuItem: null,
            menuItemId: null,
            quantity: null,
            unitPrice: Number(0.0),
            type: "Regular",
            total: Number(0.0)
        }
    ]);
    /* ---------------------------------------------------------------------------------------------------------------------
    Will grab and set the ingredient based on the ingredient chosen or input by the user 
    ---------------------------------------------------------------------------------------------------------------------*/
    const handleIngChange = (val, reason, index, rowKey) => {

        if (reason !== 'clear') {

            const newIngredient = [...rowData]
            newIngredient[index][rowKey] = val.itemName
            newIngredient[index]["menuItemId"] = val.itemId
            if (val.type) {
                newIngredient[index]["type"] = val.type
                newIngredient[index]['unitPrice'] = 0.0
                setRowData(newIngredient)
            }
            setRowData(newIngredient)

            const nmIndx = posMenuItems.findIndex(item => item.itemId === val.itemId)
            if (nmIndx >= 0) {
                const newData = [...rowData]
                const newVal = parseFloat(posMenuItems[nmIndx].productionCost).toFixed(2)
                newData[index]['unitPrice'] = Number(newVal)
                setRowData(newData)
                setCurIngredient(true)
            }
        }
    }

    /* ---------------------------------------------------------------------------------------------------------------------
        Will read the input of the user and update the proper key:value pair in the Data const
        ---------------------------------------------------------------------------------------------------------------------*/
    const handleInputChange = (index, { target }, rowKey) => {

        const uin = target.value
        const newData = [...rowData]
        newData[index][rowKey] = uin

        if (rowKey === 'quantity') {

            const newData = [...rowData]
            const newVal = parseFloat(rowData[index].unitPrice * uin).toFixed(2)
            newData[index]['total'] = Number(newVal)
            setQntChng(true)
            setRowData(newData)

            newData[index][rowKey] = Number(uin)
            setRowData(newData)
        }


        setRowData(newData)
    }

    const totalSum = () => {
        const newSumTotal = rowData.reduce((total, row) => parseFloat(total) + (parseFloat(row.total) || parseFloat(0.0)), 0.0);
        return newSumTotal
    }

    useEffect(() => {
        const newSum = totalSum()
        setDeadstockSum(newSum)
    }, [rowData])

    function onAdd() {
        setRowData([...rowData, { menuItem: null, menuItemId: null, quantity: null, unitPrice: null, total: null }])
    }

    const handleSubmit = async () => {
        setLoadSubmit(true)

        const { success } = await PostData(
            { "menuItems": rowData, "totalCost": deadstockSum },
            "api/deadstock", false)

        if (!success) {
            console.log("Failed API")
            setOpenTestSnack({ color: "error", message: "Error occured", open: true })
        }
        else {
            setLoadSubmit(false)
            setOpenTestSnack({ color: "success", message: "Deadstock recorded", open: true })

        }
    }

    const handleClose = () => {
        setOpenTestSnack({
            color: "",
            message: "",
            open: false
        })
        setSuccessfulSubmission(true)
    }

    if (successfulSubmission) {
        return <Navigate to="/analytics" replace={true} />
    }
    if (load || loadSubmit) {
        return (<div className="container"><CircularProgress /></div>)
    }
    return (
        <>
            <div className='sticky-header'>
                <div className='row pg-hdr' style={{ width: "98%" }}>
                    <div className="col-xl-10 col-lg-9 col-md-8" >
                        <h2>Unsold Items</h2>
                        <p style={{ marginBottom: '0' }}>Log unsold perishable items for: <span style={{ color: "#1fb6ff" }}>{today}</span> </p>
                    </div>

                </div>
                <hr style={{ marginTop: '0', marginBottom: "15px" }} />
            </div>

            <div className='row' style={{
                padding: "5px 0 0 10px", border: "none", margin: "20px 0 0 10px", overflowY: "scroll", height: "70vh",
            }}>
                <div className="col-12" style={{ width: '97%' }}>
                    <div className='row' style={{ justifyContent: "space-between", fontSize: '14px', marginTop: '10px', fontWeight: "bold 900" }}>
                        <div className='col-4'>Item Name</div>
                        <div className='col-2'>Qty</div>
                        <div className='col-2'>Unit Price</div>
                        <div className='col-2'>Total Price</div>
                    </div>
                    <hr />
                    {
                        rowData.map((elem, indx) =>
                            <div className='row' style={{ justifyContent: "space-between" }} key={indx}>
                                <div className='col-4'>
                                    <Autocomplete
                                        onChange={(e, val, reason) => handleIngChange(val, reason, indx, 'menuItem')}
                                        options={combinedArr}
                                        getOptionLabel={(option) => (option.variant ? option.itemName + ' - ' + option.variant : option.itemName)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                placeholder="Item"
                                                variant='standard'
                                                sx={{ '.MuiInputLabel-root, .MuiInput-root': { fontSize: "small" } }}
                                            />
                                        )}
                                    />
                                </div>

                                {/* Qty */}

                                <div className='col-2'>
                                    <TextField className="inv-hd" variant="standard"
                                        onChange={(e) => handleInputChange(indx, e, 'quantity')}
                                        value={rowData[indx]['quantity'] !== undefined ? rowData[indx]['quantity'] : ""}
                                        type='number'
                                        sx={{ '.MuiInputLabel-root, .MuiInput-root': { fontSize: "small" } }} />
                                </div>
                                {/* Unit Price */}
                                {!curIngredient ?
                                    <div className='col-2'>
                                        <TextField className="inv-hd" variant="standard" disabled
                                            onChange={(e) => handleInputChange(indx, e, 'unitPrice')}
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                            }}
                                            sx={{ '.MuiInputLabel-root, .MuiInput-root': { fontSize: "small" } }} />
                                    </div> :
                                    <div className='col-2'>
                                        <TextField className="inv-hd" variant="standard" disabled
                                            value={rowData[indx].unitPrice}
                                            onChange={(e) => handleInputChange(indx, e, 'unitPrice')}
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                            }}
                                            sx={{ '.MuiInputLabel-root, .MuiInput-root': { fontSize: "small" } }} />
                                    </div>}

                                {!qntChng ?
                                    <div className='col-2'>
                                        <TextField className="inv-hd" variant="standard" type='number' disabled

                                            onChange={(e) => handleInputChange(indx, e, 'total')}
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                            }}
                                            sx={{ '.MuiInputLabel-root, .MuiInput-root': { fontSize: "small" } }} />
                                    </div> :
                                    <div className='col-2'>
                                        <TextField className="inv-hd" variant="standard" type='number' disabled
                                            value={rowData[indx].total}
                                            onChange={(e) => handleInputChange(indx, e, 'total')}
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                            }}
                                            sx={{ '.MuiInputLabel-root, .MuiInput-root': { fontSize: "small" } }} />
                                    </div>}
                            </div>
                        )
                    }
                    <div className='row' style={{ justifyContent: "flex-end" }}>
                        <div className='col-1'>
                            <button type='button' style={{
                                background: 'none', border: 'none',
                                margin: "20px 0 15px 50%"
                            }}>
                                <AddCircleOutlineIcon onClick={onAdd} fontSize='medium' />
                            </button>
                        </div>
                    </div>
                    <div className='row' style={{
                        justifyContent: "flex-end", marginLeft: "2%", textDecoration: "underline", fontSize: "14px"
                    }}>
                        < div className='col-2' >
                            <p>Total Cost: <span>${deadstockSum.toFixed(2)}</span></p>
                        </div>
                    </div>
                    <div className='row' style={{ justifyContent: "flex-end" }}>
                        <div className='col-2'>
                            <button type='submit'
                                style={{ width: '100%', height: '40px', marginBottom: "10px", marginLeft: "0" }}
                                className={!(rowData[0]["menuItemId"] && rowData[0]["quantity"]) ? 'dis' : 'submit'}
                                disabled={!(rowData[0]["menuItemId"] && rowData[0]["quantity"])}
                                onClick={handleSubmit}
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {
                openTestSnack.open && <SnackbarEx color={openTestSnack.color} message={openTestSnack.message} open={openTestSnack.open}
                    close={handleClose} />
            }
        </>
    );
};
