import React from 'react';
import AddSpecialtyItem from './bulkCustomItem/AddSpecialtyItem';
import CloseIcon from '@mui/icons-material/Close';

export default function CustomItemDialog({ close }) {

    const closePop = () => {
        close(false)
    }

    return (

        <div style={{ height: '95vh' }}>
            <div className='row inv-hdr' style={{ alignItems: 'baseline' }}>
                <div className='col-10'>
                    <h4 style={{ margin: "20px 0 20px 7%" }}>New Custom Item: </h4>
                </div>
                <div className='col-2' style={{ position: 'relative', left: '8%' }}>
                    <button type='button' className='blank-bttn' onClick={closePop}>
                        <CloseIcon sx={{ cursor: 'pointer' }} />
                    </button>
                </div>

            </div>

            <AddSpecialtyItem close={close} />
        </div>
    );
}