import React from 'react'
import MainApp from '../components/Sidebar/MainApp'
import LookerSidebar from '../components/Sidebar/LookerSidebar'

export default function Sidebar({ usrRole }) {
    return (
        <div>
            {usrRole === "AnalyticsOnly" ?
                <LookerSidebar />
                :
                <MainApp />
            }
        </div>

    )
}