import React, { useEffect, useState } from 'react';
import PostData from "../../../services/PostData.js"
import SnackbarEx from '../../Helpers/SnackbarEx.jsx';
import TextField from '@mui/material/TextField';
import useFetch from "../../../services/useFetch.js"

export default function BusinessInfo() {

    const [primaryEmail, setPrimaryEmail] = useState(null)
    const [merchAddress, setMerchAddress] = useState(null)
    const [error, setError] = useState(false);
    const [editEnabled, setEditEnabled] = useState(false)
    const [submitLoad, setSubmitLoad] = useState(false)
    const [openTestSnack, setOpenTestSnack] = useState({
        color: "",
        message: "",
        open: false
    })

    const { data: merchantEmail, load, error: getDataErr } = useFetch("api/business-information/merch-email", false)
    const { data: merchantAddress, load: addressLoad, error: getAddressErr } = useFetch("api/business-information/address", false)

    useEffect(() => {
        merchantEmail && setPrimaryEmail(merchantEmail)
        merchantAddress && setMerchAddress(merchantAddress)
    }, [merchantEmail, merchantAddress])

    const emailRegex = /^[^@]+@[^@]+\.[^@]+$/;

    const handleChange = ({ target }, key) => {
        const newVal = target.value;
        key === "email" && setPrimaryEmail(newVal);
        key === "address" && setMerchAddress(newVal);
    }

    const updateProfile = async () => {
        setSubmitLoad(true)
        setError(!emailRegex.test(primaryEmail));
        if (emailRegex.test(primaryEmail)) {
            handleSubmit()
        }
    }

    const handleSubmit = async () => {
        const { success, error } = await PostData({ "email": primaryEmail, "address": merchAddress }, "api/update-merchant-information", false)
        if (success) {
            setEditEnabled(false)
            setSubmitLoad(false)
            setOpenTestSnack({ color: "success", message: "Merchant updated successfully!", open: true })
        } else {
            setOpenTestSnack({ color: "error", message: error.message, open: true })
        }
    }

    const enableEdit = () => { setEditEnabled(true) }


    return (
        <div>
            <div className='row' style={{ padding: "5px 0 15px 15px" }}>
                <div className='row' style={{ padding: "5px 0 15px 15px" }}>
                    <div className='col-12' style={{ padding: '0' }}>
                        <p style={{ fontSize: '20px', height: '30px', paddingLeft: '5px', margin: '0' }}>Merchant Information: </p>
                        <hr style={{ borderWidth: '0.5px', margin: '0', width: '315px' }} />
                    </div>
                </div>

                <div className='row' style={{ paddingLeft: '15px', alignItems: 'baseline' }}>
                    <div className='col-3' style={{ width: 'max-content' }}>
                        <label>Primary email: </label>
                    </div>
                    <div className='col-4' style={{ paddingLeft: "0" }}>
                        <TextField
                            variant='standard'
                            disabled={!editEnabled}
                            size='small'
                            placeholder='account@email.com'
                            onChange={(e) => handleChange(e, "email")}
                            error={error}
                            helperText={error ? 'Invalid email' : ''}
                            value={primaryEmail}
                            sx={{
                                width: '235px', marginLeft: '5.5%', color: 'gray',
                                "& .MuiInputBase-input": { fontSize: 'small' }, // Adjust font size
                                "& .MuiInputBase-input.Mui-disabled": { fontSize: 'small' }
                            }}
                        />
                    </div>
                </div>

                <div className='row' style={{ paddingLeft: '15px', alignItems: 'baseline', marginTop: '10px' }}>
                    <div className='col-3' style={{ width: 'max-content' }}>
                        <label>Address: </label>
                    </div>
                    <div className='col-4' style={{ paddingLeft: "0" }}>
                        <TextField
                            variant='standard'
                            disabled={!editEnabled}
                            size='small'
                            placeholder='address'
                            onChange={(e) => handleChange(e, "address")}
                            value={merchAddress}
                            sx={{
                                width: '275px', marginLeft: '5.5%', color: 'gray',
                                "& .MuiInputBase-input": { fontSize: 'small' }, // Adjust font size
                                "& .MuiInputBase-input.Mui-disabled": { fontSize: 'small' }
                            }}
                        />
                    </div>
                </div>

            </div>
            <div className='row' style={{ position: 'relative', top: error ? '37.0%' : '40.0%' }} >
                <button className='submit' type='submit' onClick={!editEnabled ? enableEdit : updateProfile}
                    style={{ width: '325px', marginLeft: '22px', height: '35px' }}>
                    {!editEnabled ? 'Edit' : 'Save'}
                </button>
            </div>

            {
                openTestSnack.open && <SnackbarEx color={openTestSnack.color} message={openTestSnack.message} open={openTestSnack.open}
                    close={() => setOpenTestSnack({
                        color: "",
                        message: "",
                        open: false
                    })} />
            }
        </div >
    );
};
