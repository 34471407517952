import { Navigate, Outlet } from "react-router-dom"
import React from "react"

export default function PrivateOutlet({
    isAllowed,
    redirectPath = "/login"
    , children }) {

    if (!isAllowed) {
        console.log("not allowed")
        return <Navigate to={redirectPath} replace />
    }

    return children ? children : <Outlet />;
}