import React, { createContext, useState, useContext } from 'react';

// export const authContext = React.createContext(null);

const authContext = createContext();

const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [usrGblRole, setUsrGblRole] = useState(false)
    const [userRole, setUserRole] = useState(null)

    const changeAuth = (auth) => {
        setIsAuthenticated(auth);
    };
    const changeUsrRole = (role) => {
        setUsrGblRole(role);
    };
    const updateUserRole = (type) => {
        setUserRole(type);
    };

    return (
        <authContext.Provider value={{ isAuthenticated, changeAuth, usrGblRole, changeUsrRole, updateUserRole, userRole }}>
            {children}
        </authContext.Provider>
    );
};

export const useAccount = () => useContext(authContext)
export { AuthProvider, authContext };

