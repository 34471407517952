import React, { useState, useEffect } from 'react';
import useFetch from '../../services/useFetch.js'
import { DataGrid } from "@mui/x-data-grid"
import { currencyFormatter } from '../Helpers/CurrencyFormat.js';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Drawer } from '@mui/material';
import ModifierDrawer from './ModifierDrawer.jsx';

export default function OrdersTable({ startDate, endDate, reload }) {

    const [row, setRow] = useState([])
    const [col, setCol] = useState([])
    const [openModifierDrawer, setOpenModifierDrawer] = useState(false)
    const [modifierDrawerData, setModifierDrawerData] = useState(null)

    const { data, error } = useFetch(`api/orders-log?startDateStr=${startDate}&endDateStr=${endDate}`, reload)
    const [menuItemData, setMenuItemData] = useState(null)

    useEffect(() => {
        if (data === null) {
            return
        } else {
            const newMenuItems = data.flatMap(e => e.menuItems);
            setMenuItemData(newMenuItems)
        }

    }, [data])
    useEffect(() => {
        if (menuItemData) {
            setData()
        }
    }, [menuItemData])

    const setData = () => {
        setRow(menuItemData ? menuItemData?.map((arr, ind) => ({
            id: ind,
            iName: arr.itemName,
            variant: arr.itemVariant ? arr.itemVariant : 'None',
            units: arr.totalUnits,
            amtSld: typeof arr.amountSold === 'number' ? arr.amountSold : 'None',
            cogs: typeof arr.totalCOGS === 'number' ? arr.totalCOGS : 'None',
            profit: typeof arr.totalItemProfit === 'number' ? arr.totalItemProfit : 'None',
            date: arr.orderDate,
        })) :
            []
        )

        setCol([

            { field: 'iName', headerName: 'Product', minWidth: '200', maxWidth: '225' },
            { field: 'variant', headerName: 'Variation', minWidth: '175', maxWidth: '175' },
            { field: 'units', headerName: 'Total Quantity', minWidth: '125', maxWidth: '125' },
            {
                field: 'amtSld', headerName: 'Total Revenue', valueFormatter: ({ value }) => {
                    return value !== null ? currencyFormatter(value, 2) : 'None';
                }, minWidth: '125', maxWidth: '125'
            },
            {
                field: 'cogs', headerName: 'Total Cost', valueFormatter: ({ value }) => {
                    return value !== null ? currencyFormatter(value, 2) : 'None';
                }, minWidth: '125', maxWidth: '125'
            },
            {
                field: 'profit', headerName: 'Unit Margin', valueFormatter: ({ value }) => {
                    return value !== null ? currencyFormatter(value, 2) : 'None';
                }, minWidth: '115', maxWidth: '115'
            },
            {
                field: 'date', headerName: 'Date', minWidth: '115', maxWidth: '115', valueFormatter: ({ value }) => {
                    return new Date(value).toLocaleDateString();
                }
            },
            {
                field: 'seeModifiers',
                headerName: '',
                minWidth: '200', maxWidth: '215',
                renderCell: (params) => {
                    if (menuItemData[params.id]["modifiersOrdered"] && menuItemData[params.id]["modifiersOrdered"].length > 0) {
                        return (
                            <button
                                style={{ height: '25px', background: 'rgba(0, 163, 224, 0.18)', border: "none", color: '#003366', borderRadius: '5px', borderWidth: "1px", width: '200px', fontWeight: '600' }}
                                onClick={() => modifierDetails(params)}
                            >
                                See Modifiers Ordered <KeyboardArrowDownIcon fontSize='small' />
                            </button>
                        );
                    } else {
                        return <p style={{ padding: '15px 15px', margin: '0', color: "gray" }}>No Modifiers Ordered</p>;
                    }
                }
                ,
            },
        ])
    }

    const modifierDetails = (orderRow) => {
        const rowItem = orderRow.row.iName
        const dataIndex = menuItemData[orderRow.id]
        setOpenModifierDrawer(true)
        setModifierDrawerData(dataIndex.modifiersOrdered)
    }

    const toggleDrawer = (newOpen) => () => {
        setOpenModifierDrawer(newOpen);
    };


    return (
        <div>
            <div className="row " style={{ width: "100%", position: 'relative', bottom: '20px' }}>
                <div className="col-12 menu-list" style={{ height: '80vh' }}>
                    <DataGrid

                        rows={row}
                        columns={col}
                        rowHeight={35}
                        // slots={{
                        //     // toolbar: GridToolbar,
                        //     toolbar: CustomToolbar
                        // }}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 25,
                                },
                            },
                            sorting: {
                                sortModel: [{ field: 'amtSld', sort: 'desc' }],
                            },
                        }}
                        sx={{
                            padding: "10px 0 0 25px", border: "none", overflowY: "scroll",
                            '.MuiToolbar-root': { alignItems: "baseline" },
                            '.MuiSvgIcon-root': { margin: 0 },
                            '.MuiFormControl-root': { margin: '0 10px 5px 0' },
                            '.MuiDataGrid-main': { marginRight: "20px" },
                            '.MuiDataGrid-columnHeaderTitle': { fontSize: '14px', fontWeight: '600', '@media (max-width:1080px)': { fontSize: '11px' } },
                            '.MuiDataGrid-toolbarContainer': { justifyContent: 'flex-end', marginRight: "10px" },
                            '.MuiDataGrid-cellContent': { fontSize: "small" }
                        }}
                    />
                </div>

            </div>
            <Drawer anchor={'bottom'} open={openModifierDrawer} onClose={toggleDrawer(false)} sx={{ background: 'lighgray' }}>
                <ModifierDrawer modifierData={modifierDrawerData} close={toggleDrawer(false)} />
            </Drawer>
        </div>
    );
};
