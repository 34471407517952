import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import CloseIcon from '@mui/icons-material/Close';


export default function AddVendorDialog({ closePop, storeName }) {
    const [vendorName, setVendorName] = useState(null)
    const [shopifyStore, setShopifyStore] = useState(null)

    const vendorOptions = ["Shopify", "QuickBooks"]

    const handleAuth = () => {
        if (vendorName === "Shopify") {
            storeName(shopifyStore)
        } else if (vendorName === "QuickBooks") {
            storeName('QBO')
        }
    }

    return (
        <div style={{ height: "45vh", width: "100%" }}>
            <div className='row' style={{ width: "100%", padding: "20px 0 0 20px" }}>
                <div className='col-9'>
                    <h4 style={{ marginBottom: "0" }}>Add Ingredient: </h4>
                </div>
                <div className='col-3' style={{ position: 'relative', left: "70px" }}>
                    <button type="button" className='blank-bttn'>
                        <CloseIcon onClick={() => closePop(false)} />
                    </button>
                </div>

            </div>
            <hr />
            <div className='row' style={{ width: "100%", padding: "10px 0 0 20px" }}>
                <div className='col-12'>
                    <Autocomplete
                        noOptionsText={"Loading..."}
                        size="small"
                        options={vendorOptions}
                        name='vendorNames'
                        // value={selectedLocation.locationName || ""}
                        onChange={(e, val) => setVendorName(val)}
                        sx={{ width: "315px", marginBottom: "20px" }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={"Select vendor"}
                            />
                        )}>
                    </Autocomplete>
                </div>
            </div>

            {vendorName === "Shopify" && <div className='row' style={{ width: "100%", padding: "0 0 0 20px", mariginBottom: '10px' }}   >
                <div className='row'>
                    <p style={{ fontSize: '15px', height: '30px', paddingLeft: '15px', margin: '0' }}>Shop url: </p>
                </div>
                <div className='row'>
                    <div className='col-12'>
                        <TextField
                            size='small'
                            placeholder='myshopdomain'
                            onChange={(e) => setShopifyStore(e.target.value)}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">.myshopify.com</InputAdornment>,
                            }}
                            sx={{ width: "315px" }}
                        />
                    </div>
                </div>
            </div>}

            <div className='row' style={{ padding: "20px 0 10px 20px", position: 'fixed', bottom: '28%' }}>
                <button
                    className={shopifyStore || vendorName ? 'submit' : 'dis'}
                    onClick={handleAuth}
                    style={{ width: '250px' }}>Authorize</button>
            </div>
        </div>
    );
};
