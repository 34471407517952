import React, { useEffect, useState, useContext } from 'react';
import useFetch from '../../../services/useFetch.js'
import FilterListIcon from '@mui/icons-material/FilterList';
import CircularProgress from '@mui/material/CircularProgress';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

export default function CategoryFilter({ category }) {

    const { data, error } = useFetch("api/menuCategories", false /*, usrLocs[0]*/)

    const [load, setLoad] = useState(true)

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    useEffect(() => {
        if (data === null) {
            return
        }
        else {
            setLoad(false)
        }
    }, [data])

    const handleDropdown = ({ target }) => {
        setAnchorEl(target)
    }

    const handleSelect = (categoryFilter) => {
        setAnchorEl(false)
        category(categoryFilter)
    }

    if (load) return (<div style={{ position: 'relative' }}><CircularProgress size={20} sx={{ marginTop: '3px' }} /></div>)
    return (
        <div>
            <FilterListIcon sx={{ marginBottom: '10px', color: 'gray', cursor: 'pointer' }} onClick={handleDropdown} />
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={() => { setAnchorEl(false) }}
                sx={{ '.MuiPaper-root': { width: "min-content", borderRadius: "10px" } }}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {data.map((category, ind) => (
                    < MenuItem key={ind} onClick={() => handleSelect(category)}>{category}</MenuItem>
                ))}
            </Menu>
        </div >
    );
};
