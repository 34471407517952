import React, { useState } from 'react';
import Bread_Bskt from "../images/Bread_Bskt.png"
import Snackbar from "@mui/material/Snackbar"
import Alert from '@mui/material/Alert'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import PostData from '../services/PostData';

export default function ResetPassword() {

    const [emailInput, setEmailInput] = useState("")
    const [emailSent, setEmailSent] = useState(false)
    const [openSuccess, setOpenSuccess] = useState(false)
    const [openFail, setOpenFail] = useState(false)
    const [load, setLoad] = useState(false)

    const submitEmail = async () => {
        setLoad(true)
        const { success } = await PostData({ email: emailInput }, "api/reset-password", null, null)

        if (success) {
            setLoad(false)
            setOpenSuccess(true)
            setEmailSent(true)
        } else {
            setLoad(false)
            setOpenFail(true)
        }
    }



    return (
        <div className='row justify-content-center align-items-center' style={{ height: "75vh" }}>

            <div className="col-xl-4 col-md-6">

                <div className="row" style={{ justifyContent: 'center' }}>
                    <div className="col-6 offset-xl-4 offset-lg-3 offset-md-3 offset-sm-2" style={{ marginBottom: '20px' }} >
                        <img src={Bread_Bskt} style={{ width: '115px', height: '110px' }} />
                    </div>
                </div>

                {/*---------------- -------- -------- --------UNDER LOGO SECTION -------- -------- -------- --------  */}
                {!emailSent ?
                    <div className='row offset-xl-1 rst-pss' style={{ justifyContent: 'center' }} >
                        <div className='col-12 '>
                            <div className='row' style={{ padding: "20px 15px 0 15px" }}>
                                <p>Forgot Password?</p>
                                <hr style={{ borderWidth: '0.5px' }} />
                            </div>

                            <div className='row' style={{ padding: "10px 15px 0 15px" }}>
                                <p>Please enter the email associated with your account, and we will email you a reset link.</p>
                            </div>
                            <div className='row' style={{ width: '100%', paddingLeft: '20px', paddingBottom: "25px" }}>
                                {/* <div className='col-xl-12 offset-xl-2'> */}
                                <TextField label="Email" size="small"
                                    onChange={(e) => setEmailInput(e.target.value)} value={emailInput} />
                                {/* </div> */}
                            </div>
                            <div className='row' style={{ width: "100%", padding: "25px 10px 0 10px", marginBottom: "25px" }}>
                                <button type='submit' className='submit' onClick={submitEmail}>Send reset link</button>
                            </div>
                        </div>

                    </div>
                    :
                    <div className='row offset-xl-2 offset-lg-2' style={{ justifyContent: 'center', marginTop: '30px' }}>
                        <div className='col-12 '>
                            <p style={{ fontSize: '20px', fontWeight: 'bold 900' }}>Please check your email for reset link.</p>
                        </div>
                    </div>
                }
            </div>
            <Snackbar
                open={openSuccess}
                autoHideDuration={2000}
                onClose={() => setOpenSuccess(false)}>
                <Alert onClose={() => setOpenSuccess(false)} severity="success">Email sent!</Alert>
            </Snackbar>
            <Snackbar
                open={openFail}
                autoHideDuration={2000}
                onClose={() => setOpenFail(false)}>
                <Alert onClose={() => setOpenSuccess(false)} severity="error">Unable to find email!</Alert>
            </Snackbar>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={load}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    )
};
