export const states = ["AL", "KY", "OH",
    "AK", "LA", "OK",
    "AZ", "ME", "OR",
    "AR", "MD", "PA",
    "MA", "PR",
    "CA", "MI", "RI",
    "CO", "MN", "SC",
    "CT", "MS", "SD",
    "DE", "MO", "TN",
    "DC", "MT", "TX",
    "FL", "NE",
    "GA", "NV", "UT",
    "NH", "VT",
    "HI", "NJ", "VA",
    "ID", "NM",
    "IL", "NY", "WA",
    "IN", "NC", "WV",
    "IA", "ND", "WI",
    "KS", "WY"
]