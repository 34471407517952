import React, { useState, useEffect, useContext } from 'react'
import { authContext } from '../../context/AuthContext.js';
import { NavLink, useLocation } from 'react-router-dom';
import Collapse from '@mui/material/Collapse';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import InventoryIcon from '@mui/icons-material/Inventory';
import ContactsIcon from '@mui/icons-material/Contacts';
import StorefrontRoundedIcon from '@mui/icons-material/StorefrontRounded';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import AssessmentIcon from '@mui/icons-material/Assessment';
import NoFoodIcon from '@mui/icons-material/NoFood';
import SettingsIcon from '@mui/icons-material/Settings';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';

export default function MainApp() {

    const location = useLocation();
    const { usrGblRole, userRole } = useContext(authContext)
    const [subFolder, setSubFolder] = useState(false)
    const [dashSubFolder, setDashSubFolder] = useState(false)
    const [subVendorFolder, setSubVendorFolder] = useState(false)
    const [subSetupFolder, setSubSetupFolder] = useState(false)

    useEffect(() => {
        const path = location.pathname;
        if (path.startsWith('/invoices') || path.startsWith('/unsold')) {
            setSubFolder(true);
        }
        if (path.startsWith('/orders-log')) {
            setDashSubFolder(true);
        }
        if (path.startsWith('/stock-orders-log')) {
            setSubVendorFolder(true);
        }
    }, [location.pathname]);

    const openSubFolder = () => {
        setSubFolder(!subFolder)
    }
    const openDashSubFolder = () => {
        setDashSubFolder(!dashSubFolder)
    }
    const openVendSubFolder = () => {
        setSubVendorFolder(!subVendorFolder)
    }
    const openSetupSubFolder = () => {
        setSubSetupFolder(!subSetupFolder)
    }

    const Subdirectory = () => {
        return (
            <div className='row sub-pg'>
                <NavLink to="/invoices">
                    <div className='row' style={{ alignItems: "center", margin: '0 0 0 10px ' }}  >
                        <div className='col-2'>
                            <ReceiptLongIcon fontSize='small' sx={{ paddingBottom: "3px" }} />
                        </div>
                        <div className='col-7 sidebar-name'>
                            Invoice Log
                        </div>
                    </div>
                </NavLink>

                <NavLink to="/unsold-items">
                    <div className='row' style={{ alignItems: "center", margin: '0 0 0 10px ', height: 'max-content' }}  >
                        <div className='col-2'>
                            <NoFoodIcon fontSize='small' sx={{ paddingBottom: "3px" }} />
                        </div>
                        <div className='col-9 sidebar-name'>
                            Dead Stock
                        </div>
                    </div>
                </NavLink>
            </div>
        );
    };

    const InsightSubDirectory = () => {
        return (
            <div className='row sub-pg'>

                <NavLink to="/orders-log">
                    <div className='row' style={{ alignItems: "center", margin: '0 0 0 10px ' }}  >
                        <div className='col-2'>
                            <ReceiptLongIcon fontSize='small' sx={{ paddingBottom: "3px" }} />
                        </div>
                        <div className='col-7 sidebar-name'>
                            Orders Log
                        </div>
                    </div>
                </NavLink>
            </div>
        )
    }

    const VendorSubDirectories = () => {
        return (
            <div className='row sub-pg'>

                <NavLink to="/stock-orders-log">
                    <div className='row' style={{ alignItems: "center", margin: '0 0 0 10px ' }}  >
                        <div className='col-2'>
                            <ReceiptLongIcon fontSize='small' sx={{ paddingBottom: "3px" }} />
                        </div>
                        <div className='col-7 sidebar-name' style={{ textWrap: 'nowrap' }}>
                            Re-stock Orders
                        </div>
                    </div>
                </NavLink>
            </div>
        )
    }

    /*const SetupSubDirectories = () => {
        return (
            <div className='row sub-pg'>

                <NavLink to="/configure-quickbooks">
                    <div className='row' style={{ alignItems: "center", margin: '0 0 0 10px ' }}  >
                        <div className='col-2'>
                            <IntegrationInstructionsIcon fontSize='small' sx={{ paddingBottom: "3px" }} />
                        </div>
                        <div className='col-7 sidebar-name' style={{ textWrap: 'nowrap' }}>
                            Quickbooks
                        </div>
                    </div>
                </NavLink>
            </div>
        )
    }*/

    return (
        <div className="sidebar">

            <div style={{ position: "relative", top: "2%", left: "10px", width: '220px' }}>

                <div className='row' style={{ width: '220px' }}>
                    <NavLink to="/analytics">
                        <div className='row' style={{ alignItems: "center" }}>
                            <div className='col-1' onClick={openDashSubFolder} style={{ position: 'relative', right: '10px' }}>
                                {!dashSubFolder ? <KeyboardArrowRightIcon fontSize='small' sx={{ paddingBottom: "3px" }} /> :
                                    <KeyboardArrowDownIcon fontSize='small' sx={{ paddingBottom: "3px" }} />}
                            </div>
                            <div className='col-10' style={{ paddingLeft: 0 }}>
                                <div className='row' style={{ alignItems: "center" }}>
                                    <div className='col-2'>
                                        <AssessmentIcon fontSize='small' />
                                    </div>
                                    <div className='col-7 sidebar-name'>
                                        Analytics
                                    </div>
                                </div>
                            </div>
                        </div>
                    </NavLink>
                    <Collapse in={dashSubFolder} timeout="auto" unmountOnExit sx={{ maxHeight: 'min-content', height: 'max-content' }}>
                        <InsightSubDirectory />
                    </Collapse>

                </div>

                <div className='row' style={{ width: '220px' }}>
                    <NavLink to="/menu" >
                        <div className='row' style={{ alignItems: "center" }}>
                            <div className='col-1'>
                            </div>
                            <div className='col-10' style={{ paddingLeft: 0 }}>
                                <div className='row' style={{ alignItems: "center" }}>
                                    <div className='col-2'>
                                        <MenuBookIcon fontSize='small' /*sx={{ paddingBottom: "1px" }}*/ />
                                    </div>
                                    <div className='col-7 sidebar-name'>
                                        Menu
                                    </div>
                                </div>
                            </div>
                        </div>
                    </NavLink>
                </div>

                <div className='row' style={{ width: '220px' }}>
                    <NavLink to="/inventory" >
                        <div className='row' style={{ alignItems: "center" }}>
                            <div className='col-1' onClick={openSubFolder} style={{ position: 'relative', right: '10px' }}>
                                {!subFolder ? <KeyboardArrowRightIcon fontSize='small' sx={{ paddingBottom: "3px" }} /> :
                                    <KeyboardArrowDownIcon fontSize='small' sx={{ paddingBottom: "3px" }} />}
                            </div>
                            <div className='col-10' style={{ paddingLeft: 0 }}>
                                <div className='row' style={{ alignItems: "center" }}>
                                    <div className='col-2'>
                                        <InventoryIcon fontSize='small' />
                                    </div>
                                    <div className='col-7 sidebar-name'>
                                        Inventory
                                    </div>
                                </div>
                            </div>
                        </div>
                    </NavLink>
                    <Collapse in={subFolder} timeout="auto" unmountOnExit sx={{ maxHeight: 'min-content', height: 'max-content' }}>
                        <Subdirectory />
                    </Collapse>
                </div>

                <div className='row' style={{ width: '220px' }}>
                    <NavLink to="/vendors" >
                        <div className='row' style={{ alignItems: "center" }}>
                            <div className='col-1' onClick={openVendSubFolder} style={{ position: 'relative', right: '10px' }}>
                                {!subVendorFolder ? <KeyboardArrowRightIcon fontSize='small' sx={{ paddingBottom: "3px" }} /> :
                                    <KeyboardArrowDownIcon fontSize='small' sx={{ paddingBottom: "3px" }} />}
                            </div>
                            <div className='col-10' style={{ paddingLeft: 0 }}>
                                <div className='row' style={{ alignItems: "center" }}>
                                    <div className='col-2'>
                                        <ContactsIcon fontSize='small' /*sx={{ paddingBottom: "1px" }}*/ />
                                    </div>
                                    <div className='col-7 sidebar-name'>
                                        Vendors
                                    </div>
                                </div>
                            </div>
                        </div>
                    </NavLink>
                    <Collapse in={subVendorFolder} timeout="auto" unmountOnExit sx={{ maxHeight: 'min-content', height: 'max-content' }}>
                        <VendorSubDirectories />
                    </Collapse>

                </div>


                {usrGblRole && <div className='row' style={{ width: '220px' }}>
                    <NavLink to="/configure-locations" >
                        <div className='row' style={{ alignItems: "center" }}>
                            <div className='col-1' onClick={openSetupSubFolder} style={{ position: 'relative', right: '10px' }}>
                                {/* {!subSetupFolder ? <KeyboardArrowRightIcon fontSize='small' sx={{ paddingBottom: "3px" }} /> :
                                    <KeyboardArrowDownIcon fontSize='small' sx={{ paddingBottom: "3px" }} />} */}
                            </div>
                            <div className='col-10' style={{ paddingLeft: 0 }}>
                                <div className='row' style={{ alignItems: "center" }}>
                                    <div className='col-2'>
                                        <StorefrontRoundedIcon fontSize='small' /*sx={{ paddingBottom: "1px" }}*/ />
                                    </div>
                                    <div className='col-7 sidebar-name' style={{ textWrap: 'nowrap' }}>
                                        Setup
                                    </div>
                                </div>
                            </div>
                        </div>
                    </NavLink>
                    {/* <Collapse in={subSetupFolder} timeout="auto" unmountOnExit sx={{ maxHeight: 'min-content', height: 'max-content' }}>
                        <SetupSubDirectories />
                    </Collapse> */}
                </div>}

                {userRole === "AppAdmin" && <div className='row' style={{ width: '220px' }}>
                    <NavLink to="/app-administration" >
                        <div className='row' style={{ alignItems: "center" }}>
                            <div className='col-1'>
                            </div>
                            <div className='col-10' style={{ paddingLeft: 0 }}>
                                <div className='row' style={{ alignItems: "center" }}>
                                    <div className='col-2'>
                                        <SettingsIcon fontSize='small' />
                                    </div>
                                    <div className='col-7 sidebar-name' style={{ textWrap: 'nowrap' }}>
                                        App Admin
                                    </div>
                                </div>
                            </div>
                        </div>
                    </NavLink>
                </div>}

            </div>



        </div >

    )
}