import React from 'react';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';

export default function InformNewItemRecipe({ close }) {
    // Dialog window with an okay button ==> tell the user to add new items to recipes to track inventory properly
    const handleYes = () => {
        close("confirm-new-item")
    }

    return (
        <div style={{ height: '15vh', margin: '15px 0 20px 35px', width: '500px' }}>
            <div className='row' style={{ position: 'relative', left: '4%', top: '10%', marginBottom: "20px" }}>
                <div className='col-1' style={{ padding: '0', width: 'max-content' }}>
                    <WarningAmberRoundedIcon sx={{ color: 'orange', marginBottom: '5px' }} fontSize='small' />
                </div>
                <div className='col-10' style={{ margin: '0', paddingLeft: '5px' }}>
                    <p style={{ fontWeight: 'bold', margin: '0' }}>
                        Be sure to add new ingredients to Menu Recipes.</p>
                    <p style={{ margin: '0' }}>
                        Inventory cannot be automatically calculated without this.
                    </p>

                </div>
            </div>

            <div className='row' style={{ marginTop: '10%', marginBottom: '20px', position: 'relative', left: '68%' }}>

                <div className='col-6'>
                    <button className='submit' onClick={handleYes} style={{ width: "100px", height: "30px", margin: "0", backgroundColor: "#FFBF00", color: 'black' }}>OK</button>
                </div>
            </div>

        </div>
    );
};
