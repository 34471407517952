import React, { useState, useEffect } from 'react';
import useFetch from '../services/useFetch';
import ManualEntry from '../components/Inventory/ManualEntry';
import CircularProgress from '@mui/material/CircularProgress';
import { DataGrid, GridToolbarContainer, GridToolbarFilterButton, GridToolbarExport } from "@mui/x-data-grid"
import { Dialog, DialogContent } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import InvoiceDetails from '../components/InvoiceHistory/InvoiceDetails';
import UploadedNewIngredient from '../components/Menu/HelperDialogs/UploadedNewIngredient';
import SnackbarEx from '../components/Helpers/SnackbarEx';
import FulfillInvoice from '../components/InvoiceHistory/FulfillInvoice';

const apiUrl = "api/restock-log"
export default function VendorStockOrderLog({ reload }) {

    const [reloadData, setReloadData] = useState(reload)
    const [expandViewIn, setExpandViewIn] = useState(null)
    const [expandView, setExpandView] = useState(false)
    const [openRepeatInvoice, setOpenRepeatInvoice] = useState(false)
    const [invNum, setInvNum] = useState(null)
    const [invVendId, setInvVendId] = useState(null)
    const [openNewIngredientInfo, setOpenNewIngredientInfo] = useState(false)

    const [newIngredients, setNewIngredients] = useState(null)

    const [row, setRow] = useState([])
    const [col, setCol] = useState([])
    const [unfulfilledInvVendor, setUnfulfilledInvVendor] = useState({
        "vendor": null,
        "vendorId": null,
        "invoiceNumber": null,
        "orderNumber": null
    })
    const [openFulfillment, setOpenFulfillment] = useState(false)

    const [openTestSnack, setOpenTestSnack] = useState({
        color: "",
        message: "",
        open: false
    })

    const { data, error, load } = useFetch(apiUrl, reloadData)

    useEffect(() => {
        if (data !== null) {
            setData()
        }
    }, [data])

    const setData = () => {
        setRow(data.map((e) =>
        ({
            id: e._id,
            vendor: e.vendor,
            date: e.dateOrdered,
            totalCost: e.totalPrice
        })
        ))

        setCol([

            { field: 'vendor', headerName: 'Vendor Name', flex: .75 },
            {
                field: 'date', headerName: 'Date of Order', flex: .5, type: 'date',
                valueGetter: (params) => new Date(params.value)
            },
            {
                field: 'totalCost', headerName: 'Order Total Cost', flex: .5, valueFormatter: ({ value }) =>
                    value ? `$ ${value.toFixed(2)}` : '$ ' + 0.0
            },
            {
                field: 'expand', headerName: '', flex: .1,
                renderCell: (params) => (
                    <div>
                        {expandViewIn === params.row.col1 ? (
                            <KeyboardArrowDownIcon fontSize='small' sx={{ paddingBottom: '3px' }} />
                        ) : (
                            <KeyboardArrowRightIcon fontSize='small' sx={{ paddingBottom: '3px' }} />
                        )}
                    </div>
                ),
            }
        ])
    }

    const expandInvoice = async (param) => {

        const index = param.id
        try {
            const invNumVal = await data.find((item) => item._id === index)
            if (param.field !== "fulfilled") {

                setInvNum(invNumVal.invoiceNum)
                setInvVendId(invNumVal.vendorId)

                setExpandViewIn(index === expandViewIn ? null : index)
                setExpandView(!expandView)
            } else {
                setUnfulfilledInvVendor(prev => ({
                    ...prev,
                    ["vendor"]: invNumVal.vendor,
                    ["vendorId"]: invNumVal.vendorId,
                    ["orderNumber"]: invNumVal.invoiceNum
                }))
                setOpenFulfillment(true)

            }


        } catch (err) { console.log(err) }
    }

    const handlePopupClose = async (close, invNum, invVendor) => {
        setExpandView(false)

        if (close === 'close' || close === 'exitIcon') {
            setExpandView(false)
            setOpenRepeatInvoice(false)
            setOpenFulfillment(false)
        } else if (close === "openInvoice") {
            setExpandView(false)
            setOpenRepeatInvoice(true)
        } else if (close === "success") {
            setExpandView(false)
            setOpenFulfillment(false)
            setOpenRepeatInvoice(false)
            refreshPage()
            setOpenTestSnack({ color: "success", message: "Successfully added invoice!", open: true })
        } else if (close === "parDetails") {
            setOpenNewIngredientInfo(false)
            setOpenTestSnack({ color: "success", message: "Ingredient updated!", open: true })
            refreshPage()
        }
    }

    const handleNewIngredients = (newItems) => {
        setOpenNewIngredientInfo(true)
        setNewIngredients(newItems)
    }

    const refreshPage = () => {
        setReloadData(true)
        setTimeout(() => {
            // Code to be executed after the delay
            setReloadData(false)
        }, 200);
    }

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                {/* <GridToolbarColumnsButton /> */}
                <GridToolbarFilterButton />
                {/* <GridToolbarDensitySelector /> */}
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }

    if (load) {

        return (
            <div><CircularProgress sx={{ '&.MuiCircularProgress-root': { position: 'relative', left: '50%' } }} /></div>)
    }
    return (
        <>
            {/* <div className="row " >
                    <div className="col-12" style={{ width: '97%' }}> */}
            <div className='sticky-header'>
                <div className='row pg-hdr' style={{ width: "98%" }}>
                    <div className='col-xl-9 col-lg-8 col-md-9'>
                        <h2>Re-stock Log</h2>
                        <p style={{ marginBottom: '0' }}>Select an order to see details.</p>
                    </div>
                </div>

                <hr style={{ margin: '0', width: "100%" }} />
            </div>

            <div className='row' style={{ width: '99%' }}>
                <div className='col-12 menu-list dt' >
                    <DataGrid
                        rows={row}
                        columns={col}
                        rowHeight={40}
                        pageSizeOptions={[25]}
                        // onRowClick={(param) => expandInvoice(param)}
                        onCellClick={expandInvoice}
                        slots={{
                            toolbar: CustomToolbar
                        }}

                        sx={{
                            padding: "5px 0 0 15px", border: "none", marginLeft: "10px", overflowY: "scroll",
                            '.MuiToolbar-root': { alignItems: "baseline" },
                            '.MuiDataGrid-main': { fontSize: 'small' },
                            '.MuiSvgIcon-root': { margin: 0 },
                            '.MuiDataGrid-main': { margin: "10px 15px 0 0" },
                            '.MuiDataGrid-toolbarContainer': { justifyContent: 'flex-end', marginRight: "10px", paddingLeft: '0' },
                            '.MuiDataGrid-columnHeaderTitle': {
                                whiteSpace: "break-spaces",
                                lineHeight: "normal",
                                fontWeight: '600',
                                '@media (max-width:1080px)': { fontSize: '11px' }
                                // webkitTextStroke: '1px transparent'
                            }
                        }}
                    />

                </div>

                <div >
                    <Dialog maxWidth={'md'} fullWidth open={expandView} onClose={handlePopupClose} id="pop-up">
                        <DialogContent sx={{ borderRadius: "10px", height: '55vh' }} >
                            <InvoiceDetails invnum={invNum} invVendor={invVendId} close={handlePopupClose} vendorStock={true} />
                        </DialogContent>
                    </Dialog >

                    <Dialog fullScreen open={openRepeatInvoice} onClose={handlePopupClose} >
                        <ManualEntry close={handlePopupClose} invoiceOrigin={invNum} invoiceOriginVendor={invVendId} />
                    </Dialog>

                    <Dialog maxWidth={"sm"} fullWidth sx={{ '&.MuiPaper-root': { maxWidth: "500px" } }} open={openFulfillment} onClose={handlePopupClose} >
                        <FulfillInvoice invoiceInfo={unfulfilledInvVendor} close={handlePopupClose} newIngredients={handleNewIngredients} />
                    </Dialog>

                    <Dialog maxWidth={'sm'} fullWidth open={openNewIngredientInfo} onClose={handlePopupClose} >
                        <UploadedNewIngredient close={handlePopupClose} ingredients={newIngredients} />
                    </Dialog>
                </div>
                {
                    openTestSnack.open && <SnackbarEx color={openTestSnack.color} message={openTestSnack.message} open={openTestSnack.open}
                        close={() => setOpenTestSnack({
                            color: "",
                            message: "",
                            open: false
                        })} />
                }

            </div >

        </>
    );
};
