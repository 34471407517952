import React from 'react';
import { Dialog, DialogContent } from "@mui/material";

export default function ConfirmDelete({ deleteItem, deleteOpen }) {

    const handleNo = () => {
        deleteItem(false)
    }
    const handleYes = () => {
        deleteItem(true)
    }
    return (
        <div>

            <Dialog open={deleteOpen} >
                <DialogContent sx={{ borderRadius: "20px" }} >
                    <div className='row' >
                        <p>Are you sure you'd like to delete!?</p>
                    </div>
                    <div className='row' style={{ marginTop: '20px' }}>
                        <div className='col-6 text-start'>
                            <button className='submit' onClick={handleNo} style={{ width: "100px", height: "30px", backgroundColor: "#e0e0e0", color: 'black' }}>Cancel</button>
                        </div>

                        <div className='col-6 text-end'>
                            <button className='submit' onClick={handleYes} style={{ width: "100px", height: "30px", margin: "0 10px 0 0", backgroundColor: "#e5392a" }}>Delete</button>
                        </div>
                    </div>


                </DialogContent>
            </Dialog >

        </div>
    );
};
