import React, { useEffect, useState } from 'react';
import useFetch from "../../../../services/useFetch.js"
import PostData from '../../../../services/PostData.js';

import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import EditCustomIngredients from './EditCustomIngredients.jsx';
import EditCustomPosItems from './EditCustomPosItems.jsx';

export default function EditCustomItem({ menuId, close }) {

    const { data: menuItemData, error, load } = useFetch(`api/custom-menu-item/${menuId}`, false)

    const { data: categories, load: categoryLoad } = useFetch('api/menuCategories', false)
    const [postLoad, setPostLoad] = useState(false)

    const [menuItemName, setMenuItemName] = useState({
        name: null,
        menuId: null,
        category: "Beverages - Coffee",
        size: null,
        sizeUoM: null,
        type: null,
        ingredients: [{
            name: "",
            units: [{ amount: "", measurement: "" }]
        }],
        menuItems: [{
            name: "",
            menuId: null,
            menuUoM: null,
            menuAmount: null,
            units: [{ amount: "", measurement: "" }]
        }],
    })

    useEffect(() => {
        if (menuItemData) {
            setMenuItemName({
                ...menuItemName,
                name: menuItemData.itemName,
                menuId: menuItemData.itemId,
                category: menuItemData.category,
                size: menuItemData.size,
                type: menuItemData.type,
                sizeUoM: menuItemData.itemUoM,
                ingredients: menuItemData.ingredients,
                menuItems: menuItemData.menuItems
            })
        }
    }, [menuItemData])

    const closePop = () => {
        close(false)
    }

    const updateIngredients = (value) => {

        setMenuItemName(prevValues => ({
            ...prevValues,
            ingredients: value
        }));
    }

    const updateMenuItems = (value) => {
        setMenuItemName(prevValues => ({
            ...prevValues,
            menuItems: value
        }));
    }

    const handleSubmit = async () => {

        setPostLoad(true)
        const { success, error } = await PostData(menuItemName, 'api/edit-custom-menu-item', false)
        if (success) {
            close("edit")
            setPostLoad(false)
        }
    }

    if (load || categoryLoad || postLoad || !menuItemName.sizeUoM) {
        return (<div className="container" style={{ top: "5px", left: "45%" }}><CircularProgress /></div>)
    }
    return (
        <div style={{ height: '90vh' }}>
            <div className='row inv-hdr' style={{ alignItems: 'baseline' }}>
                <div className='col-10'>
                    <h4 style={{ margin: "20px 0 20px 7%", textTransform: 'capitalize' }}>Editing: {menuItemData.itemName}: </h4>
                </div>
                <div className='col-2' style={{ position: 'relative', left: '8%' }}>
                    <button type='button' className='blank-bttn' onClick={closePop}>
                        <CloseIcon sx={{ cursor: 'pointer' }} />
                    </button>
                </div>

            </div>

            <div className='row' style={{ width: '65', position: 'relative', left: '10%', top: '6%' }} >

                <p style={{ fontSize: '20px', marginBottom: '5px' }}>Information</p>
                <div className='row'>
                    <div className='col-xl-2 col-lg-2 col-md-3' style={{ paddingRight: '0' }}>

                        <TextField
                            disabled
                            value={"Item Name"}
                            sx={{
                                width: '100%',
                                '&.MuiTextField-root .MuiInputBase-root': {
                                    backgroundColor: 'lightgray',
                                    borderRadius: '0'
                                },
                                "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: "#000000"
                                },
                            }}
                            size='medium' />
                    </div>
                    <div className='col-xl-2 col-lg-3 col-md-3' style={{ maxWidth: "300px", paddingLeft: "0" }}>

                        <TextField
                            placeholder='Coffee machine'
                            onChange={(e) => setMenuItemName({ ...menuItemName, ["name"]: e.target.value })}
                            value={menuItemName["name"] || ""}
                            sx={{
                                width: '400px',
                                '&.MuiTextField-root .MuiInputBase-root': {
                                    borderRadius: '0'
                                },
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: 'gray', // Default outline color
                                    }
                                }
                            }}
                            size='medium' />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-xl-2 col-lg-2 col-md-3' style={{ paddingRight: '0' }}>

                        <TextField
                            disabled
                            value={"Category"}
                            sx={{
                                width: '100%',
                                '&.MuiTextField-root .MuiInputBase-root': {
                                    backgroundColor: 'lightgray',
                                    borderRadius: '0'
                                },
                                "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: "#000000"
                                },
                            }}
                            size='medium' />
                    </div>
                    <div className='col-xl-3 col-lg-3 col-md-2' style={{ maxWidth: "300px", paddingLeft: "0" }}>

                        <TextField
                            select
                            onChange={(e) => setMenuItemName({ ...menuItemName, ["category"]: e.target.value })}
                            value={menuItemName["category"]}

                            sx={{
                                width: '400px',
                                '&.MuiTextField-root .MuiInputBase-root': {
                                    borderRadius: '0'
                                },
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: 'gray', // Default outline color
                                    },
                                }
                            }}
                            size='medium' >
                            {categories?.map((option, ind) =>
                                <MenuItem key={ind} value={option}>
                                    {option}
                                </MenuItem>
                            )}
                        </TextField>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-xl-2 col-lg-2 col-md-3' style={{ paddingRight: '0' }}>

                        <TextField
                            disabled
                            value={"Size"}
                            sx={{
                                width: '100%',
                                '&.MuiTextField-root .MuiInputBase-root': {
                                    backgroundColor: 'lightgray',
                                    borderRadius: '0'
                                },
                                "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: "#000000",

                                },

                            }}
                            size='medium' />
                    </div>
                    <div className='col-xl-3 col-lg-3 col-md-2' style={{ maxWidth: "300px", paddingLeft: "0" }}>

                        <TextField
                            type='number'
                            placeholder='120'
                            onChange={(e) => setMenuItemName({ ...menuItemName, ["size"]: Number(e.target.value) })}
                            value={menuItemName["size"] || ""}
                            sx={{
                                width: '400px',
                                '&.MuiTextField-root .MuiInputBase-root': {
                                    borderRadius: '0'
                                },
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: 'gray', // Default outline color
                                    },
                                }
                            }}
                            size='medium' />
                    </div>
                </div>

                <div className='row'>
                    <div className='col-xl-2 col-lg-2 col-md-3' style={{ paddingRight: '0' }}>

                        <TextField
                            disabled
                            value={"Item size UoM"}
                            sx={{
                                width: '100%',
                                '&.MuiTextField-root .MuiInputBase-root': {
                                    backgroundColor: 'lightgray',
                                    borderRadius: '0'
                                },
                                "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: "#000000"
                                },
                            }}
                            size='medium' />
                    </div>
                    <div className='col-xl-2 col-lg-3 col-md-2' style={{ maxWidth: "300px", paddingLeft: "0" }}>

                        <TextField

                            placeholder='oz'
                            onChange={(e) => setMenuItemName({ ...menuItemName, ["sizeUoM"]: e.target.value })}
                            value={menuItemName["sizeUoM"] || ""}
                            sx={{
                                width: '400px',
                                '&.MuiTextField-root .MuiInputBase-root': {
                                    borderRadius: '0'
                                },
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: 'gray', // Default outline color
                                    },
                                }
                            }}
                            size='medium' />
                    </div>
                </div>
            </div>

            <div className='row' style={{ position: 'relative', left: '10%', top: '13%' }}>
                <p style={{ fontSize: '20px', marginBottom: '5px' }}>Ingredients -- Non menu items</p>
                <EditCustomIngredients ingredients={menuItemData.ingredients} updateIngredients={updateIngredients} />
            </div>

            <div className='row' style={{ position: 'relative', left: '10%', top: '18%' }}>
                <p style={{ fontSize: '20px', marginBottom: '5px', marginTop: "25px" }}>Menu Item Ingredients</p>
                <EditCustomPosItems menuItems={menuItemData.menuItems} updateMenuItems={updateMenuItems} customSize={menuItemName.sizeUoM} />
            </div>

            <div className='row' style={{ paddingBottom: "20px", paddingLeft: '10%', justifyContent: 'start', position: "relative", top: '23%' }}>
                <button className='submit' type='submit' style={{ width: "25%" }} onClick={handleSubmit}>
                    Submit
                </button>
            </div>
        </div>
    );
};
