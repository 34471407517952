import React, { useState } from 'react';
import useFetch from "../../../services/useFetch.js"
import ShopifyOAuth from './ShopifyOAuth.js';
import { Dialog } from '@mui/material';
import AddVendorDialog from './AddVendorDialog.jsx';
import AddIcon from '@mui/icons-material/Add';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import { handleQuickBooksAuth } from './QuickBooksOAuth.js';

export default function AddVendor() {

    const { data: vendors, error, load } = useFetch("api/business-vendors", false)
    const [openNewVendor, setOpenNewVendor] = useState(false)

    const handleAuth = (shopifyStore) => {
        if (shopifyStore === "QBO") {
            handleQuickBooksAuth()
        } else {
            ShopifyOAuth(shopifyStore)
        }
    }

    const closePop = (val) => {
        setOpenNewVendor(val)
    }

    if (load) {
        return (<div className="container" style={{ left: '15%' }}><CircularProgress size={30} /></div>)
    }
    return (
        <div>
            <Dialog maxWidth={'sm'} fullWidth open={openNewVendor} onClose={closePop}>
                <AddVendorDialog closePop={closePop} storeName={handleAuth} />
            </Dialog>

            <div className='row' style={{ padding: "5px 0 15px 15px" }}>
                <div className='col-12' style={{ padding: '0' }}>
                    <p style={{ fontSize: '20px', height: '30px', paddingLeft: '5px', margin: '0' }}>Connect to vendor: </p>
                    <hr style={{ borderWidth: '0.5px', margin: '0', width: '315px' }} />
                </div>

            </div >

            <div className='row' style={{ marginBottom: "0", paddingLeft: '15px' }}>
                <div className='col-12'>
                    <p>Current automated vendors:</p>
                </div>

            </div>
            <div className='row' style={{ marginBottom: "0", paddingLeft: '15px' }}>
                <div className='col-12'>
                    {vendors && vendors.length > 0 ? vendors.map((ven, ind) =>
                        <Chip label={ven.vendorName} sx={{ marginRight: '5px' }} />
                    )
                        :
                        <Chip label="None" />
                    }
                </div>
            </div>

            <div className='row' style={{ position: "fixed", bottom: '15%' }}>
                <div className='col-6'>
                    <button className='submit'
                        style={{ display: "flex", justifyContent: 'center', alignItems: 'center', width: "150px", height: '35px', marginLeft: '0' }}
                        onClick={() => setOpenNewVendor(true)}>
                        Add Vendor
                        <span style={{ paddingLeft: '5px' }}>
                            <AddIcon sx={{ color: "#FFF" }} />
                        </span>
                    </button>
                </div>
            </div>


        </div >
    );
};
