import React from 'react';

export default function ConfirmLocations({ close }) {

    return (
        <div>
            <div className='row' style={{ position: "relative", padding: "20px", left: "6.5%", width: "fit-content", marginBottom: "0" }}>
                <p>Does this account use multi-locations?</p>
            </div>
            <div className='row' style={{ padding: '5px 30px 15px 30px' }}>
                <div className='col-6 text-start'>
                    <button className='submit'
                        onClick={() => close(true)}
                        style={{ width: "100px", height: "30px", backgroundColor: "#1eb2ff", color: 'white' }}>Yes</button>
                </div>

                <div className='col-6 text-end'>
                    <button
                        className='submit'
                        onClick={() => close(false)}
                        style={{ width: "100px", height: "30px", margin: "0 10px 0 0", backgroundColor: "#e0e0e0", color: 'black' }}>No</button>
                </div>
            </div>

        </div >
    );
};
