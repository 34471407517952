import React from 'react'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';


export default function CondiguredDates({ anchor, close, hamburgerChoice }) {
    const open = Boolean(anchor);

    const handleClose = (val) => {

        hamburgerChoice(val)
        close()
    };

    return (
        <Menu
            anchorEl={anchor}
            open={open}
            onClose={handleClose}
            sx={{ ".MuiPaper-root": { borderRadius: "15px" } }}
        >
            <MenuItem onClick={() => handleClose('Last 7 days')}>Last 7 Days</MenuItem>
            <MenuItem onClick={() => handleClose('Last 14 days')}>Last 14 Days</MenuItem>
            <MenuItem onClick={() => handleClose('Last 30 days')}>Last 30 Days</MenuItem>
            <MenuItem onClick={() => handleClose('Last 12 Months')}>Last 12 Months</MenuItem>
        </Menu>
    )
}