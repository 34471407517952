import React, { useEffect, useState } from 'react'
import useFetch from "../../../../services/useFetch.js"
import AddItemIngredients from './AddItemIngredients.jsx';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import PostData from "../../../../services/PostData.js"
import AddModifiers from './AddModifiers.jsx';

export default function AddItem({ close, ingredientId, itemCategory }) {

    const [load, setLoad] = useState(true)

    const [values, setValues] = useState({
        itemName: "",
        itemId: null,
        category: "",
        ingredients: [{
            name: "",
            id: 0,
            type: "regular",
            toGoOnly: false,
            units: [{ amount: "", measurement: "" }]
        }],
        modifiers: [{
            modifierName: "",
            modifierId: "",
            modifierPrice: 0,
            ingredients: [{
                name: "",
                id: 0,
                units: [{ amount: "", measurement: "" }]
            }]
        }]
    })

    const { data: menuData, error: menuError } = useFetch(`api/menuItems/${ingredientId}`, false)

    useEffect(() => {

        if (!menuData) { setLoad(true) }
        else {
            setValues({ ...values, ["itemName"]: menuData[0].itemName, ["itemId"]: menuData[0].itemId, ["category"]: itemCategory })
            setLoad(false)

        }
    }, [menuData])


    /* Handles the menu item name input box */
    const populateCategory = (mi) => {
        const found = menuData.find(item => item.itemName === mi)

        if (found) {
            populateCategory(found.category)
            setValues({ ...values, ["category"]: found.category })
        }
    }

    /* Grabs the ingredients from the helper function AddItemIngredients, and updates the ingredients
        Array accordingly */
    const updateIngredients = (value) => {
        setValues({ ...values, ["ingredients"]: value })
    }
    const updateModifiers = (value) => {

        setValues({ ...values, ["modifiers"]: value })
    }

    /* When the submit button gets clicked, this will send the data to the PostData API helper
        and post the data to the server to store in the database */
    const handleSubmit = async () => {
        setLoad(true)
        const { success, load, error } = await PostData(values, "api/menuItems", false)
        if (success !== 'success') {
            setLoad(load)
            close(false, 'success')
        }
        if (load) {
            return (<div className="container"><CircularProgress /></div>)
        }
    }


    const closePop = () => {
        close(false)
    }
    if (load) {
        return (<div className="container"><CircularProgress /></div>)
    }
    return (

        <div className='row' >
            <div className='row inv-hdr' style={{ alignItems: 'baseline' }}>
                <div className='col-10'>
                    <h4 style={{ margin: "20px 0 20px 5%" }}>Add Item: </h4>
                </div>
                <div className='col-2' style={{ position: 'relative', left: '10%' }}>
                    <button type='button' className='blank-bttn' onClick={closePop}>
                        <CloseIcon sx={{ cursor: 'pointer' }} />
                    </button>
                </div>
                {/* <hr /> */}
            </div>
            <div className='row' style={{ margin: "10px 0 0 0" }}>
                <div className='col' style={{ paddingLeft: '35px' }}>

                    <TextField helperText={"Menu Item"} style={{ width: "100%" }}
                        disabled name='menuItem' value={menuData[0].variant ? menuData[0].itemName + " - " + menuData[0].variant : menuData[0].itemName + " - " + 'Modifier'}
                        sx={{
                            "& .MuiInputBase-input.Mui-disabled": {
                                WebkitTextFillColor: "#000000"
                            },
                            "& .MuiFormHelperText-root.Mui-disabled": {
                                WebkitTextFillColor: "#000000"
                            }
                        }} />
                </div>
                <div className='col' style={{ paddingRight: '35px' }}>

                    <TextField helperText={"Menu Item Category"} style={{ width: "100%" }}
                        disabled name='category' value={menuData[0].category} sx={{
                            "& .MuiInputBase-input.Mui-disabled": {
                                WebkitTextFillColor: "#000000"
                            },
                            "& .MuiFormHelperText-root.Mui-disabled": {
                                WebkitTextFillColor: "#000000"
                            }
                        }} />

                </div>
                <hr style={{ marginTop: "15px", marginBottom: '0' }} />
            </div>

            <div className='row' style={{ margin: "20px 0 15px 20px" }}>
                <p style={{ fontSize: '20px' }}>{menuData[0].itemName} Ingredients:</p>
            </div>
            <AddItemIngredients submit={handleSubmit} updateIngredients={updateIngredients} itemCategory={itemCategory} />
            <hr style={{ marginTop: '20px', marginBottom: '0' }} />

            {((menuData[0].itemId !== false || menuData[0].itemId !== null) && itemCategory !== "Modifier") &&
                <AddModifiers ingredientId={ingredientId} updateModifier={updateModifiers} />
            }

            <hr style={{ marginBottom: '20px', marginTop: '0' }} />
            <div className='row' style={{ width: "20%", marginBottom: '20px', position: 'relative', left: "75%" }}>
                <button type='submit' className={values.ingredients?.[0]?.units?.[0]?.measurement ? 'submit' : 'dis'}
                    disabled={!values.ingredients?.[0]?.units?.[0]?.measurement}
                    onClick={handleSubmit} >Submit</button>
            </div>


        </div >


    )
}