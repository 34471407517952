import React, { useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PosMenu from "../components/Menu/posMenuTab/PosMenu";
import SpecialtyMenu from "../components/Menu/specialtyItemTab/SpecialtyMenu";

export default function Menu({ reload }) {
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    // Function to return the component based on the tab index
    const tabPage = (index) => {
        switch (index) {
            case 0:
                return <PosMenu reload={reload} />;
            case 1:
                return <SpecialtyMenu />;
            default:
                return null;
        }
    };

    return (
        <div>
            <div className="sticky-header">
                <div className='row pg-hdr' style={{ width: "100%" }}>
                    <div className="col-xl-9 col-lg-8 col-md-8" >
                        <h2>Menu</h2>
                        <p style={{ marginBottom: '0' }}>Select Item Name for Details</p>
                    </div>
                </div>
                <hr style={{ margin: '0', marginBottom: '5px', width: "100%" }} />
            </div>

            <Tabs value={value} onChange={handleChange} sx={{ marginLeft: '18px' }}>
                <Tab label="POS Menu" sx={{ textTransform: 'none' }} />
                <Tab label="Custom Items" sx={{ textTransform: 'none' }} />
            </Tabs>
            <hr style={{ margin: '0' }} />
            {tabPage(value)}  {/* Display the component corresponding to the current tab */}

        </div>
    );
}